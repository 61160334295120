import { requestAll } from '@helpers/requestAll.js'
import { PUBLIC_BASE_URL } from '@config'
import { createClient } from '@services'
import qs from 'qs'

const { get, uploadFile, del, put, post } = createClient()

/* API request to get files */
export function getAllFilesRequest(gameNameId, modNameId, query) {
  return requestAll(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods/@${modNameId}/files`,
    query
  )
}

/* API request to get a file */
export async function getFileRequest(gameNameId, modNameId, fileId) {
  const { data } = await get(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods/@${modNameId}/files/${fileId}`
  )
  return data
}

/* API request to download a file */
export async function downloadFileRequest(fileUrl) {
  const { data } = await get(fileUrl, { responseType: 'blob' })
  return data
}

/* API request to add file */
export async function addFileRequest(
  gameNameId,
  modNameId,
  upload,
  onUploadProgress
) {
  const { data } = await uploadFile(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods/@${modNameId}/files`,
    upload,
    onUploadProgress
  )
  return data
}

/* API request to start a multipart session */
export async function startMultipartUploadSessionRequest(
  gameNameId,
  modNameId,
  filename
) {
  const { data } = await post(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods/@${modNameId}/files/multipart`,
    qs.stringify({ filename })
  )
  return data
}

/* API request to add a part to a multipart session */
export function addMultipartUploadPartRequest(
  gameNameId,
  modNameId,
  uploadId,
  part,
  contentRange,
  onUploadProgress
) {
  return put(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods/@${modNameId}/files/multipart?upload_id=${uploadId}`,
    part,
    {
      headers: {
        'Content-Range': contentRange,
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    }
  )
}

/* API request to complete a multipart session */
export async function completeMultipartUploadSessionRequest(
  gameNameId,
  modNameId,
  upload_id
) {
  const { data } = await post(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods/@${modNameId}/files/multipart/complete`,
    qs.stringify({ upload_id })
  )
  return data
}

/* API request to get the status of a multipart session */
export async function getMultipartUploadSessionStatusRequest(
  gameNameId,
  modNameId,
  uploadId
) {
  const { data } = await get(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods/@${modNameId}/files/multipart/status?upload_id=${uploadId}`
  )
  return data
}

/* API request to update a file */
export async function updateFileRequest(gameNameId, modNameId, fileId, update) {
  const { data } = await put(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods/@${modNameId}/files/${fileId}`,
    qs.stringify(update)
  )
  return data
}

/* API request to update a file's platform status */
export async function updateFilePlatformStatusRequest(
  gameNameId,
  modNameId,
  fileId,
  update
) {
  const { data } = await post(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods/@${modNameId}/files/${fileId}/platforms`,
    qs.stringify(update)
  )

  return data
}

/* API request to delete file */
export async function deleteFileRequest(gameNameId, modNameId, fileId) {
  const { data } = await del(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods/@${modNameId}/files/${fileId}`
  )
  return data
}

/* API request to upload image for wysiwyg editor  */
export async function uploadImageRequest(image, onUploadProgress) {
  const { data } = await uploadFile('/me/media/inline', image, onUploadProgress)

  return data
}

/* API request to list media and folders for wysiwyg editor  */
export async function listMediaRequest(folder) {
  folder = folder ? `/${folder}` : ''
  const { data } = await get(`/me/media/inline${folder}`)

  return data
}
