import { LS_NEW_LABEL } from '@config/localstorage.js'
import useStorage from '@composables/useStorage.js'
import { useMonetization } from '@composables'
import { computed } from 'vue'

const navState = useStorage(LS_NEW_LABEL, true)
export default function () {
  const { pendingInvoices } = useMonetization()
  const state = computed(() => JSON.parse(navState.value || '[]'))

  function isNavChecked(value) {
    return state.value[value]
  }

  function checkNavNewLabel(value) {
    if (value) {
      const temp = { ...state.value, [value]: true }
      navState.value = JSON.stringify(temp)
    }
  }

  function showNewLabel(newLabel) {
    return (
      (newLabel?.value && !isNavChecked(newLabel?.value)) ||
      (pendingInvoices.value && newLabel?.invoice)
    )
  }

  return {
    checkNavNewLabel,
    isNavChecked,
    showNewLabel,
  }
}
