<template>
  <div
    v-if="message || $slots.default"
    class="tw-flex tw-text-sm tw-text-danger tw-space-x-2"
  >
    <slot />
    <template v-if="isArray(message)">
      <div class="tw-flex tw-flex-col tw-space-y-1">
        <span v-for="m in message" :key="m" v-decode="m" />
      </div>
    </template>
    <span v-else v-decode="message" class="tw-overflow-hidden tw-break-words" />
  </div>
</template>

<script>
import { isString, isArray } from '@helpers/utils.js'
export default {
  props: {
    message: {
      type: [String, Array],
      default: null,
    },
  },
  setup() {
    return { isString, isArray }
  },
}
</script>
