<template>
  <base-icon
    icon="arc"
    class="tw-absolute -tw-z-2 tw-top-0 tw-right-0 tw-w-2/5"
  />
  <div class="tw-h-full tw-overflow-auto">
    <mob-menu-overlay />
    <home-header is-static class="tw-font-home tw-text-base" />
    <div
      class="content tw-font-home tw-text-base tw-text-white tw-relative tw-overflow-x-clip tw-h-full tw-mx-auto tw-px-8 tw-flex tw-items-center tw-justify-center tw-gap-8 2xl:tw-gap-12"
    >
      <div class="tw-font-bold xl:tw-min-w-[1000px]">
        <p
          class="tw-font-bold tw-leading-none tw-home-h2 tw-uppercase sm:tw-max-w-[670px] tw-mb-5 sm:tw-mb-10"
        >
          Developer <span class="tw-text-primary">Documentation</span>
        </p>
        <p class="tw-text-lg sm:tw-text-xl tw-max-w-[670px] tw-mb-5 sm:tw-mb-6">
          It takes just a few simple steps to offer your players a seamless,
          cross-plaform UGC experience.
        </p>
        <a
          class="tw-rounded-full hover:tw-cursor-pointer tw-text-center tw-block sm:tw-inline-block tw-px-6 tw-py-3 tw-mb-10 sm:tw-mb-10 lg:tw-mb-15 tw-text-dark hover:tw-text-dark hover:tw-no-underline tw-bg-primary hover:tw-bg-primary-hover"
          href="https://docs.mod.io"
        >
          SEE DOCUMENTATION
        </a>

        <p class="tw-text-lg tw-mb-4 tw-uppercase">Integrate with</p>
        <div class="tw-flex tw-flex-col lg:tw-flex-row tw-gap-4">
          <a
            v-for="doc in engines"
            :key="doc.name"
            class="tw-flex tw-justify-center tw-rounded-full hover:tw-cursor-pointer tw-items-center tw-px-10 tw-py-2 sm:tw-py-3 tw-text-white hover:tw-text-white hover:tw-no-underline tw-bg-dark-2 hover:tw-bg-[#35426e] tw-gap-x-3"
            :href="doc.url"
          >
            <base-icon
              class="tw-h-8 sm:tw-h-10 tw-aspect-auto"
              :icon="doc.icon"
            />
            <span class="tw-font-bold">{{ doc.name }}</span>
          </a>
        </div>
      </div>
      <div class="tw-hidden xl:tw-block tw-self-center">
        <picture class="tw-relative -tw-z-1">
          <source :srcSet="helmet_webp" type="image/webp" />
          <img
            class="helmet tw-w-[104px] 2xl:tw-w-[417px] tw-scale-[6] 2xl:tw-scale-[2] tw-translate-x-[-80px] 2xl:tw-translate-x-[-80px] tw-translate-y-[-70px]"
            :src="helmet"
            alt="Helmet model with Blueprint editor"
          />
        </picture>
      </div>
    </div>
  </div>
</template>

<script>
import MobMenuOverlay from '@home_components/Header/MobMenuOverlay.vue'
import HomeHeader from '@home_components/Header/HomeHeader.vue'
import helmet_webp from '@assets/misc/Helmet.webp'
import helmet from '@assets/misc/Helmet.png'
import { MODIO_ENV } from '@config'

const basePath = `https://docs.${MODIO_ENV === 'staging' ? 'moddemo' : 'mod'}.io`

const engines = [
  {
    name: 'UNREAL ENGINE',
    url: `${basePath}/unreal/`,
    icon: 'unreal_logo',
  },
  {
    name: 'UNITY',
    url: `${basePath}/unity/`,
    icon: 'unity_logo',
  },
  {
    name: 'C++ SDK',
    url: `${basePath}/cppsdk/`,
    icon: 'SDK',
  },
  {
    name: 'REST API',
    url: `${basePath}/restapiref/`,
    icon: 'restapi',
  },
]

export default {
  components: {
    MobMenuOverlay,
    HomeHeader,
  },
  setup() {
    return {
      helmet_webp,
      engines,
      helmet,
    }
  },
}
</script>

<style lang="css" scoped>
@media screen and (max-height: 800px) {
  .content {
    align-items: flex-start;
    height: fit-content;
    margin-bottom: 2rem;
  }
  img.helmet {
    --tw-translate-y: 0px;
  }
}
@media screen and (min-width: 1600px) {
  .content {
    padding: 0 5%;
  }
}
@media screen and (min-width: 1800px) {
  .content {
    gap: 10rem;
  }
  img.helmet {
    --tw-translate-x: -120px;
  }
}
</style>
