export const TITLE_POSTFIX = 'mod.io'

export const HOMEPAGE_TITLE = 'Cross Platform Mod Support for Games'
export const HOMEPAGE_DESCRIPTION =
  'Support modding on PC, console and mobile with mod.io, a platform which makes it easy to grow, manage and integrate UGC in-game, using our API, SDK and plugins for Unity, Unreal Engine and more.'
export const HOMEPAGE_KEYWORDS =
  'games, modding, mods, mod, mod support, modio, steamworks, workshop, service, api, sdk, tool, plugin, wrapper, platform, windows, linux, mac, addons, addons, development, engine, unity, unreal, community, hosting, cdn'

export const GAME_BROWSE_ROUTE_TITLE = 'Browse Games'
export const GAME_BROWSE_ROUTE_KEYWORDS_META =
  'browse, search, video games, indie games, standalone games, plugins, free games, game extensions, expansion packs, game episode, api, workshop'
export const GAME_BROWSE_ROUTE_DESCRIPTION_META =
  'Browse games created by professional and indie development teams at mod.io.'
// end

export const MOD_BROWSE_ROUTE_KEYWORDS_META =
  'items, api, devkit, tools, plugins, mods, modding, total conversion, tutorials, development, ugc, items, addons, video game, indie game, standalone game, developers, creation'
// end

export const MOD_VIEW_ROUTE_KEYWORDS_META =
  'item, mod, total conversion, addon, development, indie, extension, creation, episode, chapter, expansion pack'
// end

export const GUIDE_BROWSE_ROUTE_KEYWORDS_META =
  'browse, search, modding, mods, total conversions, addons, workshop, development, discussion, tutorials, information, help, how to, guides'
export const GUIDE_BROWSE_ROUTE_DESCRIPTION_META =
  'Browse :game.name guides posted by the mod.io community.'
// end

export const GUIDE_VIEW_ROUTE_KEYWORDS_META =
  'articles, information, data, gossip, feature, read, view, discuss'
// end

export const MARKETPLACE_BROWSE_ROUTE_KEYWORDS_META =
  'items, api, devkit, tools, plugins, mods, modding, total conversion, tutorials, development, ugc, items, addons, video game, indie game, standalone game, developers, creation'
// end

export const ABOUT_ROUTE_TITLE = 'About Us'
export const ABOUT_ROUTE_KEYWORDS_META =
  'about, introduction, team, information, details, help, presskit, mediakit, logo'
export const ABOUT_ROUTE_DESCRIPTION_META =
  'Meet the mod.io team and grab our logo for us in your game and apps.'
// end

export const CAREERS_ROUTE_TITE = 'Careers'
export const CAREERS_ROUTE_KEYWORDS_META =
  'careers, jobs, hire, team, applications, position, work, salary, employee, manager, developer, production, CV, resume'
export const CAREERS_ROUTE_DESCRIPTION_META =
  "Join the mod.io team and let's work together enabling the next generation of game creators through mods."
//end

export const GAMESCOM_ROUTE_TITLE = 'mod.io at gamescom 2024'
export const GAMESCOM_ROUTE_KEYWORDS_META =
  'mod.io, gamescom, gamescom 2024, ugc, mods, addons, metrics, insights, stats, monetization, ugc experts'
export const GAMESCOM_ROUTE_DESCRIPTION_META =
  'mod.io joins gamescom 2024 to deliver UGC solutions on all platforms.'
// end

export const NECRODANCER_ROUTE_TITLE = 'Case Study - Crypt of the Necrodancer'
export const NECRODANCER_ROUTE_KEYWORDS_META =
  'case study, crypt of the necrodancer'
export const NECRODANCER_ROUTE_DESCRIPTION_META =
  'Brace yourself Games and Vortex buffer brought scriptable, multiplayer mod support to all platforms for Crypt of the Necrodancer, powered by mod.io.'
// end

export const SNOWRUNNER_ROUTE_TITLE = 'Case Study - SnowRunner'
export const SNOWRUNNER_ROUTE_KEYWORDS_META = 'case study, SnowRunner'
export const SNOWRUNNER_ROUTE_DESCRIPTION_META =
  'mod.io partnered with Saber and Focus Entertainment to bring cross-platform muiltiplayer mods to SnowRunner.'
// end

export const TERMS_ROUTE_TITLE = 'Terms of Use'
export const TERMS_ROUTE_KEYWORDS_META =
  'legal, terms, agreement, terms of use, rules, users, code of conduct'
export const TERMS_ROUTE_DESCRIPTION_META =
  'The Terms of Use which all members must agree to, to access and use the site and services provided by mod.io.'
//end

export const PRIVACY_ROUTE_TITLE = 'Privacy Policy'
export const PRIVACY_ROUTE_KEYWORDS_META =
  'legal, privacy policy, privacy, cookies, disclosure, information, security, delete account'
export const PRIVACY_ROUTE_DESCRIPTION_META =
  'The Privacy Policy which governs the data we collect and how we use that data to provide the mod.io site and services.'
//end

export const AUP_ROUTE_TITLE = 'Acceptable Use Policy'
export const AUP_ROUTE_KEYWORDS_META =
  'legal, acceptable use, policy, rules, terms, agreement, usage, access, rules, users, code of conduct'
export const AUP_ROUTE_DESCRIPTION_META = "Community do's and don'ts"
//end

export const GAME_TERMS_ROUTE_TITLE = 'Game Terms'
export const GAME_TERMS_ROUTE_KEYWORDS_META =
  'developer, publisher, legal, terms, agreement, terms of service, rules, users, code of conduct'
export const GAME_TERMS_ROUTE_DESCRIPTION_META =
  'All publishers and studios agree to be bound by the Game Terms, to access and use the mod.io site and services in their games.'
//end

export const MONETIZATION_TERMS_ROUTE_TITLE = 'Monetization Terms'
export const MONETIZATION_TERMS_ROUTE_KEYWORDS_META =
  'legal, monetization, payment, transfer, income, purchase, subscription, trade, terms, agreement, usage, access, rules, users, code of conduct'
export const MONETIZATION_TERMS_ROUTE_DESCRIPTION_META =
  'The Monetization Terms that govern the purchase of User Generated Content and creator revenue.'
//end

export const API_TERMS_ROUTE_TITLE = 'API Access Terms'
export const API_TERMS_ROUTE_KEYWORDS_META =
  'legal, api, key, terms, agreement, usage, access, rules, users, code of conduct'
export const API_TERMS_ROUTE_DESCRIPTION_META =
  'The API Access terms must be agreed to by members before requesting an API key. They govern access to and usage of the mod.io API.'
//end

export const COOKIES_ROUTE_TITLE = 'Cookies Policy'
export const COOKIES_ROUTE_KEYWORDS_META =
  'legal, cookies, consent, preferences, tracking, disclosure, information'
export const COOKIES_ROUTE_DESCRIPTION_META =
  'The Cookies Policy which explains the cookies we use to provide the mod.io site and services.'
//end

export const REFUND_ROUTE_TITLE = 'Refund Policy'
export const REFUND_ROUTE_KEYWORDS_META =
  'legal, refund, purchase, subscription, trade, terms, agreement, usage, access, rules, users, code of conduct'
export const REFUND_ROUTE_DESCRIPTION_META =
  'The Refund Policy which outlines refund process details for token packs and premium ugc.'
//end

export const CONTACT_ROUTE_TITLE = 'Contact Us'
export const CONTACT_ROUTE_KEYWORDS_META =
  'contact, email, team, help, assistance, support, question'
export const CONTACT_ROUTE_DESCRIPTION_META =
  'Contact the mod.io team for help and biz dev opportunities using our API.'
//end

export const DOCS_ROUTE_TITLE = 'Documentation'
export const DOCS_ROUTE_KEYWORDS_META =
  'Documentation, help, integration, support'
export const DOCS_ROUTE_DESCRIPTION_META =
  'It takes just a few simple steps to offer your players a seamless, cross-plaform UGC experience.'
//end

export const USER_ROUTE_KEYWORDS_META =
  'browse, search, mods, development, total conversions, modification, enhancement, games, plugins, addons, extensions, episode, expansion packs'
export const USER_ROUTE_DESCRIPTION_META =
  'Browse mods created by :user.name at mod.io.'
//end

export const REPORT_ROUTE_TITLE = 'Report'
export const REPORT_ROUTE_KEYWORDS_META =
  'report, dmca, copyright, infringe, legal, terms, rights, add, submit, form, create'
export const REPORT_ROUTE_DESCRIPTION_META =
  'Report content on mod.io that is infringing your rights, or the sites terms of use.'
//end

export const STATUS_ROUTE_TITLE = 'Status'
export const STATUS_ROUTE_KEYWORDS_META =
  'uptime, statistics, availability, api, status, online, down, working'
export const STATUS_ROUTE_DESCRIPTION_META =
  'View our API status, availability, responsivness and statistics.'
//end

export const LOGIN_ROUTE_TITLE = 'Login'
export const LOGIN_ROUTE_KEYWORDS_META =
  'login, log in, sign in, recover password, reset password, register, join, signup, help, session, cookie, navigation, development, community, contact us'
export const LOGIN_ROUTE_DESCRIPTION_META =
  'Login to mod.io and enter a world of game development. Share your thoughts and ideas with the community.'
//end

export const PRICING_ROUTE_TITLE = 'Pricing'
//end

export const GAME_ADD_ROUTE_TITLE = 'Add a game'
export const GAME_ADD_ROUTE_KEYWORDS_META =
  'api, developers, tools, kit, video games, indie games, standalone games, add, save, register, submit, establish, form, create, launch, developers, community, workshop, api'
export const GAME_ADD_ROUTE_DESCRIPTION_META =
  'Developing or publishing a game? Add it to mod.io to get access to a feature packed API with complete modding support built in.'
//end

export const GAME_ADMIN_ROUTE_TITLE = 'Edit :game-name'
export const GAME_ADMIN_ROUTE_KEYWORDS_META =
  'mods, api, devkit, tools, plugins, mods, modding, total conversion, tutorials, development, ugc, items, addons, video game, indie game, standalone game, developers, creation'
//end

export const MOD_ADD_ROUTE_TITLE = 'Add a :game-name mod'
export const MOD_ADD_ROUTE_KEYWORDS_META =
  'mods, indie games, video game development, modification, total conversion, enhancement, addon pack, extension, add, save, register, submit, establish, form, create, launch, developers, community'
export const MOD_ADD_ROUTE_DESCRIPTION_META =
  'Developing a mod for :game-name? Add it to mod.io and share it with the community.'
//end

export const MOD_ADMIN_ROUTE_TITLE = 'Edit :mod-name for :game-name'
export const MOD_ADMIN_ROUTE_KEYWORDS_META =
  'mod, total conversion, addon, development, indie, extension, creation, episode, chapter, expansion pack'
//end

export const GUIDE_ADD_ROUTE_TITLE = 'Post :game-name guide'
export const GUIDE_ADD_ROUTE_KEYWORDS_META =
  'blog, write, edit, share, discuss, article, feature, tutorial, guide, preview, interview'
export const GUIDE_ADD_ROUTE_DESCRIPTION_META =
  'Post :game-name guide on mod.io'
//end

export const GUIDE_ADMIN_EDIT_ROUTE_TITLE = 'Edit :guide-name guide'
export const GUIDE_ADMIN_EDIT_ROUTE_KEYWORDS_META =
  'mod, total conversion, addon, development, indie, extension, creation, episode, chapter, expansion pack'
//end

export const PROFILE_ACCESS_ROUTE_KEYWORDS =
  'developer, profile, features, articles, modder, gamer, member, user'
export const PROFILE_ACCESS_ROUTE_DESCRIPTION = 'Edit your profile'
//end
