<template>
  <div
    class="tw-w-full tw-flex tw-flex-col tw-items-center"
    :class="{
      'tw-pt-5 md:tw-pt-10': !isWidget && !noPadding,
      'tw-relative appear-over tw-bg-theme': appearOverHeader,
    }"
  >
    <div
      class="tw-w-full"
      :class="{
        'tw-container-max-width': !fullWidth,
        [PADDING]: !noPadding,
      }"
    >
      <div class="empty:tw-hidden tw-mb-8 md:tw-mb-10">
        <email-required />
        <modio-banners />
        <view-suggestions />
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
import EmailRequired from '@components/Profile/Panels/EmailRequired.vue'
import { isWidget as isWidgetRoute } from '@helpers/utils.js'
import ModioBanners from '@views/layouts/ModioBanners.vue'
import ViewSuggestions from '@views/ViewSuggestions.vue'

export const PADDING = 'tw-px-4 xs:tw-px-6 lg:tw-px-10'

export default {
  components: {
    ViewSuggestions,
    EmailRequired,
    ModioBanners,
  },
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
    appearOverHeader: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const isWidget = isWidgetRoute()

    return { isWidget, PADDING }
  },
}
</script>

<style scoped>
@media screen and (max-width: 767px) {
  .appear-over {
    z-index: 10;
  }
}
</style>
