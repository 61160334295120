<template>
  <div
    ref="target"
    class="tw-fixed tw-top-0 tw-left-0 tw-pt-16 md:tw-pt-0 tw-w-full xxs:tw-w-72 md:tw-w-18 tw-screenheight tw-transform md:tw-transform-none md:tw-translate-x-0 md:tw-transition-none tw-z-23 tw-bg-dark md:tw-bg-primary tw-text-dark-text md:tw-text-primary-text xxs:tw-border-r md:tw-border-r-0 tw-border-dark-3"
    :class="[
      showMobMenu && !showMenu
        ? 'tw-translate-x-0'
        : 'tw--translate-x-full xxs:tw--translate-x-72 md:tw--translate-x-64',
      { 'tw-transition': !swiping },
    ]"
    :style="{
      transform: mobileMenuX > 0 ? `translateX(-${mobileMenuX}px)` : null,
    }"
  >
    <div class="tw-h-full tw-w-full tw-flex tw-flex-col tw-items-start">
      <div
        class="empty:tw-hidden tw-w-full tw-flex md:tw-hidden tw-border-b-2 tw-p-5 tw-border-theme-3"
      >
        <base-button
          v-if="routeName === GAME_BROWSE_ROUTE"
          full-width
          @click="isLoggedIn ? redirect() : showUserLogin()"
        >
          Integrate a game
        </base-button>
      </div>

      <div
        class="tw-h-full tw-w-full tw-flex tw-flex-col tw-items-start tw-p-5 md:tw-px-3"
      >
        <navigation-container />

        <div class="tw-w-full tw-mt-auto tw-space-y-3">
          <div class="tw-space-y-1">
            <notification-message-buttons sidebar />

            <tooltip-hover
              text="My account"
              hide-on-sm
              position="right"
              background="primary-hover"
              outside-main
            >
              <base-button
                :text-link="isLoggedIn"
                :icon="isLoggedIn ? null : 'user'"
                :primary="!isLoggedIn"
                icon-size="2xl"
                class="tw-hidden md:tw-flex tw-flex-col"
                :class="
                  isLoggedIn
                    ? 'hover:tw-brightness-110 focus:tw-brightness-110 tw-ml-1'
                    : 'tw-ml-0.5'
                "
                @click="toggleUser"
              >
                <template v-if="isLoggedIn" #default>
                  <avatar-icon
                    class="tw-w-10 tw-h-10"
                    inherit-size
                    :user="user"
                  />
                </template>
              </base-button>
              <span
                v-if="isAccountClosed"
                class="tw-hidden md:tw-block tw-absolute tw--bottom-1.5 tw--right-1 tw-py-px tw-px-1 tw-rounded tw-bg-red-500 tw-text-[10px] tw-text-white tw-pointer-events-none"
                >Closed</span
              >
            </tooltip-hover>
          </div>

          <tooltip-hover
            text="mod.io"
            hide-on-sm
            position="right"
            background="primary-hover"
            outside-main
          >
            <base-button
              no-bg
              full-width
              slot-full-width
              @click="showModioFooterDropdown"
            >
              <div class="tw-w-full tw-flex tw-items-center">
                <base-icon
                  class="tw-w-10 tw-h-10 tw-ml-0 md:tw-ml-0.5 tw-fill-white md:tw-fill-current tw-transition-transform motion-safe:hover:tw-animate-[spinAround_1.6s_1_ease-out]"
                  icon="modio_cog_unreg_black"
                />
                <span class="md:tw-hidden tw-ml-4 tw-h5">mod.io</span>
                <font-awesome-icon
                  class="md:tw-hidden tw-ml-auto"
                  icon="chevron-right"
                />
              </div>
            </base-button>
          </tooltip-hover>
        </div>
      </div>
    </div>
  </div>
  <dropdown-container />
  <profile-dropdown-container />
</template>

<script>
import ProfileDropdownContainer from '@components/Navbar/ProfileDropdown/ProfileDropdownContainer.vue'
import NotificationMessageButtons from '@components/Navbar/NotificationMessageButtons.vue'
import NavigationContainer from '@components/Sidebar/NavigationContainer.vue'
import { GAME_BROWSE_ROUTE, GAME_ADD_ROUTE } from '@config/routeNames.js'
import DropdownContainer from '@components/Navbar/DropdownContainer.vue'
import TooltipHover from '@components/Message/TooltipHover.vue'
import useSidebarSwipe from '@composables/useSidebarSwipe.js'
import AvatarIcon from '@components/Users/AvatarIcon.vue'
import { setGlobalModal } from '@composables/useModal.js'
import { MODAL_LOGIN } from '@config/globalModals.js'
import { SS_REDIRECT } from '@config/localstorage.js'
import { useNavMenus, useRoute } from '@composables'
import { authStore } from '@stores'
import { ref } from 'vue'

export default {
  components: {
    NotificationMessageButtons,
    ProfileDropdownContainer,
    NavigationContainer,
    DropdownContainer,
    TooltipHover,
    AvatarIcon,
  },
  setup() {
    const { user, isLoggedIn, isAccountClosed } = authStore()
    const { showModioFooterDropdown, showUserDropdown, showMobMenu, showMenu } =
      useNavMenus()
    const target = ref(null)
    const { swiping, mobileMenuX } = useSidebarSwipe(target)
    const { routeName, push, resolve } = useRoute()

    function redirect() {
      push({ name: GAME_ADD_ROUTE })
    }

    function toggleUser() {
      if (isLoggedIn.value) {
        showUserDropdown()
      } else {
        setGlobalModal({ modal: MODAL_LOGIN })
      }
    }

    function showUserLogin() {
      setGlobalModal({ modal: MODAL_LOGIN })
      const route = resolve({ name: GAME_ADD_ROUTE })
      sessionStorage.setItem(SS_REDIRECT, route.fullPath)
    }

    return {
      showModioFooterDropdown,
      GAME_BROWSE_ROUTE,
      isAccountClosed,
      showUserLogin,
      showMobMenu,
      mobileMenuX,
      isLoggedIn,
      toggleUser,
      routeName,
      showMenu,
      redirect,
      swiping,
      target,
      user,
    }
  },
}
</script>

<style lang="css">
@keyframes spinAround {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}
</style>
