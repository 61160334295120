import { clone } from '@helpers/utils.js'
import {
  METRICS_TRANSACTION_TYPES,
  METRICS_CURRENCY_TYPES,
  STANDARD_EVENT_TYPES,
  GROUP_BY_OPTIONS,
  METRICS_TYPES,
  RECORD_TYPES,
} from '@config/chartOptions.js'
import { ref, watch } from 'vue'
import {
  useMetricsDates,
  useMetricsData,
  useAsync,
  addToast,
} from '@composables'
import {
  getTrendingCreatorDownloadsRequest,
  getTrendingModDownloadsRequest,
  getCCULocationPlaytimeActive,
  getOpenedUserReportsRequest,
  getClosedUserReportsRequest,
  getOpenedModReportsRequest,
  getClosedModReportsRequest,
  getSessionPlaytimeSummary,
  getGuidesCommentsRequest,
  getCreatorJoinedRequest,
  getCCULocationPlaytime,
  getModsCommentsRequest,
  getMonetizationMetrics,
  getSubscriptionRequest,
  getCCUModsUsersActive,
  getUsersBannedRequest,
  getDeletedModsRequest,
  getModsUpdatedRequest,
  getSessionModSummary,
  getFilesAddedRequest,
  getUserJoinedRequest,
  getModsAddedRequest,
  getDownloadsRequest,
  getBandwidthRequest,
  getAuthUsersRequest,
  getPlatformPlaytime,
  getAllUsersRequest,
  getSessionPlatform,
  getCCUTotalSummary,
  getApiKeyRequest,
  getSessionLength,
  getCCUModSummary,
  getCCUByPlatform,
  getCCUSummary,
  getCCUPeak,
} from '@services'

const storage = ref({})
const prevResource = ref(null)

// Stores comparison for scatter chart
const userStorageByDates = ref({})

export default function (settings) {
  const { ugcNameSingle, ugcNamePlural, gameNameId, modNameId, siteAdmin } =
    useMetricsData()
  const { timePeriod, dateRange, gameRef, modRef } = useMetricsDates()
  const error = ref(false)
  const currentResource = modNameId
    ? modNameId
    : gameNameId
      ? gameNameId
      : 'site'
  const secondary = settings?.secondary
  // This is currently used to override only the total stats of a game.
  const gameNameIdOverride = settings?.gameNameIdOverride
  const dateRangeOverride = settings?.dateRangeOverride

  clearStorage()

  // UGC stats dashboard
  const downloadAsync = getAsyncObject({
    request: getDownloadsRequest,
    name: 'downloads',
    enableGeo: true,
    enablePlatform: true,
    modId: modNameId,
  })
  const subscriptionAsync = getAsyncObject({
    request: getSubscriptionRequest,
    name: 'subscriptions',
    enableGeo: true,
    enablePlatform: true,
    enableSecondary: true,
    modId: modNameId,
  })
  const modsAddedAsync = getAsyncObject({
    request: getModsAddedRequest,
    name: `${ugcNamePlural} added`,
  })
  const modsUpdatedAsync = getAsyncObject({
    request: getModsUpdatedRequest,
    name: `${ugcNamePlural} updated`,
  })

  const filesAddedAsync = getAsyncObject({
    request: getFilesAddedRequest,
    name: 'files added',
  })
  const bandwidthAsync = getAsyncObject({
    request: getBandwidthRequest,
    name: 'bandwidth',
    enableGeo: true,
    enablePlatform: true,
  })
  const apiKeyAsync = getAsyncObject({
    request: getApiKeyRequest,
    name: 'key API',
    enableGeo: true,
    enablePlatform: true,
  })

  // User stats dashboard
  const userJoinedAsync = getAsyncObject({
    request: getUserJoinedRequest,
    name: 'users joined',
    enableGeo: false,
    enablePlatform: false,
  })
  const creatorJoinedAsync = getAsyncObject({
    request: getCreatorJoinedRequest,
    name: 'creators joined',
    enableGeo: true,
    enablePlatform: true,
  })
  const modCommentsAsync = getAsyncObject({
    request: getModsCommentsRequest,
    name: `${ugcNamePlural} comments`,
    modId: modNameId,
  })
  const guideCommentsAsync = getAsyncObject({
    request: getGuidesCommentsRequest,
    name: 'guides comments',
  })

  const allUsersAsync = getAsyncObject({
    request: getAllUsersRequest,
    name: 'all users',
    enableGeo: true,
    enablePlatform: true,
    enableSecondary: true,
    modId: modNameId,
  })

  const authUsersAsync = getAsyncObject({
    request: getAuthUsersRequest,
    name: 'auth users',
    enableGeo: true,
    enablePlatform: true,
    modId: modNameId,
  })

  // Health stats dashboard
  const openedModReportsAsync = getAsyncObject({
    request: getOpenedModReportsRequest,
    name: `opened ${ugcNameSingle} reports`,
  })

  const closedModReportsAsync = getAsyncObject({
    request: getClosedModReportsRequest,
    name: `resolved ${ugcNameSingle} reports`,
  })

  const openedUserReportsAsync = getAsyncObject({
    request: getOpenedUserReportsRequest,
    name: 'opened user reports',
  })

  const closedUserReportsAsync = getAsyncObject({
    request: getClosedUserReportsRequest,
    name: 'resolved user reports',
  })

  const deletedModsAsync = getAsyncObject({
    request: getDeletedModsRequest,
    name: `deleted ${ugcNameSingle}`,
  })

  const usersBannedAsync = getAsyncObject({
    request: getUsersBannedRequest,
    name: 'users banned',
  })

  // Monetization stats.
  const { REFUNDED, PENDING, PAID, CLEARED } = METRICS_TRANSACTION_TYPES
  const { MIO, USD } = METRICS_CURRENCY_TYPES
  const tokenPackSalesAsync = getAsyncObject({
    request: getMonetizationMetrics,
    name: 'token pack sales',
    enableGeo: false,
    enablePlatform: false,
    disableTotal: true,
    modId: modNameId,
    eventType: STANDARD_EVENT_TYPES.TRANSACTION_TOKENS,
    filter: {
      transaction_type: PAID,
      currency_type: USD,
    },
  })

  const tokenPackRefundsAsync = getAsyncObject({
    request: getMonetizationMetrics,
    name: 'token pack refunds',
    enableGeo: false,
    enablePlatform: false,
    disableTotal: true,
    modId: modNameId,
    eventType: STANDARD_EVENT_TYPES.TRANSACTION_TOKENS,
    filter: {
      transaction_type: REFUNDED,
      currency_type: USD,
    },
  })

  const marketplaceSalesAsync = getAsyncObject({
    request: getMonetizationMetrics,
    name: 'marketplace sales',
    enableGeo: false,
    enablePlatform: false,
    disableTotal: true,
    modId: modNameId,
    eventType: STANDARD_EVENT_TYPES.TRANSACTION_GROSS,
    filter: {
      transaction_type: PAID,
      currency_type: MIO,
    },
  })

  const marketplaceRefundsAsync = getAsyncObject({
    request: getMonetizationMetrics,
    name: 'marketplace refunds',
    enableGeo: false,
    enablePlatform: false,
    disableTotal: true,
    modId: modNameId,
    eventType: STANDARD_EVENT_TYPES.TRANSACTION_GROSS,
    filter: {
      transaction_type: REFUNDED,
      currency_type: MIO,
    },
  })

  // Overview
  const tokenTransactionTotalAsync = getUseAsyncWithArgs((start, end) =>
    getMonetizationMetrics({
      metricsType: METRICS_TYPES.CARDINALITY,
      recordType: RECORD_TYPES.TOTAL,
      gameId: gameNameId,
      modId: modNameId,
      dateFrom: start,
      dateTo: end,
      filter: {
        transaction_type: CLEARED,
        currency_type: USD,
      },
      eventType: STANDARD_EVENT_TYPES.TRANSACTION_TOKENS,
    })
  )

  const tokenGrossAsync = getUseAsyncWithArgs((start, end) =>
    getMonetizationMetrics({
      recordType: RECORD_TYPES.TOTAL,
      gameId: gameNameId,
      modId: modNameId,
      dateFrom: start,
      dateTo: end,
      filter: {
        transaction_type: CLEARED,
        currency_type: USD,
      },
      eventType: STANDARD_EVENT_TYPES.TRANSACTION_GROSS,
    })
  )

  const tokenNetAsync = getUseAsyncWithArgs((start, end) =>
    getMonetizationMetrics({
      recordType: RECORD_TYPES.TOTAL,
      gameId: gameNameId,
      modId: modNameId,
      dateFrom: start,
      dateTo: end,
      filter: {
        transaction_type: CLEARED,
        currency_type: USD,
      },
      eventType: STANDARD_EVENT_TYPES.TRANSACTION_NET,
    })
  )

  const tokenRefundAsync = getUseAsyncWithArgs((start, end) =>
    getMonetizationMetrics({
      recordType: RECORD_TYPES.TOTAL,
      gameId: gameNameId,
      modId: modNameId,
      dateFrom: start,
      dateTo: end,
      filter: {
        transaction_type: REFUNDED,
        currency_type: USD,
      },
      eventType: STANDARD_EVENT_TYPES.TRANSACTION_GROSS,
    })
  )

  const marketplaceTransactionTotalAsync = getUseAsyncWithArgs((start, end) =>
    getMonetizationMetrics({
      metricsType: METRICS_TYPES.CARDINALITY,
      recordType: RECORD_TYPES.TOTAL,
      gameId: gameNameId,
      modId: modNameId,
      dateFrom: start,
      dateTo: end,
      filter: {
        transaction_type: CLEARED,
        currency_type: USD,
      },
      eventType: STANDARD_EVENT_TYPES.TRANSACTION_GROSS,
    })
  )

  const marketplaceGrossAsync = getUseAsyncWithArgs((start, end) =>
    getMonetizationMetrics({
      recordType: RECORD_TYPES.TOTAL,
      gameId: gameNameId,
      modId: modNameId,
      dateFrom: start,
      dateTo: end,
      filter: {
        transaction_type: CLEARED,
        currency_type: MIO,
      },
      eventType: STANDARD_EVENT_TYPES.TRANSACTION_GROSS,
    })
  )

  const marketplaceNetAsync = getUseAsyncWithArgs((start, end) =>
    getMonetizationMetrics({
      recordType: RECORD_TYPES.TOTAL,
      gameId: gameNameId,
      modId: modNameId,
      dateFrom: start,
      dateTo: end,
      filter: {
        transaction_type: CLEARED,
        currency_type: MIO,
      },
      eventType: STANDARD_EVENT_TYPES.TRANSACTION_NET,
    })
  )

  const marketplaceRefundAsync = getUseAsyncWithArgs((start, end) =>
    getMonetizationMetrics({
      recordType: RECORD_TYPES.TOTAL,
      gameId: gameNameId,
      modId: modNameId,
      dateFrom: start,
      dateTo: end,
      filter: {
        transaction_type: REFUNDED,
        currency_type: MIO,
      },
      eventType: STANDARD_EVENT_TYPES.TRANSACTION_GROSS,
    })
  )

  // Monetization pending stats
  const tokenTransactionPendingAsync = getUseAsyncWithArgs((start, end) =>
    getMonetizationMetrics({
      metricsType: METRICS_TYPES.CARDINALITY,
      recordType: RECORD_TYPES.TOTAL,
      gameId: gameNameId,
      modId: modNameId,
      dateFrom: start,
      dateTo: end,
      filter: {
        transaction_type: PENDING,
        currency_type: USD,
      },
      eventType: STANDARD_EVENT_TYPES.TRANSACTION_TOKENS,
    })
  )

  const tokenGrossPendingAsync = getUseAsyncWithArgs((start, end) =>
    getMonetizationMetrics({
      recordType: RECORD_TYPES.TOTAL,
      gameId: gameNameId,
      modId: modNameId,
      dateFrom: start,
      dateTo: end,
      filter: {
        transaction_type: PENDING,
        currency_type: USD,
      },
      eventType: STANDARD_EVENT_TYPES.TRANSACTION_GROSS,
    })
  )

  const tokenNetPendingAsync = getUseAsyncWithArgs((start, end) =>
    getMonetizationMetrics({
      recordType: RECORD_TYPES.TOTAL,
      gameId: gameNameId,
      modId: modNameId,
      dateFrom: start,
      dateTo: end,
      filter: {
        transaction_type: PENDING,
        currency_type: USD,
      },
      eventType: STANDARD_EVENT_TYPES.TRANSACTION_NET,
    })
  )

  const marketplaceTransactionPendingAsync = getUseAsyncWithArgs((start, end) =>
    getMonetizationMetrics({
      metricsType: METRICS_TYPES.CARDINALITY,
      recordType: RECORD_TYPES.TOTAL,
      gameId: gameNameId,
      modId: modNameId,
      dateFrom: start,
      dateTo: end,
      filter: {
        transaction_type: PENDING,
        currency_type: USD,
      },
      eventType: STANDARD_EVENT_TYPES.TRANSACTION_GROSS,
    })
  )

  const marketplaceGrossPendingAsync = getUseAsyncWithArgs((start, end) =>
    getMonetizationMetrics({
      recordType: RECORD_TYPES.TOTAL,
      gameId: gameNameId,
      modId: modNameId,
      dateFrom: start,
      dateTo: end,
      filter: {
        transaction_type: PENDING,
        currency_type: MIO,
      },
      eventType: STANDARD_EVENT_TYPES.TRANSACTION_GROSS,
    })
  )

  const marketplaceNetPendingAsync = getUseAsyncWithArgs((start, end) =>
    getMonetizationMetrics({
      recordType: RECORD_TYPES.TOTAL,
      gameId: gameNameId,
      modId: modNameId,
      dateFrom: start,
      dateTo: end,
      filter: {
        transaction_type: PENDING,
        currency_type: MIO,
      },
      eventType: STANDARD_EVENT_TYPES.TRANSACTION_NET,
    })
  )

  // Session stats dashboard
  const sessionPlaytimeAsync = getAsyncObject({
    request: getPlatformPlaytime,
    name: 'platform playtime',
    disableTotal: true,
    enablePlatform: true,
  })

  const ccuPlatformAsync = getAsyncObject({
    request: getCCUByPlatform,
    name: 'ccu total',
    disableTotal: true,
    enablePlatform: true,
  })

  const ccuPeakAsync = getAsyncObject({
    request: getCCUPeak,
    name: 'ccu peak',
    disableTotal: true,
    enablePlatform: true,
  })

  const getSessionLengthAsync = getUseAsyncWithArgs((start, end) =>
    getSessionLength(gameNameId, start, end)
  )

  const getSessionPlatformAsync = getUseAsyncWithArgs((start, end) =>
    getSessionPlatform(gameNameId, start, end)
  )

  const getCCUModSummaryAsync = getUseAsyncWithArgs((start, end) =>
    getCCUModSummary(gameNameId, start, end)
  )

  const getCCUSummaryAsync = getUseAsyncWithArgs((start, end) =>
    getCCUSummary(gameNameId, start, end)
  )

  const getSessionModSummaryAsync = getUseAsyncWithArgs((start, end) =>
    getSessionModSummary(gameNameId, start, end)
  )

  const getSessionPlaytimeSummaryAsync = getUseAsyncWithArgs((start, end) =>
    getSessionPlaytimeSummary(gameNameId, start, end)
  )

  const getCCULocationPlaytimeAsync = getUseAsyncWithArgs((start, end) =>
    getCCULocationPlaytime(gameNameId, start, end)
  )

  const getCCULocationPlaytimeActiveAsync = getUseAsyncWithArgs((start, end) =>
    getCCULocationPlaytimeActive(gameNameId, start, end)
  )

  const getCCUTotalSummaryAsync = getUseAsyncWithArgs(() =>
    getCCUTotalSummary(gameNameId)
  )

  const getCCUModsUsersActiveAsync = getUseAsyncWithArgs((start, end) =>
    getCCUModsUsersActive(gameNameId, start, end)
  )

  async function handleAsync(asyncInfo, ...args) {
    await asyncInfo.run.apply(null, args)
    if (asyncInfo.error.value) {
      error.value = true
    }
  }

  function getUseAsyncWithArgs(cb) {
    const asyncObj = useAsync(cb)
    asyncObj.get = (...args) => handleAsync(asyncObj, ...args)

    return asyncObj
  }

  function getAsyncObject({
    enableSecondary,
    enablePlatform,
    disableTotal,
    enableGeo,
    eventType,
    request,
    groupBy,
    filter,
    modId,
  }) {
    const _dateRange = dateRangeOverride ?? dateRange
    const asyncInfo = {
      total: !disableTotal
        ? useAsync((startTimestampInSeconds) =>
            request({
              gameId: gameNameIdOverride ?? gameNameId,
              dateFrom: startTimestampInSeconds || _dateRange.value.startDate,
              dateTo: _dateRange.value.endDate,
              recordType: RECORD_TYPES.TOTAL,
              modId: modId,
              site: siteAdmin && !gameNameIdOverride,
            })
          )
        : {
            loading: ref(false),
            error: ref(''),
            data: ref({}),
            run: () => ({}),
          },
      monthly: enablePlatform
        ? useAsync(() =>
            request({
              gameId: gameNameId,
              dateFrom: dateRange.value.startDate.startOf('month'),
              dateTo: dateRange.value.endDate,
              eventType,
              groupBy: GROUP_BY_OPTIONS.PLATFORM,
              recordType: RECORD_TYPES.MONTHLY,
              filter,
              modId: modId,
              site: siteAdmin,
            })
          )
        : useAsync(() =>
            request({
              gameId: gameNameId,
              dateFrom: dateRange.value.startDate.startOf('month'),
              dateTo: dateRange.value.endDate,
              recordType: RECORD_TYPES.MONTHLY,
              eventType,
              groupBy,
              filter,
              modId: modId,
              site: siteAdmin,
            })
          ),
      daily: enablePlatform
        ? useAsync(() =>
            request({
              gameId: gameNameId,
              dateFrom: dateRange.value.startDate,
              dateTo: dateRange.value.endDate,
              eventType,
              groupBy: GROUP_BY_OPTIONS.PLATFORM,
              recordType: RECORD_TYPES.DAILY,
              filter,
              modId: modId,
              site: siteAdmin,
            })
          )
        : useAsync(() =>
            request({
              gameId: gameNameId,
              dateFrom: dateRange.value.startDate,
              dateTo: dateRange.value.endDate,
              recordType: RECORD_TYPES.DAILY,
              eventType,
              groupBy,
              filter,
              modId: modId,
              site: siteAdmin,
            })
          ),
      geo: enableGeo
        ? useAsync(() =>
            request({
              gameId: gameNameId,
              dateFrom: dateRange.value.startDate,
              dateTo: dateRange.value.endDate,
              eventType,
              groupBy: GROUP_BY_OPTIONS.LOCATION,
              recordType: RECORD_TYPES.TOTAL,
              filter,
              modId: modId,
              site: siteAdmin,
            })
          )
        : {
            loading: ref(false),
            error: ref(''),
            data: ref({}),
            run: () => ({}),
          },
      dailySecondaryOne:
        secondary?.one && enableSecondary
          ? useAsync(() =>
              secondary.one.request({
                gameId: gameNameId,
                dateFrom: dateRange.value.startDate,
                dateTo: dateRange.value.endDate,
                recordType: RECORD_TYPES.DAILY,
                modId: modId,
                site: siteAdmin,
                ...secondary.one.requestParams,
              })
            )
          : {
              loading: ref(false),
              error: ref(''),
              data: ref({}),
              run: () => ({}),
            },
      dailySecondaryTwo:
        secondary?.two && enableSecondary
          ? useAsync(() =>
              secondary.two.request({
                gameId: gameNameId,
                dateFrom: dateRange.value.startDate,
                dateTo: dateRange.value.endDate,
                recordType: RECORD_TYPES.DAILY,
                modId: modId,
                site: siteAdmin,
                ...secondary.two.requestParams,
              })
            )
          : {
              loading: ref(false),
              title: '',
              error: ref(''),
              data: ref({}),
              run: () => ({}),
            },
    }

    asyncInfo.total.get = (timestamp) => handleAsync(asyncInfo.total, timestamp)
    asyncInfo.monthly.get = () => handleAsync(asyncInfo.monthly)
    asyncInfo.daily.get = () => handleAsync(asyncInfo.daily)
    asyncInfo.geo.get = () => handleAsync(asyncInfo.geo)

    // secondary stats used by select dashboards
    if (secondary?.one && enableSecondary)
      asyncInfo.dailySecondaryOne.get = () =>
        handleAsync(asyncInfo.dailySecondaryOne)
    else asyncInfo.dailySecondaryOne.get = () => {}

    if (secondary?.two && enableSecondary)
      asyncInfo.dailySecondaryTwo.get = () =>
        handleAsync(asyncInfo.dailySecondaryTwo)
    else asyncInfo.dailySecondaryTwo.get = () => {}

    return asyncInfo
  }

  // Fetched data are naively stored per chart per time period for a dashboard
  // Except for custom period data that is still re-fetched every time
  function getStorage(type, tabName) {
    return storage.value[tabName]?.[timePeriod.value]?.[type]
  }

  function setStorage(type, data, tabName) {
    const _timePeriod = timePeriod.value
    if (!storage.value[tabName]) storage.value[tabName] = {}
    if (!storage.value[tabName][_timePeriod])
      storage.value[tabName][_timePeriod] = {}

    storage.value[tabName][_timePeriod][type] = clone(data)
  }

  function clearStorage() {
    if (currentResource != prevResource.value) {
      gameRef.value = undefined
      modRef.value = undefined

      storage.value = {}
      userStorageByDates.value = {}
      prevResource.value = currentResource
    }
  }

  // Handle trending requests.
  function getTrendingDownloadsAsync(request, start, end, filter) {
    const requestParams = {
      gameId: gameNameId,
      dateFrom: start,
      dateTo: end,
      modId: modNameId,
      site: siteAdmin,
      filter,
    }

    const asyncInfo = useAsync(() => request(requestParams))
    asyncInfo.get = () => handleAsync(asyncInfo)

    return asyncInfo
  }

  function getTrendingModDownloadsAsync(start, end, filter) {
    return getTrendingDownloadsAsync(
      getTrendingModDownloadsRequest,
      start,
      end,
      filter
    )
  }
  function getTrendingCreatorDownloadsAsync(start, end, filter) {
    return getTrendingDownloadsAsync(
      getTrendingCreatorDownloadsRequest,
      start,
      end,
      filter
    )
  }

  async function fetchDataIntoState(timePeriod, asyncRequest, start, end) {
    if (
      timePeriod > 0 &&
      !asyncRequest.state.value[timePeriod] &&
      start &&
      end
    ) {
      await asyncRequest.getAsync.get(start, end)
      asyncRequest.state.value[timePeriod] = asyncRequest.getAsync.data.value
    }
  }

  watch(error, (_error) => {
    if (_error) {
      addToast({
        title: 'Failed to retrieve complete stats data',
        isError: true,
        text: 'An unexpected error has occurred while retrieving stats, some data may be missing. Please try again later.',
      })
    }
  })

  return {
    getCCULocationPlaytimeActiveAsync,
    getTrendingCreatorDownloadsAsync,
    getSessionPlaytimeSummaryAsync,
    getTrendingModDownloadsAsync,
    getCCULocationPlaytimeAsync,
    getCCUModsUsersActiveAsync,
    getSessionModSummaryAsync,
    getSessionPlatformAsync,
    getCCUTotalSummaryAsync,
    openedUserReportsAsync,
    closedUserReportsAsync,
    closedModReportsAsync,
    openedModReportsAsync,
    getSessionLengthAsync,
    getCCUModSummaryAsync,
    sessionPlaytimeAsync,
    getCCUSummaryAsync,
    userStorageByDates,
    guideCommentsAsync,
    creatorJoinedAsync,
    fetchDataIntoState,
    subscriptionAsync,
    usersBannedAsync,
    deletedModsAsync,
    modsUpdatedAsync,
    ccuPlatformAsync,
    modCommentsAsync,
    userJoinedAsync,
    filesAddedAsync,
    bandwidthAsync,
    modsAddedAsync,
    authUsersAsync,
    downloadAsync,
    allUsersAsync,
    ccuPeakAsync,
    apiKeyAsync,
    getStorage,
    setStorage,
    error,
    // Monetization overview
    marketplaceTransactionTotalAsync,
    tokenTransactionTotalAsync,
    marketplaceRefundAsync,
    marketplaceGrossAsync,
    marketplaceNetAsync,
    tokenRefundAsync,
    tokenGrossAsync,
    tokenNetAsync,
    // Monetization trends
    marketplaceRefundsAsync,
    marketplaceSalesAsync,
    tokenPackRefundsAsync,
    tokenPackSalesAsync,
    // Monetization pending overview
    marketplaceTransactionPendingAsync,
    marketplaceGrossPendingAsync,
    tokenTransactionPendingAsync,
    marketplaceNetPendingAsync,
    tokenGrossPendingAsync,
    tokenNetPendingAsync,
  }
}
