import { areBitsSet } from '@helpers/utils.js'
import {
  MONETIZATION_FORCED_SCARCITY,
  MONETIZATION_PARTNER_PROGRAM,
  MONETIZATION_MARKETPLACE,
  MONETIZATION_SCARCITY,
  TILIA_STATUS_APPROVED,
  MONETIZATION_FILELESS,
  MONETIZATION_ENABLED,
  TILIA_STATUS_MEMBER,
} from '@config/monetization.js'
import {
  GAME_COMMUNITY_ALLOW_NEGATIVE_RATINGS,
  GAME_COMMUNITY_SHOW_MORE_ON_HOMEPAGE,
  GAME_COMMUNITY_ALLOW_EDITS_VIA_WEB,
  GAME_COMMUNITY_ALLOW_PREVIEW_URLS,
  MOD_COMMUNITY_ALLOW_PREVIEW_URLS,
  GAME_COMMUNITY_SHOW_ON_HOMEPAGE,
  MOD_COMMUNITY_ALLOW_DEPENDENCY,
  GAME_COMMUNITY_ALLOW_COMMENTS,
  GAME_COMMUNITY_PIN_TO_BROWSE,
  GAME_COMMUNITY_CHANGE_STATUS,
  GAME_COMMUNITY_ALLOW_PREVIEW,
  MOD_COMMUNITY_ALLOW_COMMENTS,
  MOD_COMMUNITY_ALLOW_PREVIEW,
  GAME_COMMUNITY_ALLOW_GUIDES,
  API_AUTHORISED_DOWNLOADS,
  API_THIRD_PARTY_ACCESS,
  API_DIRECT_DOWNLOAD,
  API_PAID_DOWNLOADS,
  COMMENT_PINNED,
  COMMENT_LOCKED,
  CURATION_PAID,
  CURATION_FULL,
} from '@config/options.js'

export function allowAddGuide(bits) {
  return areBitsSet(bits, GAME_COMMUNITY_ALLOW_GUIDES)
}

export function allowGameComments(bits) {
  return areBitsSet(bits, GAME_COMMUNITY_ALLOW_COMMENTS)
}

export function isPinnedToBrowse(bits) {
  return areBitsSet(bits, GAME_COMMUNITY_PIN_TO_BROWSE)
}

export function isShowOnHomepage(bits) {
  return areBitsSet(bits, GAME_COMMUNITY_SHOW_ON_HOMEPAGE)
}

export function allowNegativeRatings(bits) {
  return areBitsSet(bits, GAME_COMMUNITY_ALLOW_NEGATIVE_RATINGS)
}

export function isEditableOnWeb(bits) {
  return areBitsSet(bits, GAME_COMMUNITY_ALLOW_EDITS_VIA_WEB)
}

export function isShowMoreOnHomepage(bits) {
  return areBitsSet(bits, GAME_COMMUNITY_SHOW_MORE_ON_HOMEPAGE)
}

export function allowChangeStatus(bits) {
  return areBitsSet(bits, GAME_COMMUNITY_CHANGE_STATUS)
}

export function allowGamePreview(bits) {
  return areBitsSet(bits, GAME_COMMUNITY_ALLOW_PREVIEW)
}

export function allowGamePreviewURLs(bits) {
  return areBitsSet(bits, GAME_COMMUNITY_ALLOW_PREVIEW_URLS)
}

export function isGameCurationPaid(bits) {
  return areBitsSet(bits, CURATION_PAID)
}

export function isGameCurationLive(bits) {
  return areBitsSet(bits, CURATION_FULL, true)
}

export function allowModPreview(bits) {
  return areBitsSet(bits, MOD_COMMUNITY_ALLOW_PREVIEW)
}

export function allowModPreviewURLs(bits) {
  return areBitsSet(bits, MOD_COMMUNITY_ALLOW_PREVIEW_URLS)
}

export function allowModComments(bits) {
  return areBitsSet(bits, MOD_COMMUNITY_ALLOW_COMMENTS)
}

export function allowModDependency(bits) {
  return areBitsSet(bits, MOD_COMMUNITY_ALLOW_DEPENDENCY)
}

export function allowDirectDownloads(bits) {
  return areBitsSet(bits, API_DIRECT_DOWNLOAD)
}

export function allowThirdPartyAccess(bits) {
  return areBitsSet(bits, API_THIRD_PARTY_ACCESS)
}

export function isMonetizationEnabled(bits) {
  return areBitsSet(bits, MONETIZATION_ENABLED)
}

export function isMarketplaceEnabled(bits) {
  return areBitsSet(bits, MONETIZATION_MARKETPLACE)
}

export function isPartnerProgramEnabled(bits) {
  return areBitsSet(bits, MONETIZATION_PARTNER_PROGRAM)
}

export function isScarcityEnabled(bits) {
  return areBitsSet(bits, MONETIZATION_SCARCITY)
}

export function isForcedScarcityEnabled(bits) {
  return areBitsSet(bits, MONETIZATION_FORCED_SCARCITY)
}

export function isMonetizationFileless(bits) {
  return areBitsSet(bits, MONETIZATION_FILELESS)
}

export function isUserOnboarded(bits) {
  return areBitsSet(bits, TILIA_STATUS_APPROVED)
}

export function userHasCreatorWallets(bits) {
  return areBitsSet(bits, TILIA_STATUS_MEMBER)
}

export function restrictDownloadsAuth(bits) {
  return areBitsSet(bits, API_AUTHORISED_DOWNLOADS)
}

export function restrictDownloadsPaid(bits) {
  return areBitsSet(bits, API_PAID_DOWNLOADS)
}

export function isCommentPinned(bits) {
  return areBitsSet(bits, COMMENT_PINNED)
}

export function isCommentLocked(bits) {
  return areBitsSet(bits, COMMENT_LOCKED)
}
