<template>
  <footer
    class="tw-bg-primary tw-text-black tw-text-sm xs:tw-text-md 2xl:tw-text-md tw-py-8"
  >
    <div class="tw-homepage-width tw-mx-auto tw-px-8">
      <div
        class="tw-flex tw-flex-col md:tw-flex-row md:tw-space-x-4 tw-text-center md:tw-text-left tw-space-y-12 md:tw-space-y-0"
      >
        <div
          class="tw-flex tw-flex-col tw-justify-between md:tw-w-5/12 lg:tw-w-7/12 tw-space-y-5"
        >
          <div
            class="tw-flex tw-flex-col tw-items-center md:tw-items-start tw-space-y-2"
          >
            <base-link
              :to="isHomepage ? '#' : { name: HOME_ROUTE }"
              title="mod.io home"
              class="tw-outline-none focus:tw-outline-white"
              :link-primary="false"
              @click="scrollToTop"
            >
              <base-icon style="max-width: 148px" icon="modio_reg_black" />
            </base-link>
          </div>

          <p class="tw-text-md tw-font-bold">Enable. Create. Play.</p>

          <div
            class="tw-flex tw-justify-center md:tw-justify-start tw-space-x-4 sm:tw-space-x-6"
          >
            <a
              v-for="(social, socialIndex) in FOOTER.social"
              :key="socialIndex"
              :href="social.link"
              target="_blank"
              rel="noreferrer noopener"
              class="tw-flex tw-items-center tw-justify-center tw-text-black hover:tw-text-primary-hover focus:tw-text-primary-hover tw-outline-none tw-home-button-transition tw-shrink-0"
              :alt="social.title"
            >
              <font-awesome-icon :icon="['fab', `${social.icon}`]" size="xl" />
            </a>
          </div>
        </div>

        <div
          class="md:tw-w-7/12 lg:tw-w-5/12 tw-flex tw-justify-center xs:tw-justify-between md:tw-text-right xs:tw-text-center"
        >
          <div
            v-for="(menu, menuIndex) in FOOTER.menus"
            :key="menuIndex"
            class="tw-w-32 xs:tw-w-4/12 xl:tw-w-3/12 tw-space-y-4 tw-mb-4 xs:tw-mb-0"
          >
            <p class="tw-font-bold tw-text-base tw-text-black">
              {{ menu.title }}
            </p>

            <nav>
              <ul class="tw-space-y-0.5">
                <li
                  v-for="(itemLink, submenuIndex) in menu.menu"
                  :key="submenuIndex"
                >
                  <a
                    v-if="itemLink.external"
                    :href="itemLink.link"
                    target="_blank"
                    class="hover:tw-text-primary-hover focus:tw-text-primary-hover tw-outline-none"
                  >
                    {{ itemLink.text }}
                  </a>
                  <button
                    v-else-if="itemLink.modal"
                    class="hover:tw-text-primary-hover focus:tw-text-primary-hover tw-outline-none"
                    @click="triggerModal(itemLink.modal)"
                  >
                    {{ itemLink.text }}
                  </button>
                  <base-link
                    v-else
                    :to="{ name: itemLink.link }"
                    hover-primary
                    :link-primary="false"
                  >
                    {{ itemLink.text }}
                  </base-link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <div class="tw-w-full tw-h-0.5 tw-bg-grey tw-opacity-40 tw-my-5" />

      <div class="tw-flex tw-flex-row tw-justify-between">
        <div class="tw-mr-3">
          <p class="tw-font-bold">&copy; {{ footerText }}</p>
          <span class="tw-whitespace-pre-line">{{ FOOTER.copyright }}</span>
        </div>
        <div
          class="tw-flex tw-flex-col md:tw-flex-row tw-items-start md:tw-space-x-10 tw-font-bold tw-whitespace-nowrap"
        >
          <base-link
            v-for="item in FOOTER.terms"
            :key="item.text"
            hover-primary
            :link-primary="false"
            :to="{ name: item.link }"
          >
            {{ item.text }}
          </base-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { setGlobalModal } from '@composables/useModal.js'
import { scrollToElement } from '@helpers/utils.js'
import { HOME_ROUTE } from '@config/routeNames.js'
import { FOOTER } from '@home_config/footer.js'
import { useRoute } from 'vue-router'

export default {
  setup() {
    const footerText = `${new Date().getFullYear()} ${FOOTER.modio}`

    const isHomepage = useRoute().routeName === HOME_ROUTE

    function triggerModal(modal) {
      setGlobalModal({ modal, data: { homepage: true } })
    }

    function scrollToTop() {
      scrollToElement('bodyTop')
    }

    return {
      triggerModal,
      scrollToTop,
      isHomepage,
      HOME_ROUTE,
      footerText,
      FOOTER,
    }
  },
}
</script>
