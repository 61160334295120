import { computed, ref } from 'vue'

const stepComponent = ref(null)
const options = ref(null)

export default function () {
  const step = computed(() => stepComponent.value)
  const stepOptions = computed(() => options.value)

  const componentId = computed(() =>
    stepOptions.value
      ? stepOptions.value.findIndex((x) => x.component === step.value)
      : null
  )

  const showPrevBtn = computed(() => componentId.value !== 0)
  const showNextBtn = computed(() =>
    stepOptions.value
      ? componentId.value !== stepOptions.value.length - 1
      : null
  )

  function prevStep() {
    changeStep(stepOptions.value[componentId.value - 1].component)
  }

  function nextStep() {
    changeStep(stepOptions.value[componentId.value + 1].component)
  }

  function changeStep(component) {
    stepComponent.value = component
    _updateOptions()
  }

  function clearStepList() {
    stepComponent.value = null
  }

  function setOptions(value) {
    options.value = value
    _updateDefault()
  }

  function _updateOptions() {
    const tempOptions = options.value
    options.value = tempOptions.map((o, index) => {
      return {
        component: o.component,
        text: o.text,
        disabled: componentId.value !== index && index > componentId.value,
        completed: componentId.value !== index && index < componentId.value,
      }
    })
  }

  function _updateDefault() {
    if (!stepComponent.value) {
      stepComponent.value = stepOptions.value[0].component
    }
  }

  return {
    clearStepList,
    showPrevBtn,
    showNextBtn,
    stepOptions,
    setOptions,
    changeStep,
    prevStep,
    nextStep,
    step,
  }
}
