<template>
  <div
    class="tw-flex tw-flex-col tw-button-transition tw-global--border-radius tw-border-2 tw-relative"
    :class="[
      typeBorder,
      contentMessage
        ? 'tw-bg-black-opacity tw-px-4 xs:tw-px-6'
        : 'tw-bg-theme-3 tw-base-panel-px',
      small ? 'tw-py-3' : 'tw-py-6',
    ]"
  >
    <base-button
      v-if="hasClose"
      text-link-hover
      :info="info || (!warning && !danger)"
      :warning="warning"
      :danger="danger"
      icon="times"
      icon-size="lg"
      sr-only="Close message"
      class="tw-absolute tw-top-1 tw-right-1"
      @click="$emit('close:message')"
    >
    </base-button>

    <div class="tw-flex tw-items-center" :class="hasIcon ? 'tw-space-x-4' : ''">
      <font-awesome-icon
        v-if="hasIcon"
        size="lg"
        :icon="typeIcon"
        class="tw-hidden xs:tw-block"
        :class="typeText"
      />
      <div v-if="title || text" class="tw-space-y-1">
        <span v-if="title" class="tw-text-h6 tw-font-bold">{{ title }}</span>
        <p v-if="text">{{ text }}</p>
      </div>

      <div v-if="$slots.default" class="tw-flex tw-flex-col tw-w-full">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, toRefs } from 'vue'
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    hasClose: {
      type: Boolean,
      default: true,
    },
    hasIcon: {
      type: Boolean,
      default: true,
    },
    info: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    danger: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    contentMessage: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close:message'],
  setup(props) {
    const { info, success, warning, danger, primary } = toRefs(props)

    const typeBorder = computed(() => {
      if (success.value) {
        return 'tw-border-success'
      } else if (warning.value) {
        return 'tw-border-warning'
      } else if (danger.value) {
        return 'tw-border-danger'
      } else if (info.value) {
        return 'tw-border-info'
      } else if (primary.value) {
        return 'tw-border-primary'
      } else {
        return 'tw-border-info'
      }
    })

    const typeIcon = computed(() => {
      if (success.value) {
        return 'check'
      } else if (danger.value) {
        return 'times'
      } else if (warning.value) {
        return 'exclamation-triangle'
      } else if (info.value) {
        return 'info-circle'
      } else if (primary.value) {
        return 'info-circle'
      } else {
        return 'info-circle'
      }
    })

    const typeText = computed(() => {
      if (success.value) {
        return 'tw-text-success'
      } else if (warning.value) {
        return 'tw-text-warning'
      } else if (danger.value) {
        return 'tw-text-danger'
      } else if (info.value) {
        return 'tw-text-info'
      } else if (primary.value) {
        return 'tw-text-primary'
      } else {
        return 'tw-text-info'
      }
    })

    return {
      typeBorder,
      typeIcon,
      typeText,
    }
  },
}
</script>
