import { watchEffect, onMounted, readonly, unref, ref } from 'vue'
import { SORT_BAR_TOP, GAME_INFO_HEADER_HEIGHT } from '@config'
import { SCROLL_TO_ID } from '@config/htmlIDs.js'
import { useScroll } from '@vueuse/core'

export default function useScrollTop(infoHeaderOpen) {
  const _hasScrolled = ref(false)
  onMounted(() => {
    const { y } = useScroll(document.getElementById(SCROLL_TO_ID))
    watchEffect(() => {
      const headerHeight = unref(infoHeaderOpen)
        ? SORT_BAR_TOP + GAME_INFO_HEADER_HEIGHT
        : SORT_BAR_TOP
      _hasScrolled.value = y.value > headerHeight
    })
  })

  return {
    hasScrolled: readonly(_hasScrolled),
  }
}
