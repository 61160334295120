import isAlphanumeric from 'validator/lib/isAlphanumeric'
import isEmail from 'validator/lib/isEmail'
import isAlpha from 'validator/lib/isAlpha'
import isUUID from 'validator/lib/isUUID'
import isURL from 'validator/lib/isURL'
import { unref } from 'vue'
import {
  humanFilesize,
  isBoolean,
  isNumber,
  isRegex,
  isFile,
  isObj,
} from '@helpers/utils.js'

// Each validation rule will accept input and optional error message
export const required = ({ input, error } = {}) => {
  if (isObj(input) && input.file === null) {
    return error ? error : 'Field is required'
  }

  return input === 1 ||
    input === '1' ||
    input === 0 ||
    input === '0' ||
    input === false ||
    (input && input.length > 0) ||
    isObj(input) ||
    input
    ? true
    : error
      ? error
      : 'Field is required'
}

export const requiredNotZero = ({ input, error } = {}) => {
  return (input === 1 ||
    input === '1' ||
    (input && input.length > 0) ||
    isObj(input) ||
    input) &&
    input !== 0 &&
    input !== '0'
    ? true
    : error
      ? error
      : 'Field is required'
}

export const validateIf = (condition, validation, error) => {
  const fn = {
    temp: function ({ input }) {
      if (condition()) {
        return validation({ input, error })
      }
      return true
    },
  }['temp']

  fn.funcName = validation.name
  return fn
}

export const match = (word, error) => {
  return function match({ input } = {}) {
    if (!input) return true
    return input === word ? true : error ? error : `Input must match ${word}`
  }
}

// used for required check checkboxes
export const checked = ({ input, error } = {}) => {
  return input === 1 || input === true
    ? true
    : error
      ? error
      : 'Field must be checked'
}

// Checks input is truthy accepts string 0 and 1
export const boolean = ({ input, error } = {}) => {
  if (!input) return true
  return isBoolean(input) ? true : error ? error : 'Checkbox required'
}

export const number = ({ input, error } = {}) => {
  if (!input) return true
  return isNumber(input) ? true : error ? error : 'Field must be a number'
}

// TODO: SR any fields that are type email, url or number should have right type set (i.e type="email" not type="text" to auto-inform browsers their purpose).
// See: https://www.bugherd.com/projects/245354/tasks/175
export const email = ({ input, error } = {}) => {
  if (!input) {
    return true
  }
  return input && isEmail(input)
    ? true
    : error
      ? error
      : 'Field must be an email'
}

export const regexInput = ({ input, error }) => {
  if (!input) return true

  let valid = true
  let validationError = 'Invalid regular expression'

  if (input.slice(0) !== '/' && input.slice(-1) !== '/') {
    valid = false
    validationError = `${validationError}. Must start and end with forward slash /`
  } else {
    try {
      new RegExp(input)
    } catch (e) {
      valid = false
    }
  }

  return valid ? valid : error ? error : validationError
}

export const minInt = (number) => {
  return function minInt({ input, error } = {}) {
    if (!input) return true
    return input >= number
      ? true
      : error
        ? error
        : `Field must be greater or equal to ${number}`
  }
}

export const maxInt = (number) => {
  return function maxInt({ input, error }) {
    if (!input) return true
    return input <= number
      ? true
      : error
        ? error
        : `Field must be lesser or equal to ${number}`
  }
}

export const min = (number) => {
  return function min({ input, error } = {}) {
    if (!input) return true
    return input && input.length >= number
      ? true
      : error
        ? error
        : `Minimum ${number} characters`
  }
}

export const max = (number) => {
  return function max({ input, error }) {
    if (!input) return true
    return input && input.length <= number
      ? true
      : error
        ? error
        : `Maximum ${number} characters`
  }
}

export const url = ({
  requireProtocol = false,
  allowQueryParams = true,
} = {}) => {
  return function ({ input, error } = {}) {
    if (!input) return true

    let result = isURL(input, {
      require_protocol: !!requireProtocol,
      require_tld: !input.match(/:\d+/),
      allow_query_components: allowQueryParams,
    })

    if (!result) {
      if (error) return error

      let errorMsg = 'Field must be a URL'

      if (input.includes('?') && !allowQueryParams)
        errorMsg = 'The URL must not include query parameters'

      if (!input.includes('http') && requireProtocol)
        errorMsg = 'The URL must include the protocol (e.g. https://...)'

      return errorMsg
    }

    return result
  }
}

export const noWhitespace = ({ commaSeparator = false } = {}) => {
  return function ({ input, error } = {}) {
    if (/\s/g.test(input)) {
      if (error) return error

      if (commaSeparator) {
        return `Field must have no white space. Please use commas to separate`
      }
      return 'Field must have no white space'
    }

    return true
  }
}

export const uuid = ({ input, error } = {}) => {
  if (!input) return true

  return isUUID(input) ? true : error ? error : 'Field must be a UUID'
}

export const between = (min, max) => {
  return ({ input, error } = {}) => {
    if (input === null || input === undefined) return true
    return input >= min && input <= max
      ? true
      : error
        ? error
        : min === max
          ? `Field must be ${min}`
          : `Field must be between ${min} & ${max}`
  }
}
export const size = (len) => {
  return ({ input, error } = {}) => {
    if (!input) return true
    return input && input.length === len
      ? true
      : error
        ? error
        : `Field must be ${len} characters`
  }
}

export const alphaNum = ({ input, error } = {}) => {
  if (!input) return true

  return input && isAlphanumeric(input)
    ? true
    : error
      ? error
      : 'Field can only contain numbers or letters'
}

export const alpha = ({ input, error } = {}) => {
  if (!input) return true
  return isAlpha(input)
    ? true
    : error
      ? error
      : 'Field can only contain letters'
}

export const isGa = ({ input, error } = {}) => {
  if (!input) return true
  return (input && /^UA-[0-9]+-[0-9]+$/.test(input)) || !input
    ? true
    : error
      ? error
      : 'Field must be a valid Google Analytics tracking id'
}

export const isNotIn = (notInArray, error, caseSensitive = false) => {
  return function isNotIn({ input } = {}) {
    const checkArray = unref(notInArray)
    return !checkArray
      .map((item) => (caseSensitive ? item : item.toLowerCase()))
      .includes(caseSensitive ? input : input.toLowerCase())
      ? true
      : error
        ? error
        : `Cannot use ${input}`
  }
}

export const isZipFile = ({ input, error } = {}) => {
  if (input && isFile(input.file)) {
    const ext = input.file.name.split('.')

    if (ext[ext.length - 1] === 'zip') {
      return true
    }
  }

  return error ? error : 'File must be a ZIP'
}

export const image = ({ input, error } = {}) => {
  if (!input?.file) return true

  if (isFile(input.file)) {
    const ext = input.file.name.split('.')

    if (['jpg', 'jpeg', 'png'].includes(ext[ext.length - 1].toLowerCase())) {
      return true
    }
  }

  return error ? error : 'File must be a valid jpeg or png image'
}

export const dimensions = ({ minWidth, minHeight, error } = {}) => {
  return function dimensions({ input } = {}) {
    try {
      const errorMessage = []

      if (input && image({ input }) === true) {
        if (minWidth && input.width && input.width < minWidth) {
          errorMessage.push(`Image width must be at least ${minWidth}px`)
        }
        if (minHeight && input.height && input.height < minHeight) {
          errorMessage.push(
            `${
              errorMessage.length ? 'and' : 'Image'
            } height must be at least ${minHeight}px`
          )
        }
      }

      if (!errorMessage.length) {
        return true
      } else {
        return error ? error : errorMessage.join(' ')
      }
    } catch (e) {
      console.error(e)
    }
  }
}

export const minImageSize = (size, error) => {
  return function minImageSize({ input }) {
    if (
      input &&
      image({ input }) === true &&
      parseInt(input.file.size) > parseInt(size)
    ) {
      return true
    }

    return error
      ? error
      : `Filesize must be greater than ${humanFilesize(size)}`
  }
}

export const maxFileSize = (size, error) => {
  return function maxFileSize({ input }) {
    if (!input?.file || parseInt(input.file.size) < parseInt(size)) {
      return true
    }

    return error ? error : `Filesize must not exceed ${humanFilesize(size)}`
  }
}

export const youtubeLink = ({ input, error } = {}) => {
  if (!input) return true
  // eslint-disable-next-line no-useless-escape
  const regex =
    /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(&(amp;)?[\w?=]*)?/i

  return regex.test(input)
    ? true
    : error
      ? error
      : 'Field must be a valid Youtube URL'
}

export const sketchfabLink = ({ input, error } = {}) => {
  if (!input) return true
  // eslint-disable-next-line no-useless-escape
  const regex =
    /http(?:s?):\/\/(?:www\.)?sketchfab\.com\/(?:3d-)?models\/(?:[a-z0-9-]*)([a-z0-9]{32})/

  return regex.test(input)
    ? true
    : error
      ? error
      : 'Field must be a valid Sketchfab URL'
}

export const regex = (reg, error) => {
  if (!isRegex(reg)) {
    throw new TypeError('Field must be valid regex')
  }
  if (!error) {
    throw new Error('Field failed regex validation')
  }

  return function regex({ input } = {}) {
    if (!input) return true
    return reg.test(input) ? true : error
  }
}

export const isHex = ({ input, error } = {}) => {
  if (!input) return true
  // eslint-disable-next-line no-useless-escape
  const regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/

  return regex.test(input)
    ? true
    : error
      ? error
      : 'Field must be a valid hex color'
}
