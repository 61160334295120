<template>
  <component
    :is="isLink ? 'a' : 'button'"
    :href="isLink ? link : null"
    :target="isLink ? target : null"
    :type="isLink ? null : 'button'"
    class="tw-input--height-large tw-w-full focus:tw-outline-none tw-flex tw-items-center tw-rounded tw-leading-snug tw-relative tw-pl-12 xxs:tw-pl-14 xs:tw-pl-16 tw-pr-8 tw-ml-0 tw-overflow-hidden tw-button-transition tw-bg-input-hover tw-outline-none"
  >
    <div
      class="tw-absolute tw-top-0 tw-left-0 tw-input--width-large tw-h-full tw-flex tw-items-center tw-justify-center"
      :class="background"
    >
      <div v-if="baseIcon" class="tw-p-2 tw-mx-auto">
        <base-icon class="tw-text-xl xs:tw-text-2xl" :icon="icon" />
      </div>
      <font-awesome-icon
        v-else
        :icon="icon"
        class="tw-text-xl xs:tw-text-2xl"
      ></font-awesome-icon>
    </div>
    <span class="xxs:tw-text-md">{{ label }}</span>
  </component>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    link: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: '_self',
    },
    baseIcon: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: [String, Array],
      default: null,
    },
    isLink: {
      type: Boolean,
      default: false,
    },
    background: {
      type: String,
      default: 'tw-bg-primary tw-text-primary-text',
    },
  },
}
</script>
