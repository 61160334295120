import { DASHBOARD_TYPES } from '@config/chartOptions.js'
import { LS_METRICS } from '@config/localstorage.js'
import { useLocalStorage } from '@vueuse/core'
import { useRoute } from '@composables'
import { readonly, ref } from 'vue'

const type = ref(null)
const chartStates = ref(null)

export default function (dashboardType) {
  const { getGameId, getModId } = useRoute()
  const gameId = getGameId().value
  const modId = getModId().value
  const siteKey = 'site-admin'
  const gameKey = `game-${gameId ?? ''}`
  const modKey = `mod-${modId ?? ''}`
  const tabKey = modId ? modKey : gameId ? gameKey : siteKey

  const store = useLocalStorage(LS_METRICS, {})
  if (!store.value[tabKey]) {
    store.value[tabKey] = modId
      ? {
          [DASHBOARD_TYPES.MOD_UGC]: {},
        }
      : gameId
        ? {
            [DASHBOARD_TYPES.UGC]: {},
            [DASHBOARD_TYPES.USERS]: {},
            [DASHBOARD_TYPES.HEALTH]: {},
            [DASHBOARD_TYPES.SESSION]: {},
          }
        : {
            [DASHBOARD_TYPES.UGC]: {},
            [DASHBOARD_TYPES.USERS]: {},
            [DASHBOARD_TYPES.HEALTH]: {},
          }
  }

  if (dashboardType) {
    type.value = dashboardType

    if (!store.value?.[tabKey]?.[type.value]) {
      store.value[tabKey][type.value] = {}
    }

    chartStates.value = store.value?.[tabKey]?.[type.value]
  }

  function setChartStorage(key, value) {
    chartStates.value[key] = value
  }

  return {
    chartStates: readonly(chartStates),
    setChartStorage,
  }
}
