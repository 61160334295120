<template>
  <div
    ref="threadsDiv"
    class="tw-flex tw-flex-1 tw-flex-col tw-util-scrollbar tw-overflow-y-scroll dark:tw-shadow-inset tw-py-3 tw-px-6"
  >
    <div class="tw-justify-end tw-h-auto tw-flex tw-flex-col">
      <div v-for="(message, index) in threadMessages" :key="index">
        <div
          v-if="leftMessage(message.message)"
          class="tw-flex tw-w-full tw-justify-center"
        >
          <span class="tw-text-xs tw-py-1 tw-opacity-50">
            {{ message.user.username }} has left.
          </span>
        </div>

        <div
          v-else-if="isRecipient(message.user.username)"
          class="tw-flex tw-w-full tw-items-start tw-space-x-2"
        >
          <avatar-icon :user="message.user" :title="message.user.username" />
          <!-- danger -->
          <span
            class="tw-p-2 tw-max-w-3/4 tw-rounded tw-leading-snug tw-border tw-border-theme-2 tw-border-opacity-20 tw-break-words tw-whitespace-pre-wrap tw-content dark:tw-bg-rich-black tw-bg-white dark:tw-text-white tw-text-black"
            v-html="getMessageText(message.message)"
          />
        </div>
        <div v-else class="tw-flex tw-w-full tw-justify-end">
          <!-- danger -->
          <span
            class="tw-p-2 tw-max-w-3/4 tw-rounded tw-leading-snug tw-bg-primary tw-text-primary-text tw-border tw-border-theme-2 tw-break-words tw-whitespace-pre-wrap tw-content tw-sender-content tw-view-text"
            v-html="getMessageText(message.message)"
          />
        </div>
        <div
          v-if="
            requireTimestamp(
              message.date_published,
              threadMessages[index - 1] &&
                threadMessages[index - 1].date_published
            )
          "
          class="tw-justify-center tw-flex tw-w-full"
        >
          <span class="tw-text-xs tw-py-1 tw-opacity-50">
            {{ timeSince(message.date_published) }} ago
          </span>
        </div>
        <div v-else class="tw-h-2">&nbsp;</div>
      </div>

      <span
        v-if="!threadMessages.length"
        class="tw-w-full tw-text-xs tw-text-center tw-opacity-60"
      >
        You are at the start of your conversation
      </span>
    </div>
  </div>
</template>

<script>
import { nextTick, onMounted, ref, toRefs, watch } from 'vue'
import AvatarIcon from '@components/Users/AvatarIcon.vue'
import formatContent from '@helpers/formatContent.js'
import { timeSince } from '@helpers/utils.js'
import { MESSAGE_LEFT_TEXT } from '@config'
import markdownIt from 'markdown-it'
import { authStore } from '@stores'
import DOMPurify from 'dompurify'

const markdown = markdownIt({ html: true, breaks: true }).disable('image')

export default {
  components: {
    AvatarIcon,
  },
  props: {
    threadMessages: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { threadMessages } = toRefs(props)
    const threadsDiv = ref(null)

    const { user } = authStore()

    watch(threadMessages, () => {
      nextTick(() => {
        threadsDiv.value.scrollTop = threadsDiv.value.scrollHeight
      })
    })

    onMounted(() => {
      threadsDiv.value.scrollTop = threadsDiv.value.scrollHeight
    })

    function getMessageText(message) {
      let formattedMessage = formatContent(message)
      formattedMessage = markdown.render(formattedMessage)
      formattedMessage = formattedMessage.replace(/\n/g, '')
      return DOMPurify.sanitize(formattedMessage)
    }

    function isRecipient(username) {
      return (
        user.value &&
        user.value.info &&
        user.value.info.display_name !== username
      )
    }

    function requireTimestamp(datePublished, lastMessageDatePublished) {
      return timeSince(lastMessageDatePublished) !== timeSince(datePublished)
    }

    function leftMessage(message) {
      return message === MESSAGE_LEFT_TEXT
    }

    return {
      requireTimestamp,
      getMessageText,
      formatContent,
      leftMessage,
      isRecipient,
      threadsDiv,
      timeSince,
    }
  },
}
</script>
