<template>
  <div
    v-if="isLoggedIn"
    :class="
      sidebar
        ? 'tw-hidden md:tw-flex tw-flex-col tw-items-center tw-space-y-1'
        : 'tw-flex tw-items-center tw-space-x-6'
    "
  >
    <div v-if="isMonRegistered" :class="wrapStyles">
      <tooltip-hover
        text="Token balance"
        hide-on-sm
        position="right"
        background="primary-hover"
        outside-main
      >
        <base-button
          :primary="sidebar"
          :text-link-hover="!sidebar"
          icon="wallet"
          icon-size="2xl"
          @click="showWalletsModal"
        />
      </tooltip-hover>
    </div>

    <div :class="wrapStyles">
      <tooltip-hover
        text="Notifications"
        hide-on-sm
        position="right"
        background="primary-hover"
        outside-main
      >
        <base-button
          :primary="sidebar"
          :text-link-hover="!sidebar"
          icon="bell"
          icon-size="2xl"
          :sr-only="
            unreadNotificationCount
              ? 'You have unread notifications'
              : 'You have no new notifications'
          "
          @click="showNotificationDropdown"
        />
      </tooltip-hover>

      <div v-if="unreadNotificationCount" :class="notificationStyles">
        <span>
          {{ unreadNotificationCountText }}
        </span>
      </div>
    </div>

    <div :class="wrapStyles">
      <tooltip-hover
        text="Chat"
        hide-on-sm
        position="right"
        background="primary-hover"
        outside-main
      >
        <base-button
          :primary="sidebar"
          :text-link-hover="!sidebar"
          icon="comment"
          icon-size="2xl"
          :sr-only="
            messageCount?.length
              ? 'You have unread messages'
              : 'You have no new messages'
          "
          @click="showMessagesDropdown"
        />
      </tooltip-hover>

      <div v-if="messageCount?.length" :class="notificationStyles">
        <span>{{ messageCount.length }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipHover from '@components/Message/TooltipHover.vue'
import { useMessagePoll, useNavMenus } from '@composables'
import { setGlobalModal } from '@composables/useModal.js'
import { MODAL_WALLETS } from '@config/globalModals.js'
import { notificationStore, authStore } from '@stores'
import { computed } from 'vue'

export default {
  components: {
    TooltipHover,
  },
  props: {
    sidebar: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { unreadCount: unreadNotificationCount } = notificationStore()
    const { isLoggedIn, isMonRegistered } = authStore()

    const { showNotificationDropdown, showMessagesDropdown } = useNavMenus()

    const { unreadThreadIds: messageCount } = useMessagePoll()

    const wrapStyles = 'tw-flex tw-relative'
    const notificationStyles =
      'tw-absolute tw-top-px tw-right-px xs:tw-top-0.5 xs:tw-right-0.5 tw-rounded-full tw-h-4 tw-w-4 tw-text-xxs tw-flex tw-justify-center tw-items-center tw-pointer-events-none tw-bg-danger tw-text-danger-text tw-shadow-md'

    const unreadNotificationCountText = computed(() =>
      unreadNotificationCount.value < 100
        ? unreadNotificationCount.value
        : '99+'
    )

    function showWalletsModal() {
      setGlobalModal({ modal: MODAL_WALLETS })
    }

    return {
      unreadNotificationCountText,
      showNotificationDropdown,
      unreadNotificationCount,
      showMessagesDropdown,
      notificationStyles,
      showWalletsModal,
      isMonRegistered,
      messageCount,
      wrapStyles,
      isLoggedIn,
    }
  },
}
</script>
