import { addToast, defaultErrorToast } from '@composables/useToast.js'
import { isFunction, apiErrorsToArray } from '@helpers/utils.js'
import { ACCOUNT_DELETED_ROUTE } from '@config/routeNames.js'
import { useRoute, useSuggestions } from '@composables'
import errorMessages from '@errors/ErrorMessages.js'
import { DEFAULT_ERROR_MESSAGE } from '@config'

const USER_DELETED_REF = 11006
const HTTP_TOO_MANY_REQUESTS = 429

export default function () {
  const { routeError, push, routeParams } = useRoute()
  const { handleSuggestion } = useSuggestions()

  function handleError({
    error,
    errorState,
    errorCodeState = {},
    errorRefState = {},
    routeHandlerOrErrorMessage = null,
  }) {
    let errorText
    if (error.errors) {
      errorState.value = error.errors
      errorText = apiErrorsToArray(error.errors)
    } else if (error.message) {
      errorState.value = errorMessages.getErrorMessage(error.message)
      errorText = errorState.value
    } else {
      errorState.value = DEFAULT_ERROR_MESSAGE
      errorText = DEFAULT_ERROR_MESSAGE
    }

    errorCodeState.value = error.code
    errorRefState.value = error.errorRef

    if (error.errorRef === USER_DELETED_REF) {
      push({ name: ACCOUNT_DELETED_ROUTE })
    } else if (routeHandlerOrErrorMessage === true) {
      if (error.status === HTTP_TOO_MANY_REQUESTS) {
        handleSuggestion(error)
      } else {
        const config = _getErrorConfig(error)
        if (config) _handle(config, error)
      }
    } else if (routeHandlerOrErrorMessage) {
      addToast({
        title: routeHandlerOrErrorMessage,
        isError: true,
        text: apiErrorsToArray(errorState.value).join(' '),
        errorRef: errorRefState.value || '0',
      })
    }

    const errorLog = `code: ${error.code} | errorRef: ${error.errorRef} | errorMessage: ${errorText} `
    console.error(errorLog)
  }

  function _getErrorConfig(error) {
    if (!routeError.value || !error) return null

    return routeError.value.find(
      (c) =>
        _matchMethod(c, error) &&
        _matchStatus(c, error) &&
        _matchErrorRef(c, error)
    )
  }

  function _handle(config, error) {
    if (!config) return

    if (config.toast) {
      if (isFunction(config.toast)) {
        addToast({ isError: true, ...config.toast(error) })
      } else {
        defaultErrorToast()
      }
    }

    if (config.redirect) {
      push({ name: config.redirect })
    } else if (isFunction(config.action)) {
      config.action(routeParams.value, error)
    }
  }

  function _matchStatus(config, error) {
    if (config.status === '*') return true

    return config.status === error.code
  }

  function _matchMethod(config, error) {
    if (config.method === '*') return true
    return config.method === error.method
  }

  function _matchErrorRef(config, error) {
    if (config.errorRef === '*') return true
    return config.errorRef === error.errorRef
  }

  return {
    handleError,
  }
}
