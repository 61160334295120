<template>
  <div
    class="tw-flex tw-items-center tw-justify-center tw-px-3 tw-bg-theme-3 tw-text-theme tw-global--border-radius tw-h-8 tw-whitespace-nowrap tw-ml-2 sm:tw-ml-4"
  >
    <div
      class="tw-flex tw-items-center tw-justify-center tw-space-x-2 tw-text-sm"
    >
      <template v-if="label">
        <font-awesome-icon icon="lock" class="tw-text-warning" />
        <span class="tw-hidden sm:tw-flex">{{ label }}</span>
      </template>

      <base-button v-else text-link-hover icon="angle-up">
        back to top
      </base-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
  },
}
</script>
