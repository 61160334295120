<template>
  <div class="tw-whitespace-normal">
    <div
      class="tw-flex tw-items-center tw-space-x-3 tw-mb-3"
      :class="{ 'tw-justify-center': perk }"
    >
      <div
        v-if="step"
        class="tw-w-10 tw-h-10 tw-rounded-full tw-border-3 tw-border-white tw-flex tw-justify-center tw-items-center"
      >
        <span class="tw-font-bold tw-text-lg">{{ step }}</span>
      </div>
      <p
        class="tw-font-bold"
        :class="perk ? 'tw-text-h4 tw-text-center' : 'tw-text-lg'"
      >
        <slot name="title" />
      </p>
    </div>
    <p
      class="tw-opacity-70"
      :class="{
        'tw-text-center md:tw-text-sm lg:tw-text-base tw-w-4/5 md:tw-w-full lg:tw-w-4/5 tw-mx-auto':
          perk,
      }"
    >
      <slot name="content" />
    </p>
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: String,
      default: '',
    },
    perk: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
