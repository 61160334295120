<template>
  <inline-message info :text="text" :has-close="false"></inline-message>
</template>

<script>
import InlineMessage from '@components/Message/InlineMessage.vue'
export default {
  components: {
    InlineMessage,
  },
  props: {
    text: {
      type: String,
      default: 'No data available',
    },
  },
}
</script>
