import { GAME_PLATFORMS } from '@config/options.js'
import { authStore } from '@stores'

/**
 * This function converts a cogs game into a public game
 */
function convertToPublicGame(cogsGame, existingTagOptions, existingStats) {
  const copy = [
    'id',
    'status',
    'date_added',
    'date_updated',
    'date_live',
    'presentation_option',
    'dependency_option',
    'community_options',
    'monetization_options',
    'submission_option',
    'curation_option',
    'api_access_options',
    'maturity_options',
    'ugc_name',
    'icon',
    'logo',
    'header',
    'name',
    'name_id',
    'summary',
    'instructions',
    'profile_url',
    'other_urls',
    'tag_options',
    'theme',
  ]

  const game = {}

  copy.forEach((key) => (game[key] = cogsGame[key]))

  game.submitted_by = cogsGame.user
  game.instructions_url = cogsGame.support_url
  game.monetization_team = { team_id: cogsGame.monetization_team.team_id }
  game.platforms = []

  if (cogsGame.platforms.platforms_filter) {
    GAME_PLATFORMS.filter((p) => cogsGame.platforms[p].is_supported).forEach(
      (p) => {
        game.platforms.push({
          platform: p,
          label: cogsGame.platforms[p].label,
          moderated: cogsGame.platforms[p].is_moderated,
          locked: cogsGame.platforms[p].is_locked,
        })
      }
    )
  }

  // cogs game tag_options don't include counts so we copy
  // them from the existing public game if available
  if (existingTagOptions) {
    game.tag_options = game.tag_options.map((o) => {
      const match = existingTagOptions.find((other) => o.name === other.name)
      return {
        ...o,
        tag_count_map: match?.tag_count_map || null,
      }
    })
  }

  const lang = authStore().getUserLanguage()
  if (lang !== 'en') {
    game.tag_options = game.tag_options.map((o) => ({
      ...o,
      name_localized: o.name_localization?.[lang] || o.name,
      tags_localized: Object.fromEntries(
        o.tags.map((t) => [
          t,
          o.tags_localization?.find((localization) => localization.tag === t)
            .translations[lang] || t,
        ])
      ),
    }))
  }

  // cogs game doesn't include stats so we copy
  // them from the existing public game if available
  if (existingStats) {
    game.stats = existingStats
  }

  if (cogsGame.monetization_marketplace?.token_name) {
    game.token_name = cogsGame.monetization_marketplace?.token_name
    game.max_stock = cogsGame.monetization_marketplace?.max_stock
  }

  return game
}

export default convertToPublicGame
