import { watchEffect, ref, computed, watch, readonly } from 'vue'
import { GAME_ADD_ROUTE } from '@config/routeNames.js'
import { grey2 } from '@config/colorsTailwindBase.js'
import { isObjEmpty } from '@helpers/utils.js'
import { useRoute } from '@composables'
import { themeStore } from '@stores'
import Color from 'color'
import {
  DEFAULT_SUCCESS,
  DEFAULT_WARNING,
  DEFAULT_PRIMARY,
  DEFAULT_DANGER,
  THEME_DEFAULT,
  DEFAULT_LIGHT,
  DEFAULT_DARK,
  LIGHT_TEXT,
  THEME_HOME,
  HOME_DARK,
  DARK_TEXT,
} from '@config/colorsTheme.js'

const primary = ref(DEFAULT_PRIMARY)
const dark = ref(DEFAULT_DARK)
const light = ref(DEFAULT_LIGHT)
const success = ref(DEFAULT_SUCCESS)
const warning = ref(DEFAULT_WARNING)
const danger = ref(DEFAULT_DANGER)
const text = ref(LIGHT_TEXT)

let tempGameTheme = {}

const BLACK_THRESHOLD = Color('#101010').luminosity()
const LIGHTEN_AMOUNT = 8

const { getTheme } = themeStore()
export default function () {
  const { getGameId, routeName, routeMeta } = useRoute()
  const gameNameId = getGameId()

  const routeTheme = computed(() => routeMeta.value && routeMeta.value.theme)

  const isTooDark = computed(
    () => Color(dark.value).luminosity() < BLACK_THRESHOLD
  )

  const darkComp = computed(() => {
    if (!gameNameId.value && routeName.value !== GAME_ADD_ROUTE) {
      return DEFAULT_DARK
    }

    if (isTooDark.value) {
      return dark.value
    }

    return Color(dark.value).darken(0.4).hex()
  })

  const dark3Comp = computed(() => {
    if (!gameNameId.value && routeName.value !== GAME_ADD_ROUTE) {
      return grey2
    }

    if (isTooDark.value) {
      const [h, s, l] = Color(dark.value).hsl().color
      return Color.hsl(h, s, l + LIGHTEN_AMOUNT).hex()
    }

    return dark.value
  })

  // --light var is always white and cannot be changed (requirment)
  // this is to ensure the theming bg is white but panel / input
  // colors are still themable
  const themeVars = computed(() => {
    return {
      '--dark': darkComp.value,
      '--dark-1': Color(isTooDark.value ? dark3Comp.value : dark.value)
        .lighten(0.4)
        .hex(),
      '--dark-2': Color(isTooDark.value ? dark3Comp.value : dark.value)
        .lighten(0.8)
        .hex(),
      '--dark-3': dark3Comp.value,
      '--dark-text': getTextColor(dark.value),
      '--light': '#ffffff',
      '--light-1': light.value,
      '--light-2': Color(light.value).darken(0.05).hex(),
      '--light-3': Color(light.value).darken(0.08).hex(),
      '--light-text': getTextColor(light.value),
      '--primary': primary.value,
      '--primary-hover': Color(primary.value).saturate(0.5).lighten(0.2).hex(),
      '--primary-text': getTextColor(primary.value),
      '--success': success.value,
      '--success-hover': Color(success.value).saturate(0.5).lighten(0.2).hex(),
      '--success-text': getTextColor(success.value),
      '--warning': warning.value,
      '--warning-hover': Color(warning.value).saturate(0.5).lighten(0.2).hex(),
      '--warning-text': getTextColor(warning.value),
      '--danger': danger.value,
      '--danger-hover': Color(danger.value).saturate(0.5).lighten(0.2).hex(),
      '--danger-text': getTextColor(danger.value),
    }
  })

  function watchTheme() {
    watchEffect(() => {
      const theme = getTheme(gameNameId.value)
      _setThemable(theme.value)
    })

    watch(
      themeVars,
      () => {
        _pushThemeVars()
      },
      { immediate: true }
    )
  }

  // theming options now set at route level
  // default use game theming
  function _setThemable(theme) {
    if (routeTheme.value === THEME_DEFAULT) {
      // reset theme if in none themable area
      updateTheme()
    } else if (routeTheme.value === THEME_HOME) {
      _setHomeTheme()
    } else {
      // use game colors
      if (theme && !isObjEmpty(theme)) {
        updateTheme(theme)
      }
    }
  }

  // functions below used to theme modals using default theme
  // this can be seen then opening the login modal from
  // a themed game, the game theme does not carry into modal
  function setDefaultThemeVars() {
    tempGameTheme = getTheme(gameNameId.value).value
    updateTheme()
  }

  function resetGameThemeVars() {
    if (tempGameTheme && !isObjEmpty(tempGameTheme)) {
      updateTheme(tempGameTheme)
      tempGameTheme = {}
    }
  }

  function updateTheme({
    danger = DEFAULT_DANGER,
    dark = DEFAULT_DARK,
    primary = DEFAULT_PRIMARY,
    success = DEFAULT_SUCCESS,
    warning = DEFAULT_WARNING,
  } = {}) {
    updatePrimaryColor(primary)
    updateDarkColor(dark)
    updateSuccessColor(success)
    updateWarningColor(warning)
    updateDangerColor(danger)
  }

  function _setHomeTheme({ dark = HOME_DARK } = {}) {
    updateTheme({ dark })
  }

  function updatePrimaryColor(color) {
    primary.value = color
  }

  function updateDarkColor(color) {
    dark.value = color
  }

  function updateSuccessColor(color) {
    success.value = color
  }

  function updateWarningColor(color) {
    warning.value = color
  }

  function updateDangerColor(color) {
    danger.value = color
  }

  function _pushThemeVars() {
    for (let [key, value] of Object.entries(themeVars.value)) {
      document.documentElement.style.setProperty(key, value)
    }
  }

  return {
    successColor: readonly(success),
    warningColor: readonly(warning),
    primaryColor: readonly(primary),
    dangerColor: readonly(danger),
    lightColor: readonly(light),
    darkColor: readonly(dark),
    textColor: readonly(text),
    setDefaultThemeVars,
    resetGameThemeVars,
    updateSuccessColor,
    updateWarningColor,
    updatePrimaryColor,
    updateDangerColor,
    updateDarkColor,
    updateTheme,
    watchTheme,
    themeVars,
  }
}

export function getTextColor(bgColor) {
  try {
    return Color(bgColor).isDark() ? DARK_TEXT : LIGHT_TEXT
  } catch (ex) {
    return DARK_TEXT
  }
}

export function getHoverColor(color) {
  try {
    return Color(color).saturate(0.8).lighten(0.2).hex()
  } catch (ex) {
    return color
  }
}
