<template>
  <ul class="tw-w-full tw-mb-6 tw-font-bold tw-space-y-3">
    <template v-for="(item, index) in visibleLinks" :key="index">
      <li v-if="shouldShow(index)">
        <nav-link
          :item="item"
          :top="index === 0"
          :active="isActive(item.link)"
        />
      </li>
    </template>
  </ul>
</template>

<script>
import { useRoute, useNavigation } from '@composables'
import NavLink from '@components/Navbar/NavLink.vue'
import { toRefs, computed } from 'vue'

export default {
  components: {
    NavLink,
  },
  props: {
    links: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const { links } = toRefs(props)
    const { routeName } = useRoute()
    const { navFilter } = useNavigation()

    const visibleLinks = computed(() => links.value.filter(navFilter))

    function isActive(link) {
      return link === routeName.value
    }

    function shouldShow(index) {
      if (visibleLinks.value[index].label) {
        // Only render label if it has at least one link below it
        return (
          index + 1 < visibleLinks.value.length &&
          visibleLinks.value[index + 1].link
        )
      }
      return true
    }

    return {
      visibleLinks,
      shouldShow,
      isActive,
    }
  },
}
</script>
