import { useRoute } from '@composables'
import { computed, ref } from 'vue'

const state = ref({})

function _replaceState(option, noHash = false) {
  history.replaceState(
    history.state,
    null,
    `${noHash ? '/' : '#'}${option.toLowerCase().replace(/ /g, '-')}`
  )
}

export default function (
  key,
  options,
  onTabUpdated,
  initialTab,
  noHash = false
) {
  const tab = computed(() => state.value[key])

  // can acquire current tab with key
  if (!options && !onTabUpdated) {
    return {
      updateTab,
      tab,
    }
  }

  let hashTabMatch = options.find(
    (o) =>
      o.value?.toLowerCase().replace(/ /g, '-') ===
      useRoute().routeHash.value.replace('#', '')
  )

  const matchValue = hashTabMatch?.value || hashTabMatch?.text

  state.value[key] =
    matchValue ||
    (!noHash &&
      tab.value &&
      options.find((o) => o.value === tab.value)?.value) ||
    initialTab ||
    options?.[0]?.value

  if (tab.value) {
    _replaceState(tab.value, noHash)
  }

  function updateTab(newTab) {
    if (tab.value !== newTab) {
      state.value[key] = newTab
      onTabUpdated && onTabUpdated(newTab)
      _replaceState(newTab, noHash)
    }
  }

  return {
    updateTab,
    tab,
  }
}
