<template>
  <div
    id="conversationDropDownMenu"
    role="menu"
    aria-orientation="vertical"
    aria-labelledby="options-menu"
    class="tw-fixed tw-z-1 tw-transition-colors tw-duration-200 tw-overflow-hidden tw-flex tw-flex-col tw-right-3 tw-w-28 tw-util-shadow-dropdown tw-global--border-radius tw-text-sm tw-border-opacity-10 tw-border-white"
    :class="{
      'tw-max-h-0 tw-border-none': !show,
      'tw-border': show,
      'tw-top-auto tw-absolute': groupMember,
      'tw-top-14 xs:tw-top-12': !groupMember,
    }"
  >
    <conversation-dropdown-item
      v-if="groupChat"
      id="members"
      label="Chat members"
      @click="showChatMembers"
    />
    <conversation-dropdown-item
      v-if="!groupMember"
      id="leave"
      :loading="loading"
      :status="status"
      label="Leave chat"
      @click="leaveConversation"
    />
    <conversation-dropdown-item
      v-if="groupMember || !groupChat"
      id="profile"
      label="View profile"
      :to="
        otherUserNameId
          ? { name: USER_ROUTE, params: { user: otherUserNameId } }
          : ''
      "
    />
    <conversation-dropdown-item
      v-if="!groupChat && otherUserId !== userId"
      id="report"
      label="Report user"
      @click="$emit('trigger:report')"
    />
  </div>
</template>

<script>
import ConversationDropdownItem from '@components/Conversation/OptionsDropdown/ConversationDropdownItem.vue'
import { USER_ROUTE } from '@config/routeNames.js'
import { messageStore, authStore } from '@stores'
import { leaveMessageThread } from '@services'
import { nextTick } from 'vue'
import {
  useMessagePoll,
  useMessenger,
  useStatus,
  useThread,
  useAsync,
} from '@composables'

export default {
  components: {
    ConversationDropdownItem,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    otherUserNameId: {
      type: String,
      default: '',
    },
    otherUserId: {
      type: Number,
      default: null,
    },
    groupChat: {
      type: Boolean,
      default: false,
    },
    groupMember: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['blur', 'item:click', 'trigger:report'],
  setup() {
    const { removeThreadFromInbox } = messageStore()
    const { changeComponent, showHide, CONVERSATION_COMPONENTS } =
      useMessenger()
    const { removeThreadsFromWait } = useMessagePoll()
    const { getUserId } = authStore()

    const userId = getUserId()

    const { run, loading, error } = useAsync(
      (threadId) => leaveMessageThread(threadId),
      'Failed to leave message thread'
    )

    const { status } = useStatus({
      loading,
      error,
    })

    const { threadId } = useThread()

    async function leaveConversation() {
      if (threadId.value) {
        await run(threadId.value)
        if (!error.value) {
          removeThreadFromInbox(threadId.value)
          // Wait until message store has settled
          nextTick(() => removeThreadsFromWait([threadId.value]))
          showHide(false)
        }
      }
    }

    function showChatMembers() {
      changeComponent(CONVERSATION_COMPONENTS.CONVERSATION_MEMBERS)
    }

    return {
      leaveConversation,
      showChatMembers,
      USER_ROUTE,
      loading,
      status,
      userId,
    }
  },
}
</script>
