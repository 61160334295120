module.exports = {
  BREAKPOINTS: {
    xxs: '376px',
    xs: '512px',
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1200px',
    '2xl': '1518px',
    '3xl': '1878px',
    '4xl': '2238px',
    '5xl': '2598px',
    '6xl': '2958px',
  },
}
