import modSerializer from '@serializers/modSerializer.js'
import { store, myTeamsStore, modStore } from '@stores'
import { KEYS_COGS_MOD } from '@config/storeKeys.js'
import { clone } from '@helpers/utils.js'
import { NEW_RESOURCE_ID } from '@config'

const { updateStore, getStore, createStore } = store()

export default function ({ clear = false } = {}) {
  const cogsMod = getStore(KEYS_COGS_MOD)
  if (clear) {
    clearCogsMod()
  }

  const { addMod } = modStore()
  const { addTeamLeader, updateModTeam } = myTeamsStore()

  function addNewMod(gameNameId) {
    createStore(
      KEYS_COGS_MOD,
      modSerializer.getNew({
        id: NEW_RESOURCE_ID,
        game_name_id: gameNameId,
      })
    )
  }

  function addCogMod(gameNameId, mod, save = true, transform = true) {
    const _mod = transform ? modSerializer.transform(mod) : mod
    updateStore(KEYS_COGS_MOD, _mod)
    if (save) {
      saveMod(gameNameId)
    }
  }

  function saveMod(
    gameNameId,
    { newMod = null, updateTeam = false, updateTeamLeader = false } = {}
  ) {
    if (newMod) {
      addCogMod(gameNameId, newMod, false)
    }

    if (updateTeam || updateTeamLeader) {
      updateModTeam(cogsMod.value, gameNameId)
    }

    if (updateTeamLeader && cogsMod.value?.name_id) {
      addTeamLeader('mod', cogsMod.value.id, cogsMod.value.name_id)
    }

    addMod(gameNameId, cogsMod.value)
  }

  function updateModField(field, value) {
    if (!cogsMod.value) return

    const updatedMod = { ...cogsMod.value, [field]: value }
    updateStore(KEYS_COGS_MOD, updatedMod)
  }

  function updateModObjField(key, input, value) {
    if (!cogsMod.value) return

    const updatedMod = clone(cogsMod.value)

    updatedMod[key][input] = value
    updateStore(KEYS_COGS_MOD, updatedMod)
  }

  function clearCogsMod() {
    createStore(KEYS_COGS_MOD, null)
  }

  return {
    updateModObjField,
    updateModField,
    clearCogsMod,
    addNewMod,
    addCogMod,
    saveMod,
    cogsMod,
  }
}
