<template>
  <div
    role="menu"
    aria-orientation="vertical"
    aria-labelledby="options-menu"
    class="tw-fixed tw-top-16 tw-left-0 md:tw-top-0 md:tw-left-18 tw-w-full xxs:tw-w-72 tw-bottom-0 tw-flex tw-flex-col tw-transition-transform tw-duration-200 tw-z-22 tw-bg-dark tw-border-r tw-border-dark-3"
    :class="[
      showMenu ? 'tw--translate-x-0' : 'tw--translate-x-[calc(100%+4.5rem)]',
    ]"
  >
    <component :is="menu" v-if="showMenu" class="tw-text-theme" />
  </div>
</template>

<script>
import ModioInfo from '@views/layouts/ModioInfo.vue'
import { useNavMenus } from '@composables'
export default {
  components: {
    ModioInfo,
  },
  setup() {
    const { menu, showMenu } = useNavMenus()

    return {
      showMenu,
      menu,
    }
  },
}
</script>
