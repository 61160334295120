import { useResourceValue } from '@composables'
import { userStatsStore } from '@stores'
import { computed } from 'vue'

export default function (nameId, gameRoute = false) {
  const { getUserStats } = userStatsStore()
  const userStats = getUserStats(nameId, gameRoute)

  const modTotal = computed(() => userStats.value?.mod_count || 0)
  const guideTotal = computed(() => userStats.value?.guide_count || 0)
  const subsTotal = computed(() => userStats.value?.follower_count || 0)

  const userTotals = computed(() => {
    return [
      {
        stat: modTotal.value,
        text: `${useResourceValue().ugcName(null).value} created`,
        icon: 'cubes',
      },
      {
        stat: guideTotal.value,
        text: 'Guides created',
        icon: 'book-open',
      },
      {
        stat: subsTotal.value,
        text: 'Followers',
        icon: 'users',
      },
    ]
  })

  return {
    userTotals,
    guideTotal,
    subsTotal,
    modTotal,
  }
}
