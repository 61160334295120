<template>
  <div>
    <div
      id="testimonials"
      class="tw-homepage-width tw-mx-auto tw-px-8 tw-mb-10"
    >
      <p class="tw-home-h4 tw-mb-5 tw-text-center">
        TRUSTED AND CHOSEN BY INDUSTRY LEADERS
      </p>
      <div
        class="tw-relative tw-flex tw-flex-col md:tw-flex-row tw-overflow-x-visible tw-gap-5"
      >
        <div :class="testimonialClass">
          <div class="tw-flex tw-justify-between">
            <span class="tw-flex tw-items-center tw-font-bold tw-text-lg">
              Rafał Polito
            </span>
            <div
              class="tw-flex tw-bg-dark-grey tw-global--border-radius tw-p-3 tw-items-center"
            >
              <img :src="techland" alt="Techland" class="tw-h-5" />
            </div>
          </div>
          <p class="tw-opacity-70">
            “User-generated content is a wonderful way to tap into the creative
            potential of the community and deepen the relationship with our
            fans. Thanks to mod.io, we were able to implement an in-game
            solution for Dying Light 2 Stay Human. The mod.io team have been
            exceptional partners.”
          </p>
        </div>
        <div :class="testimonialClass">
          <div class="tw-flex tw-justify-between">
            <span class="tw-flex tw-items-center tw-font-bold tw-text-lg">
              Tim Willits
            </span>
            <div
              class="tw-flex tw-bg-dark-grey tw-global--border-radius tw-p-3 tw-items-center"
            >
              <base-icon icon="saber_logo" class="tw-h-5" />
            </div>
          </div>
          <p class="tw-opacity-70">
            “SnowRunner's modding community has greatly expanded the game's
            experience for dedicated players. The constant influx of fresh
            content is certainly a key driver of the title's long-term success.
            We could not have achieved this without mod.io - their tech and
            support are unmatched.”
          </p>
        </div>
        <div :class="testimonialClass">
          <div class="tw-flex tw-justify-between">
            <span class="tw-flex tw-items-center tw-font-bold tw-text-lg">
              Pierre Tartaix
            </span>
            <div
              class="tw-flex tw-bg-dark-grey tw-global--border-radius tw-p-3 tw-items-center"
            >
              <img :src="bandainamco" alt="Bandai Namco" class="tw-h-5" />
            </div>
          </div>
          <p class="tw-opacity-70">
            “Bandai Namco values and celebrates the creative spirit of all our
            Park Beyond players. Our partnership with mod.io team is testament
            to this; we want to show our commitment to innovation and community
            on both PC and console by enabling user-generated content for all.”
          </p>
        </div>
      </div>

      <div class="tw-my-20">
        <div
          class="tw-flex tw-flex-col xs:tw-flex-row tw-gap-x-5 tw-gap-y-3 tw-justify-center"
        >
          <button
            class="tw-home-btn xs:tw-h-12 tw-w-full xs:tw-w-56 tw-text-h6 tw-home-btn--secondary"
            @click="openStartModal"
          >
            GET STARTED
          </button>
          <base-link
            :link-primary="false"
            class="tw-home-btn xs:tw-h-12 tw-w-full xs:tw-w-52 tw-text-h6 tw-home-btn--primary"
            :to="{ name: CONTACT_ROUTE }"
          >
            CONTACT US
          </base-link>
        </div>
      </div>
    </div>

    <div class="tw-bg-grey2">
      <div
        class="tw-flex tw-flex-wrap tw-justify-around tw-homepage-width tw-mx-auto tw-py-10"
      >
        <div class="tw-flex tw-flex-col tw-items-center tw-mx-2">
          <p class="tw-font-bold tw-text-5xl">1B+</p>
          <p class="tw-opacity-70">DOWNLOADS</p>
        </div>
        <div class="tw-flex tw-flex-col tw-items-center tw-mx-2">
          <p class="tw-font-bold tw-text-5xl">25M+</p>
          <p class="tw-opacity-70">USERS</p>
        </div>
        <div class="sm:tw-hidden tw-w-full tw-h-0" />
        <div class="tw-flex tw-flex-col tw-items-center tw-mx-2">
          <p class="tw-font-bold tw-text-5xl">4M+</p>
          <p class="tw-opacity-70">UGC</p>
        </div>
        <div class="tw-flex tw-flex-col tw-items-center tw-mx-2">
          <p class="tw-font-bold tw-text-5xl">170+</p>
          <p class="tw-opacity-70">GAMES</p>
        </div>
      </div>
    </div>
    <modio-footer />
  </div>
</template>

<script>
import ModioFooter from '@home_components/Footer/ModioFooter.vue'
import bandainamco from '@assets/1_header/bandailandscape.svg'
import { setGlobalModal } from '@composables/useModal.js'
import { MODAL_START } from '@config/globalModals.js'
import { CONTACT_ROUTE } from '@config/routeNames.js'
import techland from '@assets/1_header/techland.svg'
import waves from '@assets/misc/waves.png'

export default {
  components: {
    ModioFooter,
  },
  setup() {
    const testimonialClass =
      'tw-flex tw-flex-col tw-gap-5 md:tw-w-1/3 tw-rounded-2xl tw-py-5 tw-bg-grey2 tw-px-5 lg:tw-px-10 '
    function openStartModal() {
      setGlobalModal({ modal: MODAL_START })
    }
    return {
      testimonialClass,
      openStartModal,
      CONTACT_ROUTE,
      bandainamco,
      techland,
      waves,
    }
  },
}
</script>
