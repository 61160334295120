import { monetizationStore } from '@stores'
import { clone } from '@helpers/utils.js'
import { ref, computed } from 'vue'
import {
  SECTION_MARKETPLACE,
  MIN_GAME_MOD_CUT,
  MAX_GAME_MOD_CUT,
  SPLIT_MODIO_ID,
  SPLIT_GAME_ID,
  SPLIT_MOD_ID,
  MIN_MODIO_CUT,
  MAX_MODIO_CUT,
} from '@config/monetization.js'

const SPLIT_TYPES = [SECTION_MARKETPLACE]

const SPLIT_DEFAULT = {
  splits: [],
  remaining: 0,
  disabled: false,
  lockedCount: 0,
}

const splitStore = ref({})
const initialSplits = ref({})

SPLIT_TYPES.forEach((item) => {
  splitStore.value[item] = clone(SPLIT_DEFAULT)
})

initialSplits.value = clone(splitStore.value)

export default function (section) {
  const splitsData = computed(() => splitStore.value[section])

  const { monetization } = monetizationStore()
  const lockedItemsCount = computed(
    () => splitStore.value[section].splits.filter((i) => i.locked).length
  )

  const lockedItemTotal = computed(() =>
    splitStore.value[section].splits
      .filter((i) => i.locked)
      .map((i) => i.split)
      .reduce((sum, val) => sum + val, 0)
  )

  const globalSplitData = computed(() => {
    return {
      [`${section}_${SPLIT_MOD_ID}`]:
        monetization.value[`${section}_default_${SPLIT_MOD_ID}`],
      [`${section}_${SPLIT_GAME_ID}`]:
        monetization.value[`${section}_default_${SPLIT_GAME_ID}`],
      [`${section}_${SPLIT_MODIO_ID}`]:
        monetization.value[`${section}_default_${SPLIT_MODIO_ID}`],
    }
  })

  function getSplitData() {
    return computed(() => {
      return {
        [`${section}_${SPLIT_MOD_ID}`]: splitStore.value[section].splits.find(
          (s) => s.field.includes(SPLIT_MOD_ID)
        ).split,
        [`${section}_${SPLIT_GAME_ID}`]: splitStore.value[section].splits.find(
          (s) => s.field.includes(SPLIT_GAME_ID)
        ).split,
        [`${section}_${SPLIT_MODIO_ID}`]: splitStore.value[section].splits.find(
          (s) => s.field.includes(SPLIT_MODIO_ID)
        ).split,
      }
    })
  }

  function setSplitsData(value, setInitial = false) {
    if (setInitial) {
      initialSplits.value[section].splits = _mapUserData(value.splits)
    }
    splitStore.value[section].splits = _mapUserData(value.splits)
  }

  function resetSplits() {
    splitStore.value = clone(initialSplits.value)
  }

  function updatePercentage(field, input) {
    // TODO temp workaround until mod.io can have splits > 0
    splitStore.value[section].splits.find((s) => isModioField(s.field)).split =
      0

    input = Math.max(input, fieldMin(field))
    // after input has been deducted whats left
    splitStore.value[section].remaining = 100 - lockedItemTotal.value - input
    // how many unlocked items not included active slider
    let unlockedItemsCount =
      splitStore.value[section].splits.length - lockedItemsCount.value - 1

    let itemIndex = -1
    // Running total of actual percent distributed
    let total = lockedItemTotal.value

    splitStore.value[section].splits.forEach((s, i) => {
      if (s.field !== field && !s.locked) {
        const nextRemaining = Math.floor(
          splitStore.value[section].remaining / unlockedItemsCount
        )
        s.split = Math.max(nextRemaining, fieldMin(s.field))
        total += s.split
        // Reduce remaining for the next split
        splitStore.value[section].remaining -= s.split
        unlockedItemsCount--
      } else if (s.field === field) {
        itemIndex = i
      }
    })

    if (itemIndex >= 0) {
      splitStore.value[section].splits[itemIndex].split = 100 - total
    }
  }

  function isModioField(field) {
    return field.includes(SPLIT_MODIO_ID)
  }

  function fieldMin(field) {
    return isModioField(field) ? MIN_MODIO_CUT : MIN_GAME_MOD_CUT
  }

  function fieldMax(field) {
    return (
      (isModioField(field) ? MAX_MODIO_CUT : MAX_GAME_MOD_CUT) -
      lockedItemTotal.value
    )
  }

  function toggleLock(split) {
    const toLock = !split.locked
    splitStore.value[section].splits.find(
      (s) => s.field === split.field
    ).locked = toLock

    // if only one item or if all other items locked toggle lock for everything
    if (
      splitStore.value[section].splits.length === 1 ||
      splitStore.value[section].splits.length - 1 === lockedItemsCount.value
    ) {
      splitStore.value[section].splits.forEach((u) => {
        u.locked = toLock
      })
    }
  }

  function setDisabled(value) {
    splitStore.value[section].disabled = value
  }

  function _mapUserData(cutList) {
    // Sort splits so platform is first, mod is last
    const keys = Object.keys(cutList).sort((a, b) => {
      if (a.includes(SPLIT_MODIO_ID) || b.includes(SPLIT_MOD_ID)) return -1
      if (b.includes(SPLIT_MODIO_ID) || a.includes(SPLIT_MOD_ID)) return 1
      return 0
    })
    let list = []

    for (const key of keys) {
      // set test for field
      let fieldText = ''

      switch (key) {
        case `${section}_${SPLIT_MOD_ID}`:
          fieldText = 'Creators'
          break

        case `${section}_${SPLIT_GAME_ID}`:
          fieldText = 'Publishers'
          break

        case `${section}_${SPLIT_MODIO_ID}`:
          fieldText = 'mod.io'
          break

        default:
          continue
      }

      list.push({
        text: fieldText,
        field: key,
        split: cutList[key],
        locked: isModioField(key),
      })
    }

    return list
  }

  return {
    updatePercentage,
    globalSplitData,
    initialSplits,
    setSplitsData,
    isModioField,
    getSplitData,
    setDisabled,
    resetSplits,
    toggleLock,
    splitsData,
    fieldMin,
    fieldMax,
  }
}
