import { countryCurrencies } from '@config/countries.js'
import { conversionRateStore } from '@stores'
import { usePlatforms } from '@composables'
import { computed, ref } from 'vue'

const priceState = ref(0)
const amountState = ref(0)
const indexState = ref(0)

export default function () {
  const { conversionRates } = conversionRateStore()
  const { PORTAL_OPTIONS } = usePlatforms()
  const DEFAULT_PLATFORM_PCT = 30
  const EPIC_PLATFORM_PCT = 12
  const WEB_PLATFORM_PCT = 5
  const WHOLESALE_CHARGE = 0.0052
  const PLATFORM_SHARE = 0.0012
  const CREATOR_SHARE = 0.004

  const COUNTRY_OPTIONS = [
    { value: '', text: 'Select a country', currency: '' },
    ...countryCurrencies,
  ]

  const selectedPackPrice = computed(() => {
    return priceState.value
  })
  const selectedPackAmount = computed(() => {
    return amountState.value
  })
  const selectedPackIndex = computed(() => {
    return indexState.value
  })
  const selectedPlatform = ref(PORTAL_OPTIONS[0].value)
  const selectedCountry = ref(COUNTRY_OPTIONS[0].value)
  const selectedPlatformText = computed(
    () => PORTAL_OPTIONS.find((o) => o.value === selectedPlatform.value).text
  )
  const selectedCountryText = computed(
    () => COUNTRY_OPTIONS.find((o) => o.value === selectedCountry.value).text
  )
  const selectedCountryCurrency = computed(
    () =>
      COUNTRY_OPTIONS.find((o) => o.value === selectedCountry.value).currency
  )
  const conversionRate = computed(
    () =>
      conversionRates.value?.find(
        (o) => o.conversion_code === selectedCountryCurrency.value
      ).conversion_rate
  )
  const usdConversionRate = computed(
    () =>
      conversionRates.value?.find(
        (o) => o.base_code === selectedCountryCurrency.value
      ).conversion_rate
  )
  const creator = computed(() => selectedPackPrice.value * CREATOR_SHARE)
  const modio = computed(() => selectedPackPrice.value * PLATFORM_SHARE)
  const wholesaleCharge = computed(
    () => selectedPackPrice.value * WHOLESALE_CHARGE
  )
  const platformPct = computed(() =>
    selectedPlatform.value === 'epicgames'
      ? EPIC_PLATFORM_PCT
      : DEFAULT_PLATFORM_PCT
  )
  const platformFee = computed(
    () => (platformPct.value / 100) * selectedPackPrice.value
  )
  const processingFee = computed(
    () => (WEB_PLATFORM_PCT / 100) * selectedPackPrice.value + 20
  )
  const suggestedPrice = computed(
    () => selectedPackPrice.value * conversionRate.value
  )
  const amountPaid = computed(
    () => suggestedPrice.value * usdConversionRate.value
  )
  const fee = computed(() =>
    selectedPlatform.value === 'web' ? processingFee.value : platformFee.value
  )
  const publisher = computed(
    () => selectedPackPrice.value - wholesaleCharge.value - fee.value
  )

  function updatePlatform(input) {
    selectedPlatform.value = input

    if (selectedPlatform.value === 'web') {
      selectedCountry.value = ''
    }
  }

  function updateActivePack(pack, index) {
    priceState.value = pack.price
    amountState.value = pack.amount
    indexState.value = index
  }

  async function updateCountry(input) {
    selectedCountry.value = input
  }

  return {
    selectedCountryCurrency,
    selectedPlatformText,
    selectedCountryText,
    selectedPackAmount,
    selectedPackPrice,
    selectedPackIndex,
    updateActivePack,
    selectedPlatform,
    selectedCountry,
    COUNTRY_OPTIONS,
    wholesaleCharge,
    suggestedPrice,
    updatePlatform,
    updateCountry,
    processingFee,
    platformFee,
    platformPct,
    amountPaid,
    publisher,
    creator,
    modio,
  }
}
