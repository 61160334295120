import { SS_TILIA_CALLBACK, SS_TRANSACTION_KEY } from '@config/localstorage.js'
import { useRoute, useStoreWatcher } from '@composables'
import { isArray, isObj } from '@helpers/utils.js'
import { useSessionStorage } from '@vueuse/core'
import { MONETIZATION_SETTINGS } from '@config'
import { computed, ref } from 'vue'
import {
  GAME_MOD_ONBOARDING_COMPLETE,
  TILIA_TYPE_KYC,
  TILIA_TYPE_KYB,
} from '@config/monetization.js'
import {
  isPartnerProgramEnabled,
  isMonetizationEnabled,
  isMarketplaceEnabled,
  isGameCurationPaid,
} from '@helpers/bitHelper.js'
import {
  PROFILE_ACCOUNT_ROUTE,
  GAME_BROWSE_ROUTE,
  MOD_BROWSE_ROUTE,
  MOD_VIEW_ROUTE,
} from '@config/routeNames.js'
import {
  revenueSplitStore,
  cogsGameStore,
  myTeamsStore,
  cogsModStore,
  authStore,
  gameStore,
  modStore,
} from '@stores'

const monetizationState = ref(MONETIZATION_SETTINGS)
const transactionKeyState = useSessionStorage(SS_TRANSACTION_KEY, '{}')
const tiliaCallbackState = useSessionStorage(SS_TILIA_CALLBACK, '{}')

export default function () {
  const { myGames } = myTeamsStore()
  const { getPublicGame } = gameStore()
  const { getModByNameId } = modStore()
  const { cogsGame } = cogsGameStore()
  const { cogsMod } = cogsModStore()
  const { user, isOnboarded, isMonRegistered, isSuperAdmin, hasMemberWallets } =
    authStore()

  const monetization = computed(() => monetizationState.value)
  const tiliaCallback = computed(() => JSON.parse(tiliaCallbackState.value))

  const globalMonetization = computed(
    () => monetization.value?.monetization_enabled
  )
  const globalMarketplace = computed(
    () => monetization.value?.monetization_marketplace
  )
  const transactionKey = computed(() => JSON.parse(transactionKeyState.value))

  const teamData = computed(() => {
    const { getGameId, getModId } = useRoute()
    const gameNameId = getGameId().value
    const modNameId = getModId().value
    const { revenueMembers: gameRevenueTeam, hasData: hasGameRevenueTeam } =
      revenueSplitStore('game', gameNameId)
    const { revenueMembers: modRevenueTeam, hasData: hasModRevenueTeam } =
      revenueSplitStore('mod', modNameId)

    const game = cogsGame.value || getPublicGame(gameNameId).value
    const mod =
      modNameId &&
      (cogsMod.value || getModByNameId(gameNameId, modNameId).value)
    const inGameTeam = myGames.value.some((item) => item.name_id === gameNameId)

    return {
      game: _mapData(
        game,
        hasGameRevenueTeam.value,
        gameRevenueTeam.value,
        user.value?.info?.id,
        'game'
      ),
      mod: _mapData(
        mod,
        hasModRevenueTeam.value,
        modRevenueTeam.value,
        user.value?.info?.id,
        'mod'
      ),
      gameNameId,
      inGameTeam,
      modNameId,
    }
  })

  function _mapData(data, hasRevenueTeam, revenueTeam, userId, type) {
    return {
      type,
      data,
      monetization: isMonetizationEnabled(data?.monetization_options),
      marketplace: isMarketplaceEnabled(data?.monetization_options),
      partner: isPartnerProgramEnabled(data?.monetization_options),
      queue: isGameCurationPaid(data?.curation_option),
      onboarded:
        data?.monetization_team?.onboarded === GAME_MOD_ONBOARDING_COMPLETE,
      monetization_type: data?.monetization_team?.type,
      isKyc: data?.monetization_team?.type === TILIA_TYPE_KYC,
      isKyb: data?.monetization_team?.type === TILIA_TYPE_KYB,
      id: data?.id,
      leader: data?.[type === 'game' ? 'user' : 'submitted_by']?.id === userId,
      inRevenueShare:
        hasRevenueTeam && type === 'game'
          ? revenueTeam.some((m) => m.user?.id === userId)
          : null,
    }
  }

  function setTiliaCallback(key, value) {
    tiliaCallbackState.value = JSON.stringify({
      ...tiliaCallback.value,
      [key]: value,
    })
  }

  function setTransactionKey(gameId, tokenPackId, value) {
    const update = isObj(transactionKey.value)
      ? { ...transactionKey.value }
      : {}
    const key = `${gameId}-${tokenPackId}`
    if (value) {
      update[key] = value
    } else {
      delete update[key]
    }
    transactionKeyState.value = JSON.stringify(update)
  }

  function clearTransactionKey() {
    transactionKeyState.value = '{}'
  }

  function handleRedirect() {
    const { getGameId, getModId, push, routePath } = useRoute()
    if (getModId().value) {
      push({ name: MOD_VIEW_ROUTE })
    } else if (getGameId().value) {
      push({ name: MOD_BROWSE_ROUTE })
    } else if (routePath.value.includes('/me')) {
      push({ name: PROFILE_ACCOUNT_ROUTE })
    } else {
      push({ name: GAME_BROWSE_ROUTE })
    }
  }

  // access builder
  // game / mod admin checks handled in usePermissions
  function monetizationAccess(scopes, redirect = false) {
    // Wait until game/mod data is available
    if (
      redirect &&
      ((teamData.value.gameNameId && !teamData.value.game.data) ||
        (teamData.value.modNameId && !teamData.value.mod.data))
    ) {
      useStoreWatcher().registerParametersWatch(() => {
        monetizationAccess(scopes, true)
      })
      return
    }

    // Wait until game revenue share is available
    if (
      redirect &&
      teamData.value.gameNameId &&
      !teamData.value.modNameId &&
      teamData.value.game.inRevenueShare === null &&
      teamData.value.game.onboarded
    ) {
      useStoreWatcher().watchForRevenueSplit(
        'game',
        teamData.value.gameNameId,
        () => {
          monetizationAccess(scopes, true)
        }
      )
      return
    }

    if (
      redirect &&
      teamData.value.gameNameId &&
      teamData.value.modNameId &&
      cogsMod.value?.name_id !== teamData.value.modNameId &&
      scopes.some((s) => s.includes('mod:onboarded'))
    ) {
      useStoreWatcher().watchForCogsMod(
        teamData.value.gameNameId,
        teamData.value.modNameId,
        () => {
          monetizationAccess(scopes, true)
        }
      )
      return
    }

    const success = scopes.every(_scopeCheck)
    if (!success && redirect) {
      handleRedirect()
    }

    return success
  }

  function _scopeCheck(scope) {
    if (isArray(scope)) {
      const op = scope[0]
      if (op.toLowerCase() === 'or') {
        return scope.slice(1).some(_scopeCheck)
      } else if (op.toLowerCase() === 'and') {
        return scope.slice(1).every(_scopeCheck)
      }
    }

    switch (scope) {
      case 'global:monetization':
        return !!globalMonetization.value
      case 'global:marketplace':
        return !!globalMarketplace.value
      case 'game:monetization':
        return teamData.value.game.monetization
      case 'game:marketplace':
        return teamData.value.game.marketplace
      case 'game:atleastone':
        return teamData.value.game.marketplace
      case 'game:partner':
        return teamData.value.game.partner
      case 'game:queue':
        return teamData.value.game.queue
      case 'game:onboarded':
        return teamData.value.game.onboarded
      case 'game:notonboarded':
        return !teamData.value.game.onboarded
      case 'game:kyc':
        return teamData.value.game.isKyc
      case 'game:kyb':
        return teamData.value.game.isKyb
      case 'game:leader':
        return teamData.value.game.leader || isSuperAdmin()
      case 'game:leaderonly':
        return teamData.value.game.leader
      case 'game:notleader':
        return !teamData.value.game.leader && !isSuperAdmin()
      case 'game:teammember':
        return teamData.value.inGameTeam
      case 'game:notleaderonly':
        return !teamData.value.game.leader
      case 'game:revenueshare':
        return !!teamData.value.game.inRevenueShare
      case 'game:notrevenueshare':
        return !teamData.value.game.inRevenueShare
      case 'mod:monetization':
        return teamData.value.mod.monetization
      case 'mod:marketplace':
        return teamData.value.mod.marketplace
      case 'mod:onboarded':
        return teamData.value.mod.onboarded
      case 'mod:notonboarded':
        return !teamData.value.mod.onboarded
      case 'mod:leader':
        return (
          teamData.value.mod.leader ||
          teamData.value.inGameTeam ||
          isSuperAdmin()
        )
      case 'mod:leaderonly':
        return teamData.value.mod.leader
      case 'mod:notleader':
        return (
          !teamData.value.mod.leader &&
          !teamData.value.inGameTeam &&
          !isSuperAdmin()
        )
      case 'site:admin':
        return isSuperAdmin()
      case 'user:wallets':
        return hasMemberWallets.value
      case 'user:onboarded':
        return isOnboarded.value
      case 'user:notonboarded':
        return !isOnboarded.value
      case 'user:registered':
        return isMonRegistered.value
    }

    return true
  }

  return {
    clearTransactionKey,
    monetizationAccess,
    globalMonetization,
    setTransactionKey,
    globalMarketplace,
    setTiliaCallback,
    transactionKey,
    tiliaCallback,
    monetization,
    teamData,
  }
}
