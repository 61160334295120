import { getWeekCategories } from '@helpers/metricsUtils.js'
import { DateTime, Interval } from 'luxon'
import { computed, ref } from 'vue'
import {
  TIME_PERIOD_OPTIONS,
  SPARKLINE_TYPES,
  STORAGE_TYPES,
  TIME_UNITS,
} from '@config/chartOptions.js'

const timePeriod = ref(TIME_PERIOD_OPTIONS.LIFETIME.value)

const customDateSparkType = ref(SPARKLINE_TYPES.MONTHLY)
const gameRef = ref(undefined)
const modRef = ref(undefined)

const currentDate = DateTime.now().startOf('day')

const siteStartDateUTC = DateTime.fromISO('2018-01-01').setZone('utc', {
  keepLocalTime: true,
})

const dateIntervals = computed(() => {
  return Interval.fromDateTimes(
    siteStartDateUTC.startOf('day'),
    currentDate.endOf('day')
  )
    .mapEndpoints((x) =>
      x.setZone('utc', {
        keepLocalTime: true,
      })
    )
    .splitBy({ day: 1 })
})

const monthIntervals = computed(() => {
  return Interval.fromDateTimes(
    siteStartDateUTC.startOf('month'),
    currentDate.endOf('month')
  )
    .mapEndpoints((x) =>
      x.setZone('utc', {
        keepLocalTime: true,
      })
    )
    .splitBy({ month: 1 })
})

export default function (
  game,
  mod,
  defaultTimePeriod = TIME_PERIOD_OPTIONS.CUSTOM.value
) {
  if (game && game.value) {
    gameRef.value = game.value
  }

  if (mod && mod.value) {
    modRef.value = mod.value
  }

  const today = DateTime.now().toJSDate()
  const yesterday = DateTime.now().minus({ days: 1 }).endOf('day').toJSDate()
  const siteStart = DateTime.fromISO('2018-01-01').toJSDate()

  const minDate = computed(() =>
    modRef.value
      ? DateTime.fromSeconds(modRef.value.date_live).toUTC().startOf('day')
      : gameRef.value
        ? DateTime.fromSeconds(gameRef.value.date_live).toUTC().startOf('day')
        : siteStartDateUTC
  )
  const curTimePeriod = ref(defaultTimePeriod)

  // global dates
  const dateRange = computed(() => {
    // startDate is already in UTC
    const startDate = minDate.value

    // convert results to UTC for consistent results on server and remove time offset with setZone
    const endDate = DateTime.now().endOf('day').setZone('utc', {
      keepLocalTime: true,
    })

    return { startDate, endDate }
  })

  const lessThanAMonth = computed(
    () =>
      dateRange.value.endDate.diff(dateRange.value.startDate, 'months').months <
      1
  )

  const dailySparkline = computed(() => lessThanAMonth.value)
  const weeklyColumnChart = computed(() => lessThanAMonth.value)

  const weekCategories = computed(() => {
    return getWeekCategories(dateRange.value.startDate, dateRange.value.endDate)
      .categories
  })

  // Updated by calendar date change & chart zoom
  const selectedCalendarDates = ref({
    start: modRef.value
      ? DateTime.fromSeconds(modRef.value.date_live).toJSDate()
      : gameRef.value
        ? DateTime.fromSeconds(gameRef.value.date_live).toJSDate()
        : DateTime.fromISO('2018-01-01').toJSDate(),
    end: dateRange.value.endDate.toJSDate(),
  })

  // Only updated when calendar date changed
  const customDateRange = ref({
    startDate: modRef.value
      ? DateTime.fromSeconds(modRef.value.date_live)
      : gameRef.value
        ? DateTime.fromSeconds(gameRef.value.date_live)
        : DateTime.fromISO('2018-01-01'),
    endDate: dateRange.value.endDate,
  })

  function getTimeFromPeriod(input) {
    let start
    switch (input) {
      case TIME_PERIOD_OPTIONS.TWENTYFOUR_MONTHS.value:
        start = DateTime.now().minus({ months: 24 })
        break
      case TIME_PERIOD_OPTIONS.EIGHTEEN_MONTHS.value:
        start = DateTime.now().minus({ months: 18 })
        break
      case TIME_PERIOD_OPTIONS.FIFTEEN_MONTHS.value:
        start = DateTime.now().minus({ months: 15 })
        break
      case TIME_PERIOD_OPTIONS.TWELVE_MONTHS.value:
        start = DateTime.now().minus({ months: 12 })
        break
      case TIME_PERIOD_OPTIONS.SIX_MONTHS.value:
        start = DateTime.now().minus({ months: 6 })
        break
      case TIME_PERIOD_OPTIONS.THREE_MONTHS.value:
        start = DateTime.now().minus({ months: 3 })
        break
      case TIME_PERIOD_OPTIONS.ONE_MONTH.value:
        start = DateTime.now().minus({ months: 1 })
        break
      case TIME_PERIOD_OPTIONS.ONE_WEEK.value:
        start = DateTime.now().minus({ weeks: 1 })
        break
      case TIME_PERIOD_OPTIONS.LIFETIME.value:
      case TIME_PERIOD_OPTIONS.LIVE.value:
        start = minDate.value
        break
    }

    return start
  }

  function daysFromTimePeriod(input) {
    if (input === TIME_PERIOD_OPTIONS.CUSTOM.value) return 0

    const start = getTimeFromPeriod(input)
    return DateTime.now().diff(start, 'days').days
  }

  function updateDateSelection(type, input, timeUnit = TIME_UNITS.DAY) {
    if (input) {
      let newStart
      let newEnd

      switch (type) {
        case 'start':
          curTimePeriod.value = TIME_PERIOD_OPTIONS.CUSTOM.value
          newStart = DateTime.fromJSDate(input)
          newEnd = DateTime.fromJSDate(selectedCalendarDates.value.end)
          break
        case 'end':
          curTimePeriod.value = TIME_PERIOD_OPTIONS.CUSTOM.value
          newEnd = DateTime.fromJSDate(input)
          newStart = DateTime.fromJSDate(selectedCalendarDates.value.start)
          break
        default:
          newEnd = DateTime.now().plus({ days: 1 })
          curTimePeriod.value = input
          newStart = getTimeFromPeriod(input).startOf(timeUnit)
          break
      }

      if (['start', 'end'].includes(type)) {
        newStart = newStart.startOf(timeUnit)
        newEnd = newEnd.endOf(TIME_UNITS.DAY)
      }

      customDateRange.value = {
        startDate: newStart,
        endDate: newEnd,
      }

      selectedCalendarDates.value = {
        start: newStart.toJSDate(),
        end: newEnd.toJSDate(),
      }
    }
  }

  function getMinimumDate(daySelection) {
    const date = DateTime.now()
      .startOf('day')
      .minus({ days: daySelection })
      .toJSDate()
    return date
  }

  function populateMissingDateForSelectedRange(data, storageType) {
    if (!data) return
    const _data = Object.create(null)
    data.forEach((x) => (_data[x.label] = x.value))

    let intervals
    let startType

    if (storageType === STORAGE_TYPES.MONTHLY) {
      intervals = monthIntervals.value
      startType = 'month'
    } else {
      intervals = dateIntervals.value
      startType = 'day'
    }

    const minDateMillis = minDate.value.startOf(startType).toMillis()

    return intervals
      .filter((interval) => interval.start.toMillis() >= minDateMillis)
      .map((interval) => {
        const iso = ![
          STORAGE_TYPES.DAILY,
          STORAGE_TYPES.DAILY_SECONDARY_ONE,
          STORAGE_TYPES.DAILY_SECONDARY_TWO,
        ].includes(storageType)
          ? interval.start.toFormat('yyyy-MM')
          : interval.start.toISODate()
        const millis = interval.start.toMillis()
        const value = _data[iso]
        return [millis, value || 0]
      })
  }

  return {
    populateMissingDateForSelectedRange,
    selectedCalendarDates,
    updateDateSelection,
    customDateSparkType,
    daysFromTimePeriod,
    weeklyColumnChart,
    getTimeFromPeriod,
    customDateRange,
    getMinimumDate,
    dailySparkline,
    weekCategories,
    curTimePeriod,
    currentDate,
    timePeriod,
    siteStart,
    yesterday,
    dateRange,
    minDate,
    gameRef,
    modRef,
    today,
  }
}
