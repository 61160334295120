import { KEYS_MODS, KEYS_MARKETPLACE_MODS } from '@config/storeKeys.js'
import modSerializer from '@serializers/modSerializer.js'
import { API_RESULT_LIMIT } from '@config'
import { store } from '@stores'
import { computed } from 'vue'

const {
  deleteStoreArrayData,
  addToStoreData,
  updateStore,
  generateId,
  getStore,
} = store()

export default function () {
  function getMods(gameNameId) {
    return getStore(generateId(KEYS_MODS, gameNameId))
  }

  function getPopularMods(gameNameId) {
    return getStore(generateId(KEYS_MODS, gameNameId, 'popular'))
  }

  function getMarketplaceMods(gameNameId) {
    return getStore(generateId(KEYS_MARKETPLACE_MODS, gameNameId))
  }

  function getModByNameId(gameNameId, modNameId) {
    const mods = getMods(gameNameId)
    const monMods = getMarketplaceMods(gameNameId)
    modNameId = modNameId?.toLowerCase()

    return computed(
      () =>
        mods.value?.data?.find(
          (mod) => mod.name_id.toLowerCase() === modNameId
        ) ||
        monMods.value?.data?.find(
          (mod) => mod.name_id.toLowerCase() === modNameId
        )
    )
  }

  function getModId(modNameId, gameNameId) {
    modNameId = modNameId?.toLowerCase()
    const mods = getStore(generateId(KEYS_MODS, gameNameId))
    const monMods = getStore(generateId(KEYS_MARKETPLACE_MODS, gameNameId))
    const mod =
      mods.value?.data?.find(
        (mod) => mod.name_id.toLowerCase() === modNameId
      ) ||
      monMods.value?.data?.find(
        (mod) => mod.name_id.toLowerCase() === modNameId
      )
    return mod ? mod.id : null
  }

  function addMods(gameNameId, mods, query) {
    updateStore(generateId(KEYS_MODS, gameNameId), {
      ...modSerializer.transform(mods),
      query,
    })
  }

  function addPopularMods(gameNameId, mods) {
    updateStore(
      generateId(KEYS_MODS, gameNameId, 'popular'),
      modSerializer.transform(mods)
    )
    if (!getMods(gameNameId).value?.result_total) {
      addMods(gameNameId, mods, {
        _limit: API_RESULT_LIMIT,
        _offset: 0,
        _sort: 'downloads',
      })
    }
  }

  function addMarketplaceMods(gameNameId, mods, query) {
    updateStore(generateId(KEYS_MARKETPLACE_MODS, gameNameId), {
      ...modSerializer.transform(mods),
      query,
    })
  }

  function removeMods(gameNameId) {
    updateStore(generateId(KEYS_MODS, gameNameId))
  }

  function addMod(gameNameId, mod) {
    const _mod = modSerializer.transform(mod)
    addToStoreData(generateId(KEYS_MODS, gameNameId), _mod)
    return _mod
  }

  function removeMod(gameNameId, modNameId) {
    deleteStoreArrayData(
      generateId(KEYS_MODS, gameNameId),
      (mod) => mod.name_id === modNameId
    )
  }

  return {
    addMarketplaceMods,
    getMarketplaceMods,
    addPopularMods,
    getPopularMods,
    getModByNameId,
    removeMods,
    removeMod,
    getModId,
    addMods,
    getMods,
    addMod,
  }
}
