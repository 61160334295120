<template>
  <div class="tw-screenheight">
    <homepage v-if="routeName === HOME_ROUTE" />
    <docs v-else-if="routeName === DOCS_ROUTE" />
    <template v-else-if="!widget">
      <modio-header />

      <div
        class="tw-screenheight tw-flex md:tw-pt-0 tw-w-full tw-relative"
        :class="{ 'tw-pt-16 md:tw-pl-18': !routeMeta.noSidebar }"
      >
        <div v-if="!routeMeta.noSidebar" class="tw-dark">
          <modio-sidebar />
        </div>
        <!-- removing 'theming classes' will cause style issue throughout the site -->
        <main ref="mainWrap" class="tw-screenheight tw-w-full tw-dark">
          <modio-toast />

          <div
            :id="SCROLL_TO_ID"
            class="scroll-region tw-flex tw-flex-col tw-justify-between tw-h-full tw-w-full tw-relative tw-util-scrollbar tw-overflow-y-auto tw-overflow-x-hidden tw-text-theme"
            :class="{ 'tw-pb-14 tw-bg-theme': !routeMeta.noSidebar }"
          >
            <div class="tw-flex tw-flex-col tw-flex-1">
              <router-view :key="$route.path" v-slot="{ Component }">
                <component :is="Component"></component>
              </router-view>
            </div>

            <conversation-tab v-if="isLoggedIn" />
            <theming-menu
              v-if="THEMING_DEBUG || statusBar"
              :class="{ 'tw-mb-14': isAdmin && MODIO_DEBUG }"
            />
          </div>
          <admin-testing-panel v-if="MODIO_DEBUG" />
          <terms-popup-modal />
          <cookie-modal />
        </main>
      </div>
    </template>
    <widget v-else></widget>
    <!-- oauth loading/success/error -->
    <template v-if="!widget">
      <loading-modal
        :message="modalState?.message"
        :button-text="modalState?.buttonText"
        :button-type="modalState?.buttonType"
        :status="modalState?.status"
        :show="showOauthLoadingModal"
      />
      <email-callback
        v-if="emailCallbackToken"
        :token="emailCallbackToken"
        @clear:token="() => (emailCallbackToken = null)"
      />
      <initial-login-modal v-if="initialLogin" />
      <menu-overlay />
    </template>
    <global-modals />
  </div>
</template>

<script>
import { THEMING_DEBUG, MODIO_DEBUG, BREAKPOINTS, MODIO_ENV } from '@config'
import { defineAsyncComponent, onMounted, computed, watch, ref } from 'vue'
import { OAUTH_ROUTE, HOME_ROUTE, DOCS_ROUTE } from '@config/routeNames.js'
import ConversationTab from '@components/Conversation/ConversationTab.vue'
import InitialLoginModal from '@components/Modals/InitialLoginModal.vue'
import { getQueryObj, isWidget, getHashObj } from '@helpers/utils.js'
import MenuOverlay from '@components/Sidebar/Mobile/MenuOverlay.vue'
import TermsPopupModal from '@components/Modals/TermsPopupModal.vue'
import { ALL_LOGIN_METHODS, PORTAL_STUDIO } from '@config/auth.js'
import LoadingModal from '@components/Modals/LoadingModal.vue'
import Homepage from '/modio-home-page/src/views/Homepage.vue'
import ThemingMenu from '@components/Sidebar/ThemingMenu.vue'
import CookieModal from '@components/Modals/CookieModal.vue'
import { BANNER_TEST_ENV } from '@composables/useBanners.js'
import ModioToast from '@components/Message/ModioToast.vue'
import GlobalModals from '@views/layouts/GlobalModals.vue'
import ModioSidebar from '@views/layouts/ModioSidebar.vue'
import { setGlobalModal } from '@composables/useModal.js'
import ModioHeader from '@views/layouts/ModioHeader.vue'
import ViewSuggestions from '@views/ViewSuggestions.vue'
import Docs from '/modio-home-page/src/views/Docs.vue'
import { MODAL_LOGIN } from '@config/globalModals.js'
import { LS_TEST_ENV } from '@config/localstorage.js'
import { SCROLL_TO_ID } from '@config/htmlIDs.js'
import { useBreakpoints } from '@vueuse/core'
import Widget from '@views/Widget.vue'
import { authStore } from '@stores'
import {
  useStoreWatcher,
  useSuggestions,
  useBoundaries,
  useStatusBar,
  useNavMenus,
  useBanners,
  useTheming,
  useStorage,
  useOauth,
  useRoute,
  useSeo,
} from '@composables'
export default {
  components: {
    EmailCallback: defineAsyncComponent(
      () => import('@components/Login/EmailCallback.vue')
    ),
    AdminTestingPanel: defineAsyncComponent(
      () => import('@components/Admin/TestingPanel/AdminTestingPanel.vue')
    ),
    InitialLoginModal,
    ViewSuggestions,
    TermsPopupModal,
    ConversationTab,
    GlobalModals,
    LoadingModal,
    ModioSidebar,
    MenuOverlay,
    ThemingMenu,
    ModioHeader,
    CookieModal,
    ModioToast,
    Homepage,
    Widget,
    Docs,
  },
  setup() {
    const { isSuperAdmin, isLoggedIn, initialLogin, setPortal } = authStore()
    const { statusBar } = useStatusBar()
    const { modalState } = useOauth()
    const { routeName, routeMeta, routeParams } = useRoute()
    const { init } = useStoreWatcher()
    const { showOverlay, closeAllMenus } = useNavMenus()
    const { watchTheme } = useTheming()
    const { setBoundaryVars } = useBoundaries()
    const { addWarningBanner } = useBanners()
    const mainWrap = ref(null)
    const widget = ref(true)
    const { suggestionRouteName, clearSuggestions } = useSuggestions()
    const breakpoints = useBreakpoints(BREAKPOINTS)
    const lgAndUp = breakpoints.greater('md')
    const emailCallbackToken = ref(null)

    const showOauthLoadingModal = computed(
      () => routeName.value === OAUTH_ROUTE
    )

    // watch for route changes to
    // set correct theming options
    watchTheme()

    watch(
      routeName,
      (_name) => {
        widget.value = isWidget()
        document.body.className =
          _name === 'home'
            ? 'tw-bg-black tw-overflow-x-hidden'
            : 'tw-bg-dark tw-overflow-hidden'
      },
      { immediate: true }
    )

    const stopInitialRequestsWatch = watch(routeName, (_name) => {
      if (_name !== HOME_ROUTE) {
        stopInitialRequestsWatch()
      }
    })

    watch(
      lgAndUp,
      () => {
        if (lgAndUp.value && showOverlay.value) {
          closeAllMenus()
        }
      },
      { immediate: true }
    )

    watch(
      [routeName, routeParams],
      ([newRouteName, newRouteParams], [, oldRouteParams]) => {
        if (
          suggestionRouteName.value &&
          (newRouteName !== suggestionRouteName.value ||
            (newRouteParams?.game &&
              newRouteParams.game != oldRouteParams?.game))
        ) {
          if (routeMeta.value.requiresAuth && !isLoggedIn.value) return

          clearSuggestions()
        }
      }
    )

    function setEmailCallbackToken() {
      const hash = getHashObj()
      if (hash.token) {
        emailCallbackToken.value = hash.token
      }
    }

    function setQueryPortal() {
      const query = getQueryObj()
      const portal = query.portal || query.ref
      const login = query.login || query.auto
      const sbx = query.sbx

      if (portal) {
        setPortal(portal)

        if (
          (!isLoggedIn.value || portal === PORTAL_STUDIO) &&
          ALL_LOGIN_METHODS.filter(
            (method) => method.login || method.type === PORTAL_STUDIO
          )
            .map((method) => method.type)
            .includes(portal)
        ) {
          setGlobalModal({
            modal: MODAL_LOGIN,
            data: {
              portal,
              login,
              sbx,
            },
          })
        }
      }
    }

    async function startPusher() {
      try {
        const pusher = await import('@plugins/pusher.js')
        pusher.initialize()
      } catch (error) {
        console.warn(error)
      }
    }

    function setTestEnvMessage() {
      if (MODIO_ENV === 'test') {
        addWarningBanner(BANNER_TEST_ENV, {
          isError: true,
          customComponent: 'TestEnv',
          show: useStorage(LS_TEST_ENV, true).value !== 'true',
        })
      }
    }

    onMounted(() => {
      useSeo().handleSeo()
      init()
      setQueryPortal()
      setEmailCallbackToken()
      setTestEnvMessage()
    })

    watch(
      mainWrap,
      (_mainWrap) => {
        if (_mainWrap) {
          setBoundaryVars('main', _mainWrap.getBoundingClientRect())
        }
      },
      {
        flush: 'pre',
      }
    )

    const isAdmin = computed(() =>
      isSuperAdmin(!['staging', 'test'].includes(MODIO_ENV) ? 'admin' : '')
    )

    watch(
      isAdmin,
      (_isAdmin) => {
        if (_isAdmin && MODIO_DEBUG) {
          startPusher()
        }
      },
      {
        immediate: true,
      }
    )

    return {
      showOauthLoadingModal,
      emailCallbackToken,
      THEMING_DEBUG,
      initialLogin,
      SCROLL_TO_ID,
      MODIO_DEBUG,
      OAUTH_ROUTE,
      modalState,
      isLoggedIn,
      DOCS_ROUTE,
      HOME_ROUTE,
      routeMeta,
      statusBar,
      routeName,
      mainWrap,
      isAdmin,
      widget,
    }
  },
}
</script>

<style scoped>
@media screen(md) {
  .scroll-region::-webkit-scrollbar-track {
    margin-top: theme(height.header);
  }
}
</style>
