<template>
  <nav
    class="tw-bg-rich-black lg:tw-bg-transparent tw-h-screen lg:tw-h-auto tw-w-full xxs:tw-w-72 lg:tw-w-auto tw-fixed lg:tw-relative tw-top-0 tw-right-0 tw-transform tw-transition lg:tw-transition-none tw-z-21"
    :class="
      showHomeMobMenu
        ? 'tw-translate-x-0'
        : 'tw-translate-x-full xxs:tw-translate-x-72 lg:tw-translate-x-0 tw-invisible lg:tw-visible'
    "
  >
    <ul
      class="nav tw-flex tw-flex-col lg:tw-flex-row tw-items-end lg:tw-items-center lg:tw-space-x-6 tw-text-white tw-font-bold tw-mt-18 lg:tw-mt-0 lg:tw-pt-0 tw-text-md tw-overflow-auto lg:tw-overflow-visible tw-util-scrollbar"
    >
      <li
        class="tw-block lg:tw-hidden tw-relative tw-group tw-border-t tw-border-grey tw-border-opacity-40 tw-w-full tw-px-4"
      >
        <browse-button />
      </li>

      <span
        v-if="MODIO_ENV === 'test'"
        class="tw-px-2 tw-py-1 tw-outline-none tw-bg-danger tw-text-xs xs:tw-text-sm xs:tw-px-5 xs:tw-py-1.5 tw-text-white tw-rounded-2xl tw-absolute tw-left-10 tw-top-6 lg:tw-static"
      >
        TEST ENVIRONMENT
      </span>

      <li
        v-for="(item, index) in NAVIGATION"
        :key="index"
        class="tw-relative tw-group tw-border-t lg:tw-border-none tw-border-grey tw-border-opacity-40 tw-w-full lg:tw-w-auto tw-cursor-default lg:tw-py-2"
      >
        <a
          v-if="item.external"
          :href="item.link"
          target="_blank"
          rel="noreferrer noopener"
          class="tw-flex tw-items-center tw-h-12 lg:tw-h-auto tw-px-4 lg:tw-px-0 hover:tw-text-primary-hover focus:tw-text-primary-hover"
        >
          {{ item.text }}
        </a>
        <router-link
          v-else-if="item.link"
          :to="{
            name: item.link,
            hash: item.anchor ? `#${item.anchor}` : null,
          }"
          class="tw-w-full lg:tw-w-auto tw-flex tw-items-center tw-h-12 tw-space-x-1 tw-outline-none hover:tw-text-primary-hover focus:tw-text-primary-hover lg:tw-h-auto tw-px-4 lg:tw-px-0"
          :tabindex="showHomeMobMenu ? '0' : '-1'"
          @click="scrollToAnchor($event, item)"
        >
          <span>{{ item.text }}</span>
        </router-link>
        <template v-else-if="item.menu">
          <span
            class="tw-w-full lg:tw-w-auto tw-flex tw-items-center tw-h-12 tw-space-x-1 tw-outline-none hover:tw-text-primary-hover focus:tw-text-primary-hover lg:tw-h-auto tw-px-4 lg:tw-px-0"
            :tabindex="showHomeMobMenu ? '0' : '-1'"
          >
            <span class="lg:tw-my-4">{{ item.text }}</span>

            <font-awesome-icon
              v-if="item.menu"
              icon="chevron-down"
              size="xs"
              class="tw-hidden lg:tw-block"
            />
          </span>

          <div
            class="tw-relative lg:tw-absolute tw-bg-white tw-bg-opacity-10 lg:tw-bg-opacity-100 lg:tw-text-rich-black lg:tw-rounded-lg lg:tw-w-40 tw-flex-col lg:tw-invisible group-hover:tw-visible lg:tw-opacity-0 group-hover:tw-opacity-100 tw-overflow-hidden tw-shadow-md"
          >
            <template v-for="(subItem, subIndex) in item.menu" :key="subIndex">
              <a
                v-if="subItem.external"
                :href="subItem.link"
                :class="subNavStyles"
              >
                {{ subItem.text }}
              </a>
              <button
                v-else-if="subItem.modal"
                :class="subNavStyles"
                @click="triggerModal(subItem.modal)"
              >
                {{ subItem.text }}
              </button>
              <router-link
                v-else
                :to="{ name: subItem.link }"
                :class="subNavStyles"
                @click="closeHomeMobMenu"
              >
                {{ subItem.text }}
              </router-link>
            </template>
          </div>
        </template>
      </li>
    </ul>
  </nav>
</template>

<script>
import BrowseButton from '@home_components/Header/BrowseButton.vue'
import { setGlobalModal } from '@composables/useModal.js'
import { scrollToElementOffset } from '@helpers/utils.js'
import { NAVIGATION } from '@home_config/navigation.js'
import { useNavMenus } from '@home_composables'
import { useRoute } from '@composables'
import { MODIO_ENV } from '@config'

export default {
  components: {
    BrowseButton,
  },
  setup() {
    const { routeName } = useRoute()
    const { showHomeMobMenu, closeHomeMobMenu } = useNavMenus()
    const subNavStyles =
      'tw-block tw-w-full tw-px-8 lg:tw-px-4 tw-py-4 lg:tw-py-2 tw-outline-none hover:tw-text-primary-hover focus:tw-text-primary-hover tw-border-b last:tw-border-b-0 tw-text-left tw-border-light-grey tw-border-opacity-10'

    function triggerModal(modal) {
      closeHomeMobMenu()
      setGlobalModal({ modal, data: { homepage: true } })
    }

    function scrollToAnchor(e, { link, anchor }) {
      if (anchor && routeName.value === link) {
        scrollToElementOffset(anchor)
        e.preventDefault()
      }
      closeHomeMobMenu()
    }

    return {
      closeHomeMobMenu,
      showHomeMobMenu,
      scrollToAnchor,
      triggerModal,
      subNavStyles,
      NAVIGATION,
      MODIO_ENV,
    }
  },
}
</script>

<style lang="css" scoped>
.nav {
  height: calc(100vh - 4.5rem);
  height: calc(100dvh - 4.5rem);
}
@media screen and (min-width: 1024px) {
  .nav {
    height: auto;
  }
}
</style>
