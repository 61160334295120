import { isObj, cleanHTMLEntities } from '@helpers/utils.js'
import gameDefaults from '@types/game.js'
import Serializer from './Serializer.js'
import {
  GAME_MOD_SUBMISSION_OPTIONS,
  GAME_PRESENTATION_OPTIONS,
  GAME_MOD_CURATION_OPTIONS,
  GAME_TAG_TYPE_OPTIONS,
  STATUS_OPTIONS,
} from '@config/options.js'

const gameOptions = {
  status_options: STATUS_OPTIONS,
  presentation_options: GAME_PRESENTATION_OPTIONS,
  tag_types: GAME_TAG_TYPE_OPTIONS,
  submission_options: GAME_MOD_SUBMISSION_OPTIONS,
  curation_options: GAME_MOD_CURATION_OPTIONS,
}

const transformations = [
  _transformTeam,
  _flatten([
    'community',
    'connections',
    'content',
    'info',
    'links',
    'media',
    'plan',
  ]),
  transformTags,
  _maskConnections,
  _transformAgreement,
  _clean,
]

const cogsGameSerializer = new Serializer({
  transformations,
  options: gameOptions,
  defaults: gameDefaults,
})

function _transformTeam(game) {
  const gameCopy = { ...game }
  gameCopy.team = [...game.team]

  return gameCopy
}

function _maskConnections(game) {
  const connections = [
    'google_analytics',
    'steam_ticket',
    'gog_ticket',
    'switch_app_id',
    'oculus_rift_id',
    'oculus_rift_secret',
    'oculus_quest_id',
    'oculus_quest_secret',
  ]

  connections.forEach((connection) => {
    if (game[connection]) {
      let text = game[connection].replace(/\*/g, '').replace(' ', '')

      const len = text.length
      const mid = Math.ceil(len / 2)
      const first = text.slice(0, mid)
      const last = text.slice(mid)

      game[connection] = [first, last].join('*'.repeat(25))
    }
  })

  return game
}

function _transformAgreement(game) {
  let gameCopy = { ...game }
  if (gameCopy.agreement) {
    gameCopy.agreement = gameCopy.agreement ? 1 : 0
  }

  gameCopy.custom_agreement = gameCopy.agreement === 0

  return gameCopy
}

function _flatten(include = []) {
  return (game) => {
    let gameCopy = {}

    Object.keys(game).forEach((key) => {
      if (include.includes(key)) {
        gameCopy = { ...gameCopy, ...game[key] }
      } else {
        gameCopy[key] = game[key]
      }
    })

    return gameCopy
  }
}

export function transformTags(game) {
  const gameCopy = { ...game }

  if (gameCopy.tag_options) {
    gameCopy.tag_options = _setTagIds(gameCopy.tag_options)
  }

  return gameCopy
}

function _setTagIds(tags) {
  return tags.map((tag, index) => ({
    ...tag,
    id: `T-${index}`,
    selected: [],
    // Handle cases where tags are saved as an object in API
    tags: isObj(tag.tags) ? Object.values(tag.tags) : tag.tags,
  }))
}

function _clean(game) {
  game.name = cleanHTMLEntities(game.name)
  game.summary = cleanHTMLEntities(game.summary)
  return game
}

export default cogsGameSerializer
