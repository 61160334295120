<template>
  <div
    class="tw-w-60 tw-rounded tw-absolute tw-top-4 tw-transform tw--translate-y-full tw--left-3 tw-pb-8 tw-z-10"
  >
    <div class="tw-relative tw-shadow-xl">
      <div class="tw-relative tw-z-2">
        <div
          class="tw-flex tw-justify-between tw-items-end tw-space-x-2 tw-p-4 dark:tw-shadow-inset"
        >
          <div class="tw-flex tw-w-full tw-space-x-3">
            <avatar-icon :user="user" />

            <div class="tw-flex tw-flex-1">
              <div class="tw-flex tw-flex-col tw-w-full">
                <span
                  class="tw-w-full tw-font-bold tw-text-sm tw-break-all tw-leading-snug"
                >
                  {{ user?.username }}
                </span>

                <div class="tw-flex tw-justify-between">
                  <span
                    class="tw-uppercase tw-text-xs"
                    :class="onlineStatus ? 'tw-text-success' : 'tw-text-info'"
                  >
                    {{ onlineStatus ? 'Online' : 'Offline' }}
                  </span>

                  <div class="tw-flex tw-space-x-3">
                    <base-link :to="to" :link-primary="false">
                      <base-button
                        radius="none"
                        text-link-hover
                        primary
                        icon="id-card-alt"
                        :sr-only="`View ${user && user.username} profile`"
                      />
                    </base-link>

                    <base-button
                      v-if="showMessageButton"
                      radius="none"
                      text-link-hover
                      primary
                      icon="comment"
                      :sr-only="`Message ${user && user.username}`"
                      @click="onMessageUser(user)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="tw-rounded tw-absolute tw-top-0 tw-left-0 tw-h-full tw-w-full tw-z-1 tw-bg-theme-3"
      />
    </div>

    <font-awesome-icon
      icon="caret-down"
      class="tw-h-10 tw-absolute tw-bottom-2 tw-left-4 tw-leading-tight tw-z-3 tw-text-theme-3"
      size="3x"
    />
  </div>
</template>

<script>
import AvatarIcon from '@components/Users/AvatarIcon.vue'
import { USER_ROUTE } from '@config/routeNames.js'
import { isOnlineUser } from '@helpers/utils.js'
import { useMessenger } from '@composables'
import { toRefs, computed } from 'vue'
import { authStore } from '@stores'
export default {
  components: {
    AvatarIcon,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const { user } = toRefs(props)
    const { onMessageUser } = useMessenger()
    const { getUserId, isLoggedIn } = authStore()

    const showMessageButton = computed(
      () => isLoggedIn.value && user.value?.id !== getUserId()
    )

    const to = computed(() => ({
      name: USER_ROUTE,
      params: { user: user.value?.name_id || user.value?.username },
    }))
    const onlineStatus = computed(() => {
      return isOnlineUser(user.value?.date_online)
    })

    return {
      showMessageButton,
      onMessageUser,
      onlineStatus,
      to,
    }
  },
}
</script>
