import { useSuggestions, useTip, useNavMenus } from '@composables'
import { handleScroll, validNameId } from '@helpers/utils.js'
import { createRouter, createWebHistory } from 'vue-router'
import { authStore, monetizationStore } from '@stores'
import { SS_REDIRECT } from '@config/localstorage'
import { SCROLL_TO_ID } from '@config/htmlIDs.js'
import { updateLocalStorage } from '@services'
import routes from './routes.js'
import { ref } from 'vue'
import {
  WIDGET_PREFIX_ROUTE,
  GAME_BROWSE_ROUTE,
  LOGIN_ROUTE,
} from '@config/routeNames.js'

export const profileFrom = ref(undefined)

const { requiresRefresh, isSuperAdmin, isLoggedIn } = authStore()
const { monetizationAccess } = monetizationStore()

const savedScrollPositions = new Map()

const router = new createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: (to, from, savedPos) => {
    const key = from.fullPath + '|' + to.fullPath
    // If used browser back button, scroll to saved postiion
    if (savedPos && savedScrollPositions.has(key)) {
      document.getElementById(SCROLL_TO_ID).scrollTop =
        savedScrollPositions.get(key)
      savedScrollPositions.delete(key)
    } else {
      handleScroll(to, from)
    }
  },
})

function handleCloseMobileMenu(to, from) {
  let close = false
  const fromMatches = from.matched.map((m) => m.path)
  const toMatches = to.matched.map((m) => m.path)

  if (from.name === to.name) {
    // Bail if same page
    return
  }

  if (to.matched[0]?.children.length) {
    for (const m of fromMatches) {
      if (toMatches.includes(m)) {
        close = true
        break
      }
    }
  } else {
    close = true
  }

  if (close) {
    useNavMenus().closeMobMenu()
  }
}

router.beforeEach((to, _, next) => {
  let routeName
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (requiresRefresh()) {
      updateLocalStorage()
    }
    // redirect to login page if not logged in
    if (!isLoggedIn.value) {
      if (to.fullPath?.split('/').pop() === WIDGET_PREFIX_ROUTE) {
        sessionStorage.setItem(SS_REDIRECT, to.path)
        routeName = `${LOGIN_ROUTE}-${WIDGET_PREFIX_ROUTE}`
      }
    } else if (
      to.matched.some((record) => record.meta.superAdmin) &&
      !isSuperAdmin()
    ) {
      // redirect to game browse if attemping a super admin route without being a super admin
      routeName = GAME_BROWSE_ROUTE
    }
  }

  let routeChanged = false
  const _params = {}
  if (to.params.mod) {
    _params.mod = validNameId(to.params.mod)
    if (_params.mod !== to.params.mod) routeChanged = true
  }
  if (to.params.guide) {
    _params.guide = validNameId(to.params.guide)
    if (_params.guide !== to.params.guide) routeChanged = true
  }
  if (to.params.game) {
    _params.game = validNameId(to.params.game)
    if (_params.game !== to.params.game) routeChanged = true
  }

  // make sure to always call next()!
  if (routeName) next({ name: routeName })
  else if (routeChanged) next({ name: to.name, params: _params })
  else next()
})

router.afterEach((to, from) => {
  // used to ensure back button on UserView returns out of user profile
  if (to.path.includes('/u/') && !from.path.includes('/u/')) {
    profileFrom.value = from
  }

  const { handleSuggestion } = useSuggestions()
  useTip().clearTip()

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (
      !isLoggedIn.value &&
      to.fullPath?.split('/').pop() !== WIDGET_PREFIX_ROUTE
    ) {
      handleSuggestion()
      return
    }
  }
  savedScrollPositions.set(
    to.fullPath + '|' + from.fullPath,
    document.getElementById(SCROLL_TO_ID).scrollTop
  )
  handleCloseMobileMenu(to, from)
  useNavMenus().closeAllMenus()

  // check if try to access route without permission
  if (to?.meta?.scope) {
    const scope = to.meta.scope
    monetizationAccess(scope, true)
  }
})

export default router
