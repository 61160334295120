<template>
  <div
    :id="inputId"
    class="tw-w-full"
    :class="{
      'tw-sticky tw-z-15 tw-bg-theme': topLevel,
      'tw-top-0 md:tw-top-[theme(height.header)]': topLevel && appearOverHeader,
      'tw-top-[theme(height.header)]': topLevel && !appearOverHeader,
    }"
  >
    <div
      v-if="topBorder"
      class="tw-h-px tw-w-full"
      :class="altBackground ? 'tw-bg-theme-2' : 'tw-bg-theme-3'"
    />
    <div
      ref="scrollTarget"
      class="tw-relative tw-w-full tw-overflow-x-auto tw-util-scrollbar"
    >
      <div
        ref="target"
        class="tw-flex tw-my-4 tw-space-x-8 lg:tw-space-x-10 tw-overflow-visible tw-w-fit"
        :class="{ [PADDING]: topLevel }"
      >
        <component
          :is="
            option.to &&
            (!option.subOptions || (medScreen && !IS_IOS_TOUCH_DEVICE))
              ? 'router-link'
              : 'button'
          "
          v-for="(option, index) in options"
          :key="index"
          :to="option.subOptions ? option.subOptions[0].to : option.to || null"
          class="tw-flex tw-justify-center tw-relative tw-outline-none tw-text-h6 tw-font-bold tw-whitespace-nowrap tw-button-transition tw-group"
          :class="{ 'tw-hidden': option.hidden }"
          :tabindex="isActive(index) ? '-1' : '0'"
          @click="onClick(option)"
        >
          <loading-title v-if="loading" random class="tw-py-1" />

          <template v-else>
            <div>
              <span :class="linkClass(option, index)">{{ option.text }}</span>
              <span
                v-if="showNewLabel(option.newLabel)"
                class="tw-absolute tw--bottom-3 tw-right-1 tw-py-px tw-px-1 tw-rounded tw-bg-red-500 tw-text-[10px] tw-text-white"
                >NEW</span
              >

              <div
                v-if="option.count"
                class="tw-bubble tw-bg-primary tw-text-primary-text tw-ml-1 tw-relative tw-top-px tw-float-right"
              >
                {{ option.count }}
              </div>
              <div
                class="tw-absolute tw-inset-x-0 tw-h-0.5 tw-bg-primary"
                :class="[
                  isActive(index) ? 'tw-opacity-100' : 'tw-opacity-0',
                  IS_IOS_TOUCH_DEVICE ? 'tw--bottom-3' : 'tw--bottom-4',
                ]"
              />
              <template v-if="option.subOptions">
                <font-awesome-icon
                  icon="chevron-down"
                  size="xs"
                  class="tw-ml-2 tw-opacity-75 group-hover:tw-opacity-100 group-active:group-hover:tw-opacity-100"
                />
                <div
                  v-if="medScreen && !IS_IOS_TOUCH_DEVICE"
                  class="tw-absolute md:tw-fixed tw-z-1 tw-top-[calc(theme(height.header)+2.5rem)] tw-hidden group-hover:tw-block group-active:tw-block tw-shadow-md tw-shadow-theme-1 tw-min-w-24 tw-pt-1 tw-ml-3.5"
                  :style="{ translate: `-${scrollLeft}px` }"
                >
                  <div
                    class="tw-flex-col tw-bg-theme-1 tw-rounded-md tw-hidden group-hover:tw-flex group-active:tw-flex tw-transition-opacity tw--ml-4"
                  >
                    <component
                      :is="subOption.to ? 'router-link' : 'button'"
                      v-for="(subOption, subIndex) in option.subOptions"
                      :key="subIndex"
                      class="tw-px-4 tw-py-2 hover:tw-bg-theme-2"
                      :class="[
                        linkClass(option, index, subIndex),
                        {
                          'tw-rounded-b-md':
                            subIndex + 1 === option.subOptions.length,
                          'tw-rounded-t-md': subIndex === 0,
                        },
                      ]"
                      :to="subOption.to || null"
                      @click="updateTab(subOption)"
                      >{{ subOption.text }}
                      <span
                        v-if="showNewLabel(subOption.newLabel)"
                        class="tw-relative tw-ml-1 tw-right-1 tw-py-px tw-px-1 tw-rounded tw-bg-red-500 tw-text-[10px] tw-text-white"
                        >NEW</span
                      >
                    </component>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </component>
      </div>
    </div>
    <div
      class="tw-h-px"
      :class="altBackground ? 'tw-bg-theme-2' : 'tw-bg-theme-3'"
    />
    <base-modal
      size="md"
      no-padding
      :show="isModalOpen"
      has-close-btn
      @close:modal="hideModal"
    >
      <div class="tw-flex tw-flex-col">
        <component
          :is="subOption.to ? 'router-link' : 'button'"
          v-for="(subOption, subIndex) in modalSubOptions"
          :key="subIndex"
          class="tw-p-4 hover:tw-bg-theme-2"
          :class="[
            linkClass(undefined, undefined, subIndex),
            {
              'tw-border-b tw-border-theme-2':
                subIndex + 1 !== modalSubOptions.length,
            },
          ]"
          :to="subOption.to || null"
          @click="updateTab(subOption)"
          >{{ subOption.text }}
          <span
            v-if="showNewLabel(subOption.newLabel)"
            class="tw-relative tw-ml-1 tw-right-1 tw-py-px tw-px-1 tw-rounded tw-bg-red-500 tw-text-[10px] tw-text-white"
            >NEW</span
          >
        </component>
      </div>
    </base-modal>
  </div>
</template>

<script>
import LoadingTitle from '@components/Loading/LoadingTitle.vue'
import { PADDING } from '@components/View/ViewContainer.vue'
import { IS_IOS_TOUCH_DEVICE, BREAKPOINTS } from '@config'
import { useBreakpoints, useScroll } from '@vueuse/core'
import { toRefs, ref, watchEffect } from 'vue'
import { genHtmlId } from '@helpers/utils.js'
import { useModal } from '@composables'
import { newLabelStore } from '@stores'
export default {
  components: {
    LoadingTitle,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    modelValue: {
      type: [String, Number],
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    topLevel: {
      type: Boolean,
      default: true,
    },
    topBorder: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    altBackground: {
      type: Boolean,
      default: false,
    },
    appearOverHeader: {
      type: Boolean,
      default: false,
    },
    noScroll: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const { id, disabled, modelValue, options, noScroll } = toRefs(props)
    const target = ref(null)
    const scrollTarget = ref(null)
    const { greaterOrEqual } = useBreakpoints(BREAKPOINTS)
    const { showNewLabel, checkNavNewLabel } = newLabelStore()

    const medScreen = greaterOrEqual('md')
    const modalSubOptions = ref([])

    const { isModalOpen, showModal, hideModal } = useModal()

    const inputId = id.value || genHtmlId()

    function isActive(index, subIndex) {
      if (!index && subIndex >= 0) {
        const subOption = modalSubOptions.value[subIndex]

        if (!subOption) return false
        if (subOption.to.name === modelValue.value) {
          return true
        }
        return false
      }

      if (modelValue.value === '' && index === 0) {
        return true
      }

      if (options.value[index].subOptions && subIndex === undefined) {
        const subOption = options.value[index].subOptions.find(
          (x) =>
            x.to.name === modelValue.value ||
            x.to.navAlias?.includes(modelValue.value)
        )

        if (subOption) return true
      }

      return subIndex !== undefined
        ? modelValue.value ===
            options.value[index].subOptions?.[subIndex].to.name
        : modelValue.value === options.value[index].text ||
            modelValue.value === options.value[index].to?.name ||
            modelValue.value === options.value[index].value
    }

    const { x: scrollLeft } = useScroll(scrollTarget)

    function linkClass(option, index, subIndex) {
      if (option?.disabled) {
        return 'tw-cursor-not-allowed tw-opacity-30'
      }
      if (isActive(index, subIndex)) {
        return subIndex !== undefined ? 'tw-bg-theme-2' : ''
      }
      return 'hover:tw-text-primary-hover focus:tw-text-primary-hover tw-opacity-75'
    }

    function updateTab(option) {
      checkNavNewLabel(option.newLabel?.value)

      if (!disabled.value && !option.disabled && !option.to) {
        emit(
          'input',
          Number.isInteger(modelValue.value) && Number.isInteger(option.value)
            ? option.value
            : option.text
        )
      }
    }

    function toggleModal(subOptions) {
      modalSubOptions.value = subOptions
      showModal()
    }

    function onClick(option) {
      checkNavNewLabel(option.newLabel?.value)
      if (option.subOptions && (!medScreen.value || IS_IOS_TOUCH_DEVICE)) {
        toggleModal(option.subOptions)
      } else if (!option.to) {
        updateTab(option)
      }
    }

    if (!noScroll.value) {
      watchEffect(() => {
        if (target.value && options.value?.length) {
          for (let i in options.value) {
            if (isActive(i)) {
              target.value.children[i]?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center',
              })
              return
            }
          }
        }
      })
    }

    return {
      IS_IOS_TOUCH_DEVICE,
      modalSubOptions,
      scrollTarget,
      showNewLabel,
      isModalOpen,
      scrollLeft,
      medScreen,
      linkClass,
      updateTab,
      hideModal,
      isActive,
      PADDING,
      onClick,
      inputId,
      target,
    }
  },
}
</script>
