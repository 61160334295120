import { useMonetization, useResourceValue, useRoute } from '@composables'
import { msToSecs } from '@helpers/utils.js'
import {
  PARTNER_PROGRAM_SUBSCRIPTIONS,
  PARTNER_PROGRAM_VERIFIED,
  PARTNER_PROGRAM_RATINGS,
  PARTNER_PROGRAM_REPORTS,
  PARTNER_PROGRAM_MODS,
  PARTNER_PROGRAM_DMCA,
  AUTH_SCOPE_ACCOUNT,
  MIO_CASHOUT_RATE,
  AUTH_SCOPE_GAME,
  AUTH_SCOPE_MOD,
  CURRENCY_MIO,
  CURRENCY_USD,
} from '@config/monetization.js'

// Dynamic import to avoid circular dependency
let authStore
import('@stores/authStore.js').then((data) => {
  authStore = data.default()
})

export function humanPriceFormat(dollars, maxDigits = 2) {
  let amount = isNaN(dollars) ? 0 : dollars

  return amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: maxDigits,
  })
}

export function convertToWhole(val) {
  return Math.round(val * 100)
}

export function convertToDecimal(val, maxDigits = 2) {
  return parseFloat((val / 100).toFixed(maxDigits))
}

export function formatAmount(amount, currency, meta, earnable) {
  if (isNaN(amount)) return ''

  switch (currency) {
    case CURRENCY_MIO: {
      const tokenName = earnable
        ? useResourceValue().tokenName(true).value
        : meta?.token_name || useResourceValue().tokenName(false).value
      return `${amount.toLocaleString()} ${tokenName}`
    }

    case CURRENCY_USD:
      return humanPriceFormat(convertToDecimal(amount))

    default:
      return amount.toLocaleString()
  }
}

export function mioToUSD(val) {
  const centsAmount = Math.floor(val * MIO_CASHOUT_RATE)
  return convertToDecimal(centsAmount)
}

export function roundDownToNearestWholeDollar(val) {
  return Math.floor(val / 100) * 100
}

export async function checkMonTokenTs(gameEndpoint, modEndpoint) {
  const { authenticate, adminModScopeId } = useMonetization()
  const { getModId } = useRoute()
  const modId = getModId().value

  const scope = modEndpoint
    ? AUTH_SCOPE_MOD
    : gameEndpoint
      ? AUTH_SCOPE_GAME
      : AUTH_SCOPE_ACCOUNT
  const current = msToSecs(Date.now())

  if (authStore.monAccessToken.value?.[scope]) {
    if (
      current < authStore.monAccessToken.value[scope].monetization_expiry &&
      (scope !== AUTH_SCOPE_MOD ||
        (scope === AUTH_SCOPE_MOD && modId === adminModScopeId.value))
    ) {
      // token within expiry date
      return
    }
  }

  if (scope === AUTH_SCOPE_MOD) {
    adminModScopeId.value = modId
    delete authStore.monAccessToken.value?.[scope]
  }

  // token expired
  await authenticate(gameEndpoint, modEndpoint)
}

export function effectStatus(value, flip = false) {
  if (flip) {
    return value ? 'deny' : 'allow'
  } else {
    return value ? 'allow' : 'deny'
  }
}

export function monDateToUTCTimestamp(dateTimeString) {
  const [date, time] = dateTimeString.split(' ')
  const [year, month, day] = date.split('-')
  const [hour, min, secs] = time.split(':')
  return Date.UTC(year, month - 1, day, hour, min, secs)
}

export function getCriteriaExtras(key) {
  switch (key) {
    case 'kyc_verified':
      return {
        label: 'Require KYC verification for Creators',
        bit: PARTNER_PROGRAM_VERIFIED,
        countText: '',
      }

    case 'max_dmca':
      return {
        label: 'Max. DMCA strikes',
        bit: PARTNER_PROGRAM_DMCA,
        countText: 'DMCA strikes',
      }

    case 'max_reports':
      return {
        label: 'Max. number of reports',
        bit: PARTNER_PROGRAM_REPORTS,
        countText: 'reports',
      }

    case 'min_mods':
      return {
        label: `Min. number of ${
          useResourceValue().ugcName(null, false, true).value
        } created`,
        bit: PARTNER_PROGRAM_MODS,
        countText: 'created',
      }

    case 'min_ratings':
      return {
        label: `Min. average ${
          useResourceValue().ugcName(null, true, true).value
        } rating`,
        bit: PARTNER_PROGRAM_RATINGS,
      }

    case 'min_subscriptions':
      return {
        label: 'Min. number of subscribers',
        bit: PARTNER_PROGRAM_SUBSCRIPTIONS,
        countText: 'subscribers',
      }
  }

  return {}
}

export function formatStoreName(store) {
  switch (store) {
    case 'egs':
      return 'Epic Games Store'
    case 'google':
      return 'Google Play'
    case 'nintendo':
      return 'Nintendo eShop'
    case 'psn':
      return 'PlayStation™Store'
    case 'steam':
      return 'Steam'
    case 'tilia':
      return 'Tilia'
    case 'xboxlive':
      return 'Xbox Marketplace'
  }
  return store
}
