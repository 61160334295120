export const PORTAL_STUDIO = 'studio'

// login=false means you can only link this account in your control panel this is important to minimize number of login methods offered to the essential ones
const methods = [
  {
    label: 'Steam',
    icon: ['fab', 'steam-symbol'],
    background: 'tw-bg-steam',
    type: 'steam',
    url: '/steam/login',
    connectUrl: '/steam/connect',
    login: true,
  },
  {
    label: 'Epic Games',
    icon: 'epic_logo',
    baseIcon: true,
    background: 'tw-bg-epicgames',
    type: 'epicgames',
    url: '/epicgames/login',
    connectUrl: '/epicgames/connect',
    login: true,
  },
  {
    label: 'Xbox Live',
    icon: ['fab', 'xbox'],
    background: 'tw-bg-xboxlive',
    type: 'xboxlive',
    url: '/xboxlive/login',
    connectUrl: '/xboxlive/connect',
    login: true,
  },
  {
    label: 'PlayStation™Network',
    icon: ['fab', 'playstation'],
    background: 'tw-bg-playstation',
    type: 'psn',
    url: '/psn/login',
    connectUrl: '/psn/connect',
    login: true,
  },
  {
    label: 'Email',
    icon: 'envelope',
    background: 'tw-bg-email',
    type: 'email',
    url: '',
    login: true,
  },
  {
    label: 'Google',
    icon: ['fab', 'google'],
    background: 'tw-bg-google',
    type: 'google',
    url: '/google/login',
    connectUrl: '/google/connect',
    login: true,
  },
  {
    label: 'Discord',
    icon: ['fab', 'discord'],
    background: 'tw-bg-discord',
    type: 'discord',
    url: '/discord/login',
    connectUrl: '/discord/connect',
    login: true,
  },
  {
    label: 'Itch.io',
    icon: ['fab', 'itch-io'],
    background: 'tw-bg-itchio',
    type: 'itchio',
    url: '/itchio/login',
    connectUrl: '/itchio/connect',
    login: false,
  },
  {
    label: 'Studio',
    icon: 'gamepad',
    background: 'tw-bg-email',
    type: PORTAL_STUDIO,
    url: '/studio/login',
    connectUrl: '/studio/connect',
    login: false,
  },
  {
    label: 'Nebula',
    icon: ['fab', 'nebula-symbol'],
    background: 'tw-bg-email',
    type: 'nebula',
    url: '/nebula/login',
    connectUrl: '/nebula/connect',
    login: false,
  },
  {
    label: 'Switch',
    icon: 'switch',
    baseIcon: true,
    background: 'tw-bg-email',
    type: 'switch',
    url: '/switch/login',
    connectUrl: '/switch/connect',
    login: false,
  },
  {
    label: 'Apple',
    icon: 'apple_logo',
    baseIcon: true,
    background: 'tw-bg-email',
    type: 'apple',
    url: '/apple/login',
    connectUrl: '/apple/connect',
    login: false,
  },
  {
    label: 'GOG',
    icon: 'gog_logo',
    baseIcon: true,
    background: 'tw-bg-email',
    type: 'gog',
    url: '/gog/login',
    connectUrl: '/gog/connect',
    login: false,
  },
  {
    label: 'Oculus Rift',
    icon: 'meta_logo',
    baseIcon: true,
    background: 'tw-bg-email',
    type: 'oculusrift',
    url: '/oculusrift/login',
    connectUrl: '/oculusrift/connect',
    login: false,
  },
  {
    label: 'Meta Quest',
    icon: 'meta_logo',
    baseIcon: true,
    background: 'tw-bg-email',
    type: 'oculusquest',
    url: '/oculusquest/login',
    connectUrl: '/oculusquest/connect',
    login: false,
  },
]

export const LOGIN_METHODS = methods.filter((method) => method.login)
export const ALL_LOGIN_METHODS = methods
