import { createClient } from '@services'
import qs from 'qs'

const { get, put } = createClient()

/* API request to get game theme */
export async function getGameThemeRequest(gameNameId) {
  const { data } = await get(`/games/@${gameNameId}/theme`)

  return data
}

/* API request to set game theme */
export async function setGameThemeRequest({ gameNameId, theme }) {
  const { data } = await put(`/games/@${gameNameId}/theme`, qs.stringify(theme))

  return data
}
