import { createClient } from '@services'
import qs from 'qs'

const { put } = createClient()

export async function bulkGameTagsRequest(gameNameId, tagOptions) {
  const tag_options = tagOptions.map((option) => ({
    ...option,
    locked: option.locked ? 1 : 0,
    hidden: option.hidden ? 1 : 0,
  }))

  const { data } = await put(
    `/games/@${gameNameId}/tags`,
    qs.stringify({ tag_options })
  )
  return data
}

export async function renameGameTagRequest(gameNameId, from, to) {
  const { data } = await put(
    `/games/@${gameNameId}/tags/rename`,
    qs.stringify({ from, to })
  )

  return data
}
