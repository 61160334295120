<template>
  <div v-bind="$attrs" class="tw-w-full">
    <div class="tw-w-full tw-flex tw-justify-between tw-space-x-3">
      <div class="tw-flex tw-grow">
        <slot name="search" />
      </div>
      <div class="empty:tw-hidden tw-flex md:tw-hidden">
        <slot name="filters" />
      </div>
      <div class="tw-flex md:tw-hidden">
        <slot name="sort" />
      </div>

      <div class="tw-hidden md:tw-flex tw-space-x-3 tw-items-center">
        <div class="tw-flex tw-space-x-4">
          <slot name="filters" />
        </div>

        <div class="tw-flex tw-space-x-3">
          <slot name="sort" />
        </div>
      </div>
    </div>

    <div class="tw-flex">
      <div>
        <slot name="totals" />
      </div>
      <div class="tw-flex-1">
        <slot name="tags" />
      </div>
    </div>
  </div>
</template>
