<template>
  <tooltip-hover position="bottom left" :text="srOnly" class="tw-capitalize">
    <base-button
      xsmall
      text-link-hover
      :icon="icon"
      icon-size="lg"
      :sr-only="srOnly"
    />
  </tooltip-hover>
</template>

<script>
import TooltipHover from '@components/Message/TooltipHover.vue'
export default {
  components: {
    TooltipHover,
  },
  props: {
    icon: {
      type: String,
      default: '',
    },
    srOnly: {
      type: String,
      default: '',
    },
  },
}
</script>
