<template>
  <div>
    <div v-if="tip || label" class="tw-flex tw-label-gap">
      <base-input-label
        :id-for="inputId"
        :label="label"
        :tip="tip"
        :tip-hover="tipHover"
        :label-between="labelBetween"
      />
    </div>
    <div
      class="tw-input--min-height tw-w-full tw-global--border-radius tw-flex tw-justify-center tw-items-center tw-input--text-size tw-px-2"
      :class="{
        'tw-bg-theme-1 tw-input--px': background,
        'tw-input--focus-within': !disabled && !inputLoading,
        'tw-border-2 tw-border-transparent': disabled && !inputLoading,
      }"
    >
      <font-awesome-icon
        v-if="icon"
        :icon="icon"
        class="tw-input--text-size tw-mr-2 tw-opacity-30 tw-flex"
      />

      <div
        v-if="$slots.prepend"
        class="tw-flex tw-whitespace-nowrap tw-pr-2 tw-relative"
        :class="{ 'tw-pt-4': multi }"
      >
        <slot name="prepend" :value="modelValue" />
      </div>

      <div
        class="tw-flex-1"
        :class="{
          'tw-opacity-60 dark:tw-opacity-50 ': disabled || inputLoading,
        }"
      >
        <div
          v-if="multiStops?.length"
          class="tw-w-full tw-flex tw-justify-between tw-pt-1"
        >
          <div
            v-for="stop in multiStops"
            :key="stop"
            class="tw-flex tw-items-center tw-justify-center tw-w-5 tw-h-4 tw-relative tw-opacity-60 tw-text-sm"
          >
            <div>
              {{ stop }}
              <div
                class="tw-absolute tw-top-4 tw-h-1.5 tw-w-px tw-bg-theme-text tw-transform tw--translate-x-1/2 tw-left-1/2"
              />
            </div>
          </div>
        </div>
        <div class="tw-relative tw-h-10 tw-flex tw-items-center">
          <div
            class="tw-relative tw-w-full tw-h-1 tw-rounded tw-bg-theme-2 tw-overflow-hidden"
          >
            <div
              class="tw-absolute tw-inset-y-0 tw-bg-primary"
              :style="trackStyle"
            />
          </div>
          <input
            :value="multi ? modelValue[0] : modelValue"
            :step="step"
            type="range"
            :min="min"
            :class="[inputClasses, { single: !multi }]"
            :max="max"
            :disabled="disabled || inputLoading || readonly"
            @input="onUpdate(parseInt($event.target.value), 'min', $event)"
          />
          <input
            v-if="multi"
            :value="multi ? modelValue[1] : modelValue"
            :step="step"
            type="range"
            :min="min"
            :class="inputClasses"
            :max="max"
            :disabled="disabled || inputLoading || readonly"
            @input="onUpdate(parseInt($event.target.value), 'max')"
          />
        </div>
      </div>

      <div
        v-if="$slots.append"
        class="tw-flex tw-whitespace-nowrap tw-pl-2 tw-text-right tw-relative"
        :class="{ 'tw-pt-4': multi }"
      >
        <slot name="append" :value="modelValue" />
      </div>
    </div>
  </div>
</template>

<script>
import { genHtmlId, clamp } from '@helpers/utils.js'
import { computed, toRefs, inject } from 'vue'

const inputClasses =
  'tw-absolute tw-inset-0 tw-top-1/2 tw-transform tw--translate-y-1/2 tw-pointer-events-none tw-border-none tw-outline-none'

export default {
  props: {
    modelValue: {
      type: [Number, String, Array],
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    softMax: {
      type: Number,
      default: null,
    },
    softMin: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: 100,
    },
    min: {
      type: Number,
      default: 0,
    },
    step: {
      type: Number,
      default: 1,
    },
    label: {
      type: String,
      default: '',
    },
    tip: {
      type: String,
      default: '',
    },
    tipHover: {
      type: Boolean,
      default: false,
    },
    labelBetween: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multi: {
      type: Boolean,
      default: false,
    },
    multiStops: {
      type: Array,
      default: () => [],
    },
    background: {
      type: Boolean,
      default: true,
    },
    inputLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const { multi, modelValue, softMax, softMin, max, min, step } =
      toRefs(props)
    const inputId = genHtmlId()
    const readonly = inject('saving', false)

    const trackStyle = computed(() => {
      const range = max.value - min.value
      const val = clamp(modelValue.value, min.value, max.value)

      if (!multi.value)
        return {
          left: '0px',
          width: `${((val - min.value) / range) * 100}%`,
        }

      return {
        left: (modelValue.value[0] / range) * 100 + '%',
        width:
          ((modelValue.value[1] - modelValue.value[0]) / range) * 100 + '%',
      }
    })

    function onUpdate(val, sliderType, event) {
      if (multi.value) {
        const [_min, _max] = modelValue.value
        if (sliderType === 'min')
          emit('input', [Math.min(val, _max - step.value), _max])
        else if (sliderType === 'max')
          emit('input', [_min, Math.max(val, _min + step.value)])
      } else if (softMax.value || softMin.value) {
        _checkThreshold(val, event)
      } else {
        emit('input', val)
      }
    }

    function _checkThreshold(val, event) {
      if (val <= softMin.value) {
        emit('input', softMin.value)
        event.target.value = softMin.value
      } else if (val >= softMax.value) {
        emit('input', softMax.value)
        event.target.value = softMax.value
      } else {
        emit('input', val)
      }
    }

    return {
      trackStyle,
      inputClasses,
      onUpdate,
      readonly,
      inputId,
    }
  },
}
</script>

<style lang="postcss">
[type='range'] {
  appearance: none;
  background: transparent;
  margin: 0;
  width: 100%;
}
[type='range']::-moz-focus-outer {
  border: 0;
}
[type='range']:focus {
  outline: 0;
}
[type='range']::-webkit-slider-runnable-track {
  color: transparent;
  cursor: default;
  height: 8px;
  width: 100%;
}
[type='range']::-moz-range-track {
  cursor: default;
  height: 8px;
  width: 100%;
  color: transparent;
}
[type='range']::-ms-track {
  cursor: default;
  height: 8px;
  width: 100%;
  color: transparent;
}
[type='range']::-ms-fill-lower {
  @apply tw-bg-primary;
  border-radius: 10px;
}
[type='range']::-ms-fill-upper {
  @apply tw-bg-light-2 dark:tw-bg-dark-2;
  border-radius: 10px;
}

.single[type='range'] {
  pointer-events: all;
}

/* Thumbs */
[type='range']::-ms-thumb {
  @apply tw-bg-primary;
  pointer-events: all;
  border-radius: 12px;
  border: 0px;
  box-sizing: border-box;
  cursor: default;
  height: 20px;
  width: 20px;
  margin-top: 2px;
}
[type='range']::-webkit-slider-thumb {
  @apply tw-bg-primary;
  pointer-events: all;
  border-radius: 12px;
  border: 0px;
  box-sizing: border-box;
  cursor: default;
  height: 20px;
  width: 20px;
  -webkit-appearance: none;
  margin-top: -5px;
}
[type='range']::-moz-range-thumb {
  @apply tw-bg-primary;
  pointer-events: all;
  border-radius: 12px;
  border: 0px;
  box-sizing: border-box;
  cursor: default;
  height: 20px;
  width: 20px;
}

[type='range']:disabled::-webkit-slider-thumb,
[type='range']:disabled::-moz-range-thumb,
[type='range']:disabled::-ms-thumb,
[type='range']:disabled::-webkit-slider-runnable-track,
[type='range']:disabled::-ms-fill-lower,
[type='range']:disabled::-ms-fill-upper {
  cursor: not-allowed;
}
</style>
