<template>
  <loading-button
    v-if="inputLoading"
    :small="small"
    :large="!small && !xsmall"
    :full-width="fullWidth"
    :input-btn="iconBtn"
    :full-width-at="fullWidthAt"
  />

  <button
    v-else
    :id="inputId"
    ref="target"
    :data-testid="UI_TEST_ENVS.includes(MODIO_ENV) ? testId : ''"
    :type="type"
    :disabled="isDisabled"
    v-bind="$attrs"
    :class="[
      buttonTopLevelStyles,
      buttonTextColor,
      buttonTextSize,
      buttonRadius,
      buttonCursor,
      buttonHeight,
      buttonWidth,
      buttonBg,
      {
        'tw-px-3': autoWidth,
        'tw-opacity-40': disabled,
        'tw-border-2': !isTextLinkItem,
      },
    ]"
    :tabindex="disabled ? '-1' : '0'"
    @click="onClick"
  >
    <!-- loader -->
    <base-progress-circle v-if="showProgressIndicator" :percent="progress" />
    <base-loading
      v-else
      v-show="hasStatus"
      :class="{
        'tw-relative tw--mr-6.5 tw-left-[calc(50%-0.5625rem)]':
          $slots.default && autoWidth,
      }"
      :status="status || thisStatus"
    />

    <!-- icon -->
    <template v-if="icon && !hasStatus">
      <base-icon v-if="baseIcon" :icon="icon" class="tw-w-6 tw-h-6" />
      <font-awesome-icon
        v-else
        :class="iconColor"
        :icon="icon"
        :size="iconSize"
        fixed-width
      />
    </template>

    <!-- slot data -->
    <span
      v-if="$slots.default"
      class="tw-transform tw-transition-transform"
      :class="[
        textAnimation,
        {
          'tw-w-full': slotFullWidth,
          'tw-opacity-0': hasStatus && autoWidth,
          'tw-hidden': hasStatus && !autoWidth,
        },
      ]"
    >
      <slot />
    </span>

    <!-- screen reader text -->
    <span v-if="srOnly" class="sr-only">{{ srOnly }}</span>
  </button>
</template>

<script>
import BaseProgressCircle from '@components/Misc/BaseProgressCircle.vue'
import { computed, toRefs, inject, ref, unref, onMounted } from 'vue'
import LoadingButton from '@components/Loading/LoadingButton.vue'
import { genHtmlId, isObjEmpty } from '@helpers/utils.js'
import { MODIO_ENV, UI_TEST_ENVS } from '@config'
import { useStatus } from '@composables'

export default {
  components: {
    BaseProgressCircle,
    LoadingButton,
  },
  props: {
    textLink: {
      type: Boolean,
      default: false,
    },
    textLinkHover: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    danger: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: Boolean,
      default: false,
    },
    hollow: {
      type: Boolean,
      default: false,
    },
    noBg: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    xsmall: {
      type: Boolean,
      default: false,
    },
    textSize: {
      type: String,
      default: null,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    slotFullWidth: {
      type: Boolean,
      default: false,
    },
    autoWidth: {
      type: Boolean,
      default: false,
    },
    autoHeight: {
      type: Boolean,
      default: false,
    },
    fullWidthAt: {
      type: String,
      default: '',
      validator: (value) => ['xl', 'lg', 'md', 'sm', 'xs', ''].includes(value),
    },
    radius: {
      type: String,
      default: 'default',
      validator: (value) =>
        ['full', 'bottom', 'none', 'left', 'right', 'default'].includes(value),
    },
    icon: {
      type: [String, Array],
      default: null,
    },
    iconSize: {
      type: String,
      default: null,
    },
    baseIcon: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    status: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
    },
    id: {
      type: String,
      default: null,
    },
    srOnly: {
      type: String,
      default: '',
    },
    inputLoading: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Number,
      default: 0,
    },
    iconColor: {
      type: String,
      default: 'tw-fill-current',
    },
    darkBackground: {
      type: Boolean,
      default: false,
    },
    focus: {
      type: Boolean,
      default: false,
    },
    testId: {
      type: String,
      default: '',
    },
  },
  setup(props, { slots }) {
    const {
      darkBackground,
      textLinkHover,
      fullWidthAt,
      fullWidth,
      autoWidth,
      autoHeight,
      secondary,
      progress,
      disabled,
      textSize,
      textLink,
      warning,
      success,
      primary,
      errors,
      hollow,
      radius,
      status,
      danger,
      xsmall,
      theme,
      focus,
      small,
      info,
      noBg,
      icon,
      id,
    } = toRefs(props)

    const target = ref(null)
    const inputId = id.value || genHtmlId()
    const readonly = inject('saving', false)
    const {
      watchStatusAnimation,
      status: thisStatus,
      updateStatus,
      statusType,
    } = useStatus()

    const { isLoading, isSuccess, isError } = watchStatusAnimation(status)

    const isTextLinkItem = computed(() => textLink.value || textLinkHover.value)

    const iconBtn = computed(() => icon.value && !slots.default)

    const isDisabled = computed(
      () =>
        status.value === statusType.LOADING || disabled.value || unref(readonly)
    )

    const hasStatus = computed(
      () =>
        isLoading.value || isSuccess.value || isError.value || thisStatus.value
    )

    const showProgressIndicator = computed(
      () => isLoading.value && progress.value && progress.value < 1
    )

    const buttonTopLevelStyles = `tw-flex tw-items-center tw-justify-center tw-overflow-hidden tw-button-transition tw-outline-none tw-shrink-0 tw-space-x-2 tw-font-bold`

    const buttonWidth = computed(() => {
      if (fullWidth.value) {
        return 'tw-w-full'
      } else if (autoWidth.value || (isTextLinkItem.value && !iconBtn.value)) {
        return ''
      } else if (iconBtn.value) {
        if (small.value) {
          return 'tw-input--width-small'
        } else if (xsmall.value) {
          return 'tw-input--width-xsmall'
        }
        return isTextLinkItem.value
          ? 'tw-input--width-small'
          : 'tw-input--width-large'
      } else if (['xl', 'lg', 'md', 'sm', 'xs'].includes(fullWidthAt.value)) {
        return `tw-w-full ${fullWidthAt.value}:tw-w-36`
      }

      return 'tw-w-36'
    })

    const buttonHeight = computed(() => {
      if (autoHeight.value || (isTextLinkItem.value && !iconBtn.value)) {
        return ''
      } else if (small.value) {
        return 'tw-input--height-small'
      } else if (xsmall.value) {
        return 'tw-input--height-xsmall'
      }

      return isTextLinkItem.value
        ? 'tw-input--height-small'
        : 'tw-input--height-large'
    })

    const buttonTextSize = computed(() => {
      if (textSize.value) {
        return textSize.value
      } else if (small.value || xsmall.value) {
        return 'tw-text-sm tw-leading-snug'
      } else if (iconBtn.value) {
        return 'tw-text-sm'
      }

      return 'tw-text-md tw-leading-normal'
    })

    const buttonCursor = computed(() => {
      if (hasStatus.value) {
        return 'tw-cursor-default'
      } else if (isDisabled.value) {
        return 'tw-cursor-not-allowed'
      }

      return 'tw-cursor-pointer'
    })

    const propToText = computed(() => {
      if (primary.value) return 'primary'
      if (info.value) return 'info'
      if (success.value) return 'success'
      if (warning.value) return 'warning'
      if (danger.value) return 'danger'
      if (theme.value) return 'theme'

      return null
    })

    const buttonBg = computed(() => {
      if (isTextLinkItem.value) {
        return ''
      } else if (noBg.value) {
        return 'tw-border-transparent'
      } else if (hollow.value) {
        const classes = [
          darkBackground.value
            ? 'tw-bg-theme-3 hover:tw-bg-theme focus:tw-bg-theme'
            : secondary.value
              ? 'tw-bg-theme-2 hover:tw-bg-theme-1 focus:tw-bg-theme-1'
              : 'hover:tw-bg-theme-2 focus:tw-bg-theme-2',
        ]
        if (propToText.value) {
          classes.push(`tw-border-${propToText.value}`)
        }
        return classes
      } else if (propToText.value) {
        const text = propToText.value
        return _checkStatus(
          `tw-bg-${text} tw-text-${text}-text tw-border-${text} hover:tw-bg-${text}-hover focus:tw-bg-${text}-hover hover:tw-border-${text}-hover focus:tw-border-${text}-hover`
        )
      } else if (secondary.value) {
        return _checkStatus(
          'tw-bg-theme-1 tw-text-theme tw-border-theme-1 hover:tw-bg-theme-2 focus:tw-bg-theme-1 hover:tw-border-theme-2 focus:tw-border-theme-2'
        )
      } else if (darkBackground.value) {
        return _checkStatus(
          'tw-bg-theme-3 tw-text-theme tw-border-theme-3 hover:tw-bg-theme focus:tw-bg-theme hover:tw-border-theme focus:tw-border-theme'
        )
      }

      return _checkStatus(
        'tw-bg-theme-1 tw-text-theme tw-border-theme-1 hover:tw-bg-theme-2 focus:tw-bg-theme-2 hover:tw-border-theme-2 focus:tw-border-theme-2'
      )
    })

    const buttonTextColor = computed(() => {
      const text = propToText.value || 'primary'
      if (textLink.value) {
        const hoverProps = `hover:tw-text-${text}-hover focus:tw-text-${text}-hover`
        return `tw-text-${text} ${!isDisabled.value ? hoverProps : ''}`
      } else if (textLinkHover.value && !isDisabled.value) {
        return `hover:tw-text-${text} focus:tw-text-${text}`
      }

      return ''
    })

    const buttonRadius = computed(() => {
      if (radius.value === 'none' || isTextLinkItem.value) {
        return ''
      }
      switch (radius.value) {
        case 'bottom':
          return 'tw-global--border-radius-b'
        case 'left':
          return 'tw-global--border-radius-l'
        case 'right':
          return 'tw-global--border-radius-r'
        case 'full':
          return 'tw-rounded-full'
        default:
          return 'tw-global--border-radius'
      }
    })

    const textAnimation = computed(() => {
      if (icon.value) return ''

      return hasStatus.value ? 'tw-translate-x-px' : 'tw-translate-x-0'
    })

    onMounted(() => {
      if (focus.value && target.value) {
        target.value.focus()
      }
    })

    function _checkStatus(currentBg) {
      if (hasStatus.value) {
        if (isLoading.value) {
          return currentBg
        } else if (isSuccess.value) {
          return 'tw-bg-success tw-border-success tw-text-success-text'
        } else if (isError.value) {
          return 'tw-bg-danger tw-border-danger tw-text-danger-text'
        }
      }
      return currentBg
    }

    function onClick() {
      if (
        errors.value &&
        !isObjEmpty(errors.value) &&
        Object.values(errors.value).some((errArray) => errArray.length)
      ) {
        updateStatus(statusType.ERROR)
      }
    }

    return {
      showProgressIndicator,
      buttonTopLevelStyles,
      buttonTextColor,
      buttonTextSize,
      isTextLinkItem,
      textAnimation,
      buttonCursor,
      buttonRadius,
      buttonHeight,
      UI_TEST_ENVS,
      buttonWidth,
      isDisabled,
      thisStatus,
      hasStatus,
      MODIO_ENV,
      buttonBg,
      iconBtn,
      inputId,
      onClick,
      target,
    }
  },
}
</script>
