export const DEFAULT_PAYOUT_AMOUNT = 10000
export const MIO_CASHOUT_RATE = 0.4

// onboarding status
export const TILIA_STATUS_UNREGISTERED = 0
export const TILIA_STATUS_PENDING = 1
export const TILIA_STATUS_REJECTED = 2
export const TILIA_STATUS_REVIEW = 4
export const TILIA_STATUS_ACTION = 8
export const TILIA_STATUS_APPROVED = 16
export const TILIA_STATUS_MEMBER = 32

// monetization status
export const MONETIZATION_DISABLED = 0
export const MONETIZATION_ENABLED = 1
export const MONETIZATION_MARKETPLACE = 2
export const MONETIZATION_PARTNER_PROGRAM = 4
export const MONETIZATION_SCARCITY = 8
export const MONETIZATION_FORCED_SCARCITY = 16
export const MONETIZATION_FILELESS = 64

// paid mods status
export const MOD_FREE = 0
export const MOD_PAID = 1
export const MOD_FREE_AND_PAID = 2

// tilia
export const TILIA_STATE_CANCEL = 'cancel'
export const TILIA_STATE_ERROR = 'error'
export const TILIA_TYPE_KYC = 'kyc'
export const TILIA_TYPE_KYB = 'kyb'
export const TILIA_TYPE_PAYMENT = 'payment-selection'
export const TILIA_TYPE_PAYOUT = 'payout'

// polling
export const MON_POLL_INTERVAL_SECONDS = 8

// wallet type
export const WALLET_CONVERTIBLE = 'convertible'
export const WALLET_CONVERTIBLE_HOLDING = 'convertible_holding'
export const WALLET_DEFAULT_HOLDING = 'default_holding'
export const WALLET_DEFAULT_USD = 'default_usd'
export const WALLET_FEE = 'fee'
export const WALLET_STANDARD_MIO = 'standard_mio'

// breakdown types
export const BREAKDOWN_SELLER = 'seller'
export const BREAKDOWN_STORE = 'store'
export const BREAKDOWN_PLATFORM = 'platform'

// transaction status
export const TRANSACTION_PAID = 'paid'
export const TRANSACTION_PENDING = 'pending'
export const TRANSACTION_CANCELLED = 'cancelled'
export const TRANSACTION_FAILED = 'failed'
export const TRANSACTION_CLEARED = 'cleared'
export const TRANSACTION_REFUNDED = 'refunded'
export const TRANSACTION_CREDITED = 'credited'

// item status
export const ITEM_BOUGHT = 'bought'
export const ITEM_SOLD = 'sold'

// currency type
export const CURRENCY_MIO = 'mio'
export const CURRENCY_USD = 'usd'

// sale type
export const SALE_TYPE_FIAT = 'fiat'
export const SALE_TYPE_TOKEN = 'token'
export const SALE_TYPE_EXTERNAL = 'external'

// refund sale type
export const REFUND_TYPE_SKU = 0
export const REFUND_TYPE_UGC = 1

export const SPLIT_MOD_ID = 'mod_cut'
export const SPLIT_GAME_ID = 'game_cut'
export const SPLIT_MODIO_ID = 'platform_cut'

export const MIN_MODIO_CUT = 0
export const MAX_MODIO_CUT = 100
export const MIN_GAME_MOD_CUT = 0
export const MAX_GAME_MOD_CUT = 100

export const DEFAULT_GATEWAY_FEE_PERCENTAGE = 7
export const DEFAULT_PROCESSING_FEE_CHARGE = 20

export const MAX_REVENUE_SHARE_MEMBERS = 20

export const MIN_REVENUE_SHARE_AMOUNT = 1

export const GAME_MOD_ONBOARDING_PENDING = 'pending'
export const GAME_MOD_ONBOARDING_CREATED = 'created'
export const GAME_MOD_ONBOARDING_ONBOARDED = 'onboarded'
export const GAME_MOD_ONBOARDING_COMPLETE = 'completed'

// sections
export const SECTION_MARKETPLACE = 'marketplace'

export const REFUND_REASON_OPTIONS = [
  { value: 'Accidental purchase', text: 'Accidental purchase' },
  { value: 'Billing error', text: 'Billing error' },
  { value: "Content doesn't work", text: "Content doesn't work" },
  { value: "I didn't make the purchase", text: "I didn't make the purchase" },
]

export const PARTNER_PROGRAM_VERIFIED = 1
export const PARTNER_PROGRAM_SUBSCRIPTIONS = 2
export const PARTNER_PROGRAM_MODS = 4
export const PARTNER_PROGRAM_RATINGS = 8
export const PARTNER_PROGRAM_DMCA = 16
export const PARTNER_PROGRAM_REPORTS = 32

export const PARTNER_PROGRAM_APPLICATION_PENDING = 0
export const PARTNER_PROGRAM_APPLICATION_SUCCESS = 1
export const PARTNER_PROGRAM_APPLICATION_REJECTED = 2

export const PARTNER_PROGRAM_TEXT = {
  [PARTNER_PROGRAM_APPLICATION_PENDING]: 'Pending review',
  [PARTNER_PROGRAM_APPLICATION_SUCCESS]: 'Approved',
  [PARTNER_PROGRAM_APPLICATION_REJECTED]: 'Rejected',
}

export const PARTNER_PROGRAM_CRITERIA_DEFAULTS = {
  kyc_verified: false,
  max_dmca: 10,
  max_reports: 50,
  min_mods: 1,
  min_ratings: 50,
  min_subscriptions: 10,
}

export const MARKETPLACE_QUEUE_STATUS_PENDING = 0
export const MARKETPLACE_QUEUE_STATUS_SUCCESS = 1
export const MARKETPLACE_QUEUE_STATUS_REJECTED = 2

// monetization authentication token types
export const AUTH_SCOPE_ACCOUNT = 'me'
export const AUTH_SCOPE_GAME = 'game'
export const AUTH_SCOPE_MOD = 'mod'
