export const GUIDE_SORT_OPTIONS = [
  {
    name: 'Name',
    key: 'name',
    direction: 'asc',
  },
  {
    name: 'Comments',
    key: 'stats.comments_total',
    direction: 'desc',
  },
  {
    name: 'Views',
    key: 'stats.visits_total',
    direction: 'desc',
  },
  {
    name: 'Date added',
    key: 'date_live',
    direction: 'desc',
    default: true,
  },
  {
    name: 'Date updated',
    key: 'date_updated',
    direction: 'desc',
  },
]

export const GAME_SORT_OPTIONS = [
  {
    name: 'Trending',
    key: 'popular',
    alias: 'stats.mods_downloads_today',
    direction: 'asc',
    default: true,
    reverse: true,
  },
  {
    name: 'Most popular',
    key: 'downloads',
    alias: 'stats.mods_downloads_total',
    direction: 'asc',
    reverse: true,
  },
  {
    name: 'Total mods',
    key: 'mods',
    alias: 'stats.mods_count_total',
    direction: 'asc',
    reverse: true,
  },
  {
    name: 'Recently added',
    key: 'date_live',
    direction: 'desc',
  },
  {
    name: 'Last updated',
    key: 'date_updated',
    direction: 'desc',
  },
  {
    name: 'Alphabetical',
    key: 'name',
    direction: 'asc',
  },
]

export const MY_GAME_SORT_OPTIONS = [
  {
    name: 'Trending',
    key: 'popular',
    alias: 'resource_stats.mods_downloads_today',
    direction: 'asc',
    default: true,
    reverse: true,
  },
  {
    name: 'Most popular',
    key: 'downloads',
    alias: 'resource_stats.mods_downloads_total',
    direction: 'asc',
    reverse: true,
  },
  {
    name: 'Total mods',
    key: 'mods',
    alias: 'resource_stats.mods_count_total',
    direction: 'asc',
    reverse: true,
  },
  {
    name: 'Recently added',
    key: 'date_live',
    alias: 'access.date_added',
    direction: 'desc',
  },
  {
    name: 'Alphabetical',
    key: 'name',
    direction: 'asc',
  },
]

export const MOD_SORT_OPTIONS = [
  {
    name: 'Trending',
    key: 'popular',
    alias: 'stats.downloads_today',
    direction: 'desc',
    default: true,
  },
  {
    name: 'Highest rated',
    key: 'rating',
    alias: 'stats.ratings_weighted_aggregate',
    reverse: true,
  },
  {
    name: 'Most popular',
    key: 'downloads',
    alias: 'stats.downloads_total',
    reverse: true,
  },
  {
    name: 'Most subscribers',
    key: 'subscribers',
    alias: 'stats.subscribers_total',
    reverse: true,
  },
  {
    name: 'Recently added',
    key: 'date_live',
    direction: 'desc',
  },
  {
    name: 'Last updated',
    key: 'date_updated',
    direction: 'desc',
  },
  {
    name: 'Alphabetical',
    key: 'name',
    direction: 'asc',
  },
]
