<template>
  <conversation-container>
    <template v-if="header" #header>
      <div v-if="!loading" class="tw-flex tw-w-full tw-fill-current">
        <div class="tw-flex tw-w-48 tw-items-center tw-justify-start">
          <div
            v-if="threadOtherUsers.length > 1"
            class="tw-inline-block tw-absolute tw-mb-3 tw-cursor-pointer"
            @click="showChatMembers"
          >
            <avatar-icon
              show-status
              inherit-size
              class="tw-border-2 tw-border-theme tw-top-4 tw-flex tw-shrink-0 tw-w-8 tw-h-8 tw-rounded-full tw-object-cover tw-object-center tw-overflow-hidden tw-self-start tw-mt tw-mr-2 tw-ml-3"
              :user="threadOtherUsers[0]"
            />
            <avatar-icon
              show-status
              inherit-size
              class="tw-border-2 tw-border-theme tw-absolute tw--top-1 tw-flex tw-shrink-0 tw-w-8 tw-h-8 tw-rounded-full tw-object-cover tw-object-center tw-overflow-hidden tw-self-start tw-mt tw-mr-2"
              :user="threadOtherUsers[1]"
            />
          </div>
          <div v-else class="tw-inline-block tw-top-3">
            <tooltip-hover
              :outside-main="!mdAndUp"
              :position="mdAndUp ? '' : 'right'"
              :text="badge.description ? `Admin of ${badge.description}` : null"
            >
              <avatar-icon
                class="tw-flex tw-shrink-0 tw-w-10 tw-h-10 tw-rounded-full tw-object-cover tw-object-center tw-overflow-hidden tw-self-start tw-mt tw-mr-2 tw-absolute"
                show-status
                inherit-size
                :user="threadOtherUsers[0]"
              />
              <base-icon
                v-if="badge.name"
                class="tw-absolute tw-w-5 tw-top-5 tw-right-2"
                :icon="badge.name"
              />
            </tooltip-hover>
          </div>
          <span
            class="tw-font-bold tw-text-h6 tw-leading-tight tw-util-truncate-two-lines tw-text-theme"
            :class="{
              'tw-util-truncate-one-line tw-ml-12': threadOtherUsers.length > 1,
            }"
            style="word-break: break-all"
          >
            {{ threadUsersText }}
          </span>
        </div>
        <div class="tw-flex tw-items-center tw-justify-end tw-ml-auto">
          <conversation-extra-options-button
            :participants="participants"
            :other-user-name-id="threadOtherUsers[0]?.name_id"
            @trigger:report="triggerReportModal"
          />
          <conversation-enlarge-button class="tw-hidden md:tw-block" />
          <conversation-header-button icon="times" @click="close" />
        </div>
      </div>
    </template>
    <template #body>
      <div v-if="!loading" class="tw-flex tw-flex-col tw-justify-end tw-h-full">
        <conversation-list-form :thread-messages="threadMessages" />
        <send-message-input
          v-if="threadOtherUsers.length > 0"
          :thread-id="threadId"
          :thread-users="[]"
        />
      </div>
      <loading-chat-feed v-else />
    </template>
  </conversation-container>
</template>

<script>
import ConversationExtraOptionsButton from '@components/Conversation/ConversationExtraOptionsButton.vue'
import ConversationEnlargeButton from '@components/Conversation/ConversationEnlargeButton.vue'
import ConversationHeaderButton from '@components/Conversation/ConversationHeaderButton.vue'
import ConversationContainer from '@components/Conversation/ConversationContainer.vue'
import ConversationListForm from '@components/Conversation/ConversationListForm.vue'
import { useMessagePoll, useMessenger, useThread, useAsync } from '@composables'
import SendMessageInput from '@components/Conversation/SendMessageInput.vue'
import LoadingChatFeed from '@components/Loading/LoadingChatFeed.vue'
import { RESOURCE_USERS, RESOURCE_GAMES } from '@config/options.js'
import TooltipHover from '@components/Message/TooltipHover.vue'
import AvatarIcon from '@components/Users/AvatarIcon.vue'
import { setGlobalModal } from '@composables/useModal.js'
import { MODAL_REPORT } from '@config/globalModals.js'
import { getMessageThreadRequest } from '@services'
import { messageStore, authStore } from '@stores'
import { useBreakpoints } from '@vueuse/core'
import { isArray } from '@helpers/utils.js'
import { watch, computed } from 'vue'
import { BREAKPOINTS } from '@config'

export default {
  components: {
    ConversationExtraOptionsButton,
    ConversationEnlargeButton,
    ConversationHeaderButton,
    ConversationContainer,
    ConversationListForm,
    SendMessageInput,
    LoadingChatFeed,
    TooltipHover,
    AvatarIcon,
  },
  props: {
    header: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { greaterOrEqual } = useBreakpoints(BREAKPOINTS)
    const mdAndUp = greaterOrEqual('md')

    const { removeFromUnreadMessage } = useMessagePoll()
    const { threadId } = useThread()
    const userId = authStore().getUserId()
    const {
      CONVERSATION_COMPONENTS,
      expandChatWindow,
      changeComponent,
      toggleExpand,
      showHide,
    } = useMessenger()

    const {
      messages: inbox,
      updateInboxThread,
      getMessageThread,
      addMessageThread,
      getThread,
    } = messageStore()

    const badge = computed(() => {
      const access = threadOtherUsers.value[0]?.access
      const _badge = {}
      _badge.description = access?.map((a) => a.resource_name).join(', ')
      if (access?.find((a) => a.resource_type === RESOURCE_GAMES)) {
        _badge.name = 'badge_gameadmin'
      }

      return _badge
    })

    const { loading, run, error, data } = useAsync(
      () => getMessageThreadRequest(threadId.value),
      'Failed to get message thread'
    )

    const threadMessages = computed(
      () => getMessageThread(threadId.value).value
    )

    const participants = computed(
      () => getThread(threadId.value).value?.participants || []
    )

    const threadOtherUsers = computed(() =>
      participants.value.filter((p) => p.id !== userId)
    )

    const threadUsersText = computed(() =>
      threadOtherUsers.value.map((p) => p.username).join(', ')
    )

    function updateInboxWithLatestMessage(messages) {
      const latestInboxMessage = inbox.value.data.find(
        (m) => m.id === threadId.value
      )?.last_message
      if (latestInboxMessage) {
        const latestThreadMessage = messages.slice(-1).pop()
        if (latestInboxMessage.id !== latestThreadMessage.id) {
          updateInboxThread(latestThreadMessage)
        }
      }
    }

    watch(
      threadMessages,
      (value) => {
        if (value && isArray(value) && value.length !== 0) {
          removeFromUnreadMessage(threadId.value)
          updateInboxWithLatestMessage(value)
        } else if (threadId.value) {
          fetchThread()
        }
      },
      {
        immediate: true,
      }
    )

    async function fetchThread() {
      await run()

      if (!error.value) {
        addMessageThread(threadId.value, data.value)
      }
    }

    function showChatMembers() {
      changeComponent(CONVERSATION_COMPONENTS.CONVERSATION_MEMBERS)
    }

    function close() {
      showHide(false)
      // Resets expand bool when closed
      expandChatWindow.value && toggleExpand()
      changeComponent(null)
    }

    function triggerReportModal() {
      setGlobalModal({
        modal: MODAL_REPORT,
        data: {
          resource: RESOURCE_USERS,
          resourceId: threadOtherUsers.value[0]?.id,
        },
      })
    }

    return {
      triggerReportModal,
      threadOtherUsers,
      showChatMembers,
      changeComponent,
      threadUsersText,
      threadMessages,
      participants,
      threadId,
      mdAndUp,
      loading,
      close,
      badge,
    }
  },
}
</script>
