<template>
  <conversation-container>
    <template #header>
      <div class="tw-flex tw-w-full tw-fill-current">
        <div
          class="tw-flex tw-w-56 tw-items-center tw-justify-start tw-space-x-1"
        >
          <span
            class="tw-font-bold tw-text-h6 tw-util-truncate-two-lines dark:tw-text-light-1 dark:tw-fill-current"
          >
            New message
          </span>
        </div>
        <div
          class="tw-flex tw-items-center tw-justify-end tw-ml-auto tw-space-x-1"
        >
          <conversation-enlarge-button class="tw-hidden md:tw-block" />
          <conversation-header-button icon="times" @click="close" />
        </div>
      </div>
    </template>
    <template #body>
      <div class="tw-justify-between tw-h-full tw-flex-col tw-flex">
        <user-look-up
          :recipients="recipients"
          @add:recipient="(user) => addRecipient(user)"
          @remove:recipient="(user) => removeRecipient(user)"
        />

        <div
          v-if="loadingThreads"
          class="tw-h-full tw-flex tw-justify-center tw-items-start tw-py-6"
        >
          <div class="tw-flex tw-items-center tw-space-x-2">
            <base-loading status="loading"></base-loading>
            <span>Loading conversation</span>
          </div>
        </div>
        <template v-else-if="recipientsId.length">
          <conversation-list-form :thread-messages="threadMessages" />
          <send-message-input
            :thread-id="threadId"
            :thread-users="recipientsId"
            @compose:message="(message) => handleNewMessage(message)"
            @new:message="(message) => handleReplyMessage(message)"
          />
        </template>
      </div>
    </template>
  </conversation-container>
</template>

<script>
import ConversationEnlargeButton from '@components/Conversation/ConversationEnlargeButton.vue'
import ConversationHeaderButton from '@components/Conversation/ConversationHeaderButton.vue'
import ConversationContainer from '@components/Conversation/ConversationContainer.vue'
import ConversationListForm from '@components/Conversation/ConversationListForm.vue'
import SendMessageInput from '@components/Conversation/SendMessageInput.vue'
import { useThread, useAsync, useMessenger } from '@composables'
import UserLookUp from '@components/Conversation/UserLookUp.vue'
import { getMessageThreadRequest } from '@services'
import { watchEffect, ref } from 'vue'
import { messageStore } from '@stores'

export default {
  components: {
    ConversationEnlargeButton,
    ConversationHeaderButton,
    ConversationContainer,
    ConversationListForm,
    SendMessageInput,
    UserLookUp,
  },
  setup() {
    const threadMessages = ref([])
    const loadingThreads = ref(false)

    const {
      removeFromRecipients,
      addToRecipients,
      updateThreadId,
      recipientsId,
      recipients,
      threadId,
    } = useThread()
    const { changeComponent, showHide, CONVERSATION_COMPONENTS } =
      useMessenger()

    const {
      run: runGetMessageThread,
      error: getMessageThreadError,
      data: messageThreadData,
    } = useAsync(() => getMessageThreadRequest(threadId.value))

    const { getThreadIdForUsers, addMessageThread, getMessageThread } =
      messageStore()

    watchEffect(
      () => (threadMessages.value = getMessageThread(threadId.value).value)
    )

    function addRecipient(user) {
      addToRecipients(user)
      updateRecipients()
    }

    function removeRecipient(user) {
      removeFromRecipients(user)
      updateRecipients()
    }

    function updateRecipients() {
      threadMessages.value = []

      const threadId = getThreadIdForUsers(recipientsId.value)
      if (threadId) {
        updateThreadId(threadId)

        const thread = getMessageThread(threadId).value
        if (thread.length !== 0) {
          threadMessages.value = getMessageThread(threadId.value).value
        } else {
          fetchThread()
        }
      } else {
        updateThreadId(null)
      }
    }

    function handleNewMessage(message) {
      updateThreadId(message.thread_id)
      changeComponent(CONVERSATION_COMPONENTS.CONVERSATION_THREAD)
    }

    function handleReplyMessage() {
      changeComponent(CONVERSATION_COMPONENTS.CONVERSATION_THREAD)
    }

    async function fetchThread() {
      loadingThreads.value = true
      await runGetMessageThread()

      if (!getMessageThreadError.value) {
        loadingThreads.value = false
        addMessageThread(threadId.value, messageThreadData.value)
      }
    }

    function close() {
      showHide(false)
      changeComponent(null)
    }

    return {
      handleReplyMessage,
      updateRecipients,
      handleNewMessage,
      removeRecipient,
      changeComponent,
      loadingThreads,
      threadMessages,
      updateThreadId,
      addRecipient,
      recipientsId,
      recipients,
      threadId,
      close,
    }
  },
}
</script>
