import { store, myTeamsStore, gameStore, authStore } from '@stores'
import cogsGameSerializer from '@serializers/cogsGameSerializer.js'
import convertToPublicGame from '@serializers/gameConverter.js'
import { useRecentlyViewGames, useTheming } from '@composables'
import { KEYS_COGS_GAME } from '@config/storeKeys.js'
import { NEW_RESOURCE_ID } from '@config'
import { clone } from '@helpers/utils.js'
import { ref } from 'vue'

const { updateStore, getStore, createStore } = store()
const latestAgreement = ref({})

export default function () {
  const { getPublicGame, addPublicGame } = gameStore()
  const { updateGameTeam } = myTeamsStore()
  const { user } = authStore()

  const cogsGame = getStore(KEYS_COGS_GAME)

  function saveCogGame(updateAccess = true) {
    if (!cogsGame.value) return

    if (updateAccess) {
      updateGameTeam(cogsGame.value, user)
    }
    useTheming().updateTheme(cogsGame.value.theme)
    useRecentlyViewGames().updateRecentGameTheme(
      cogsGame.value.name_id,
      cogsGame.value.theme
    )

    const publicGame = getPublicGame(cogsGame.value.name_id)
    addPublicGame(
      convertToPublicGame(
        cogsGame.value,
        publicGame.value?.tag_options,
        publicGame.value?.stats
      )
    )
  }

  function addGame(data, transform = true, updateAccess = true) {
    const game = transform ? cogsGameSerializer.transform(data) : data
    createStore(KEYS_COGS_GAME, game)
    saveCogGame(updateAccess)
  }

  function addNewGame() {
    createStore(
      KEYS_COGS_GAME,
      cogsGameSerializer.getNew({ id: NEW_RESOURCE_ID })
    )
  }

  function updateGameField(input, value) {
    if (!cogsGame.value) return

    const updatedGame = { ...cogsGame.value, [input]: value }
    updateStore(KEYS_COGS_GAME, updatedGame)
  }

  function addGameArrayField(input, value) {
    if (!cogsGame.value) return

    const updatedGame = clone(cogsGame.value)

    updatedGame[input] = [...updatedGame[input], value]
    updateStore(KEYS_COGS_GAME, updatedGame)
  }

  function updateGameObjField(key, input, value) {
    if (!cogsGame.value) return

    const updatedGame = clone(cogsGame.value)

    updatedGame[key][input] = value
    updateStore(KEYS_COGS_GAME, updatedGame)
  }

  function deleteFromGameArrayField(input, cb) {
    if (!cogsGame.value) return

    const updatedGame = clone(cogsGame.value)

    const updatedInput = [...updatedGame[input]]
    updatedGame[input].forEach((data, index) => {
      if (cb(data)) {
        updatedInput.splice(index, 1)
      }
    })

    updatedGame[input] = updatedInput
    updateStore(KEYS_COGS_GAME, updatedGame)
  }

  function clearCogsGame() {
    createStore(KEYS_COGS_GAME, null)
  }

  return {
    deleteFromGameArrayField,
    updateGameObjField,
    addGameArrayField,
    latestAgreement,
    updateGameField,
    clearCogsGame,
    saveCogGame,
    addNewGame,
    cogsGame,
    addGame,
  }
}
