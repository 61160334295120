import { authStore, monetizationStore, gameStore, modStore } from '@stores'
import { asyncSleep, msToSecs, isString, setBits } from '@helpers/utils.js'
import { useAsync, useStatus, useRoute } from '@composables'
import { computed, nextTick, ref, readonly } from 'vue'
import { REPLICATION_DELAY_MS } from '@config'
import {
  monDateToUTCTimestamp,
  formatAmount,
} from '@helpers/monetizationUtils.js'
import {
  TRANSACTION_REFUNDED,
  TILIA_STATUS_MEMBER,
  SALE_TYPE_EXTERNAL,
  AUTH_SCOPE_ACCOUNT,
  AUTH_SCOPE_GAME,
  SALE_TYPE_TOKEN,
  AUTH_SCOPE_MOD,
  SALE_TYPE_FIAT,
  CURRENCY_USD,
  CURRENCY_MIO,
} from '@config/monetization.js'
import {
  createCreatorWalletsRequest,
  getAccountWalletsRequest,
  getAuthenticateRequest,
  getSessionRequest,
  registerRequest,
  getUsersStatus,
} from '@services'

const gamePurchasesFetchedState = ref({})
const minMaxValidationStatus = ref(true)
const manualModerationState = ref(false)
const pendingInvoicesState = ref({})
const loadingGameWallet = ref(false)
const authLoadingState = ref(false)
const kycTermsAccepted = ref(false)
const nonceToken = ref('unset')
const gameWalletState = ref({})
const scarcityState = ref({
  enabled: false,
  force: false,
  amount: 1,
})

const initialScarcity = ref(undefined)

// the mod id a game / site admin is currently authed to
const adminModScopeId = ref(undefined)

export default function () {
  const { getGameId: getGameNumericId } = gameStore()
  const { monetizationAccess } = monetizationStore()
  const { getModId: getModNumericId } = modStore()

  const {
    setUserMonetizationStatus,
    userOnboardingStatus,
    hasMemberWallets,
    isMonRegistered,
    isSuperAdmin,
    setMonToken,
  } = authStore()

  const { getGameId, getModId } = useRoute()

  const nonce = computed(() => nonceToken.value)
  const minMaxValid = computed(() => minMaxValidationStatus.value)
  const globalAuthLoading = computed(() => authLoadingState.value)
  const gamePurchasesFetched = computed(() => gamePurchasesFetchedState.value)
  const gameWallets = computed(() => gameWalletState.value)
  const pendingInvoices = computed(
    () => pendingInvoicesState.value[getGameId().value]
  )

  // user registration
  const {
    loading: loadingRegister,
    error: errorRegister,
    data: registerData,
    run: runRegister,
  } = useAsync(registerRequest)
  async function registerUser() {
    if (isMonRegistered.value) return

    await runRegister()

    if (!errorRegister.value) {
      // Wait for registered to propagate before calling session
      await asyncSleep(REPLICATION_DELAY_MS)
      setUserMonetizationStatus(registerData.value?.monetization_status)
    }
  }

  // get session nonce for widget
  const {
    loading: loadingSession,
    error: sessionError,
    data: sessionData,
    run: runSession,
  } = useAsync(getSessionRequest)
  async function getSession() {
    await runSession()
    if (!sessionError.value) {
      nonceToken.value = sessionData.value.nonce
    }
  }

  // get wallet for game
  const {
    error: errorGameWallet,
    data: dataGameWallet,
    run: runGameWallet,
  } = useAsync(
    (teamId) => getAccountWalletsRequest(false, teamId),
    'Error getting game token balance'
  )

  const {
    loading: loadingCreateWallets,
    error: errorCreateWallets,
    run: runCreateWallets,
  } = useAsync(createCreatorWalletsRequest)

  async function createCreatorWallets() {
    if (hasMemberWallets.value) return

    await runCreateWallets()

    if (!errorCreateWallets.value) {
      setUserMonetizationStatus(
        setBits(userOnboardingStatus.value, TILIA_STATUS_MEMBER)
      )
    }
  }

  // get monetization token for interacting with monetization api
  const {
    loading: loadingAuthenticateMe,
    error: authenticateMeError,
    data: authenticateMeData,
    run: runAuthenticateMe,
  } = useAsync((gameId, modId) => getAuthenticateRequest(gameId, modId))

  async function authenticate(gameEndpoint, modEndpoint) {
    const scope = modEndpoint
      ? AUTH_SCOPE_MOD
      : gameEndpoint
        ? AUTH_SCOPE_GAME
        : AUTH_SCOPE_ACCOUNT

    authLoadingState.value = true

    const gameNameId = getGameId().value
    const modNameId = getModId().value

    const gameId = gameEndpoint ? getGameNumericId(gameNameId) : undefined

    const modId = modEndpoint
      ? getModNumericId(modNameId, gameNameId)
      : undefined

    await runAuthenticateMe(gameId, modId)
    authLoadingState.value = false

    if (!authenticateMeError.value) {
      setMonToken(authenticateMeData.value, scope)
    }
  }

  const authenticateError = computed(() => authenticateMeError.value)
  const loadingAuthenticate = computed(() => loadingAuthenticateMe.value)

  // check onboarding status
  const {
    loading: loadingUserStatus,
    error: userStatusError,
    data: userStatusData,
    run: runUserStatus,
  } = useAsync(getUsersStatus)

  async function getUserStatus() {
    await runUserStatus()

    if (!userStatusError.value) {
      // Next tick so that the emit from OnboardButton can run
      nextTick(() =>
        setUserMonetizationStatus(userStatusData.value?.monetization_status)
      )
    }
  }

  // get session
  const {
    status: sessionStatus,
    updateStatus: updateSessionStatus,
    statusType: sessionStatusType,
    clearStatus: clearSessionStatus,
  } = useStatus({
    loading: loadingSession,
    error: sessionError,
  })

  function unsetNonce() {
    nonceToken.value = 'unset'
  }

  function setMinMaxValidation(status) {
    minMaxValidationStatus.value = status
  }

  function setManualModeration(value) {
    manualModerationState.value = value
  }

  function setScarcity(value) {
    scarcityState.value = value
  }

  function formatAmountText(data, earnable, useGrossAmount) {
    if (data.monetization_type === SALE_TYPE_EXTERNAL && !isSuperAdmin()) {
      return '-'
    }

    let amount = useGrossAmount ? data.gross_amount : data.net_amount
    let field = data.line_items ? 'line_items' : 'meta'

    return data.transaction_type === TRANSACTION_REFUNDED
      ? `(${formatAmount(amount, data.currency, data[field][0], earnable)})`
      : formatAmount(amount, data.currency, data[field][0], earnable)
  }

  function formatTransactions(
    transactions,
    earnable = false,
    siteAdmin = false
  ) {
    if (!transactions) return []
    return transactions.map((data) =>
      formatTransaction(data, earnable, siteAdmin)
    )
  }

  function formatTransaction(data, earned, useGrossAmount) {
    let moreDesc = ''
    let moreGames = ''
    let monetizationType = ''
    const field = data.line_items ? 'line_items' : 'meta'

    if (data[field].length > 1) {
      const countMods = data[field].length - 1
      const games = data[field].map((a) => a.game_id)
      const countGames = [...new Set(games)].length - 1
      moreDesc = ` + ${countMods} more`
      moreGames = countGames > 0 ? ` + ${countGames} more` : ''
    }

    const description =
      (data[field][0].mod_name || data[field][0].token_pack_name) + moreDesc
    const game = data[field][0].game_name + moreGames

    switch (data.monetization_type) {
      case SALE_TYPE_FIAT:
      case SALE_TYPE_EXTERNAL:
        monetizationType =
          data.transaction_type === TRANSACTION_REFUNDED
            ? 'SKU pack refund'
            : 'SKU pack sale'
        break

      case SALE_TYPE_TOKEN:
        monetizationType =
          data.transaction_type === TRANSACTION_REFUNDED
            ? 'Marketplace refund'
            : 'Marketplace sale'
        break
    }

    return {
      ...data,
      purchase_date: msToSecs(monDateToUTCTimestamp(data.purchase_date)),
      description: description,
      game: game,
      type_text: monetizationType,
      amount_text: formatAmountText(data, earned, useGrossAmount),
      processing_fees: data.gateway_fee,
    }
  }

  function formatEarnings(item) {
    if (item.transaction_type === TRANSACTION_REFUNDED) {
      return `(${formatAmount(item.revenue, item.currency, null, true)})`
    } else if (item.monetization_type === SALE_TYPE_EXTERNAL) {
      return `(${formatAmount(
        item.token_pool_fee + item.platform_fee,
        item.currency,
        null,
        true
      )})*`
    }

    return formatAmount(item.revenue, item.currency, null, true)
  }

  function formatItems(items) {
    if (!items) return []
    return items.map((data) => formatItem(data))
  }

  function formatItem(data) {
    let monetizationType = ''
    let field = data.line_items ? 'line_items' : 'meta'

    switch (data.monetization_type) {
      case SALE_TYPE_FIAT:
      case SALE_TYPE_EXTERNAL:
        monetizationType =
          data.transaction_type === TRANSACTION_REFUNDED
            ? 'SKU pack refund'
            : 'SKU pack sale'
        break

      case SALE_TYPE_TOKEN:
        monetizationType =
          data.transaction_type === TRANSACTION_REFUNDED
            ? 'Marketplace refund'
            : 'Marketplace sale'
        break
    }

    let item_description = data[field].mod_name || data[field].token_pack_name
    let item_game = data[field].game_name
    let item_meta = data[field]

    if (Array.isArray(data[field])) {
      item_description =
        data[field][0].mod_name || data[field][0].token_pack_name
      item_game = data[field][0].game_name
      item_meta = data[field][0]
    }

    return {
      ...data,
      purchase_date: msToSecs(monDateToUTCTimestamp(data.purchase_date)),
      description: item_description,
      game: item_game,
      type_text: monetizationType,
      net_amount: data.sale_price,
      processing_fees: data.gateway_fee,
      earnings: formatEarnings(data),
      meta: item_meta,
      team_type: data.team_type?.toLowerCase() ?? '-',
    }
  }

  function formatPayouts(payouts) {
    return payouts.map((data) => {
      const status = data.status === 'success' ? 'processed' : data.status

      return {
        created_at: msToSecs(monDateToUTCTimestamp(data.created_at)),
        id: data.id,
        status: status,
        method: data.method,
        amount: formatAmount(data.amount, CURRENCY_USD),
        exchanged: data.exchanged,
        fee: formatAmount(data.fee, CURRENCY_USD),
        earnings: data.earnings,
      }
    })
  }

  function formatRefunds(refunds) {
    return refunds.map((data) => {
      const meta = data.meta?.[0]
        ? data.meta[0]
        : typeof data.line_items === 'string'
          ? JSON.parse(data.line_items)
          : data.line_items

      return {
        ...data,
        meta,
        created_at: isString(data.created_at)
          ? msToSecs(monDateToUTCTimestamp(data.created_at))
          : data.created_at,
        amount: formatAmount(
          data.amount,
          meta.mod_id ? CURRENCY_MIO : CURRENCY_USD,
          meta
        ),
      }
    })
  }

  function formatInvoices(items) {
    if (!items) return []
    return items.map((data) => formatInvoice(data))
  }

  function formatInvoice(data) {
    const date = new Date(monDateToUTCTimestamp(data.created_at))
    return {
      ...data,
      date: msToSecs(date.getTime()),
      amount_text: formatAmount(data.gross_amount, CURRENCY_USD),
      canPay: monetizationAccess([
        ['or', 'game:leaderonly', ['and', 'game:kyb', 'game:revenueshare']],
      ]),
    }
  }

  async function getGameWallet(teamId) {
    loadingGameWallet.value = true
    await runGameWallet(teamId)

    if (!errorGameWallet.value) {
      gameWalletState.value[teamId] = dataGameWallet.value.data[0]
    }
    loadingGameWallet.value = false
  }

  function setGameWallet(gameId, wallet) {
    gameWalletState.value = {
      ...gameWalletState,
      [gameId]: wallet,
    }
  }

  function setGamePurchasesFetched(gameId) {
    gamePurchasesFetchedState.value = {
      ...gamePurchasesFetchedState.value,
      [gameId]: true,
    }
  }

  function setTeamPendingInvoices(gameNameId, state = false) {
    pendingInvoicesState.value = {
      ...pendingInvoicesState.value,
      [gameNameId]: state,
    }
  }

  return {
    manualModeration: readonly(manualModerationState),
    scarcity: readonly(scarcityState),
    setGamePurchasesFetched,
    setTeamPendingInvoices,
    loadingCreateWallets,
    createCreatorWallets,
    gamePurchasesFetched,
    setManualModeration,
    updateSessionStatus,
    setMinMaxValidation,
    loadingAuthenticate,
    formatTransactions,
    clearSessionStatus,
    errorCreateWallets,
    formatTransaction,
    sessionStatusType,
    loadingUserStatus,
    loadingGameWallet,
    authenticateError,
    globalAuthLoading,
    kycTermsAccepted,
    userStatusError,
    adminModScopeId,
    initialScarcity,
    loadingRegister,
    pendingInvoices,
    loadingSession,
    formatInvoices,
    setGameWallet,
    getGameWallet,
    sessionStatus,
    getUserStatus,
    errorRegister,
    formatPayouts,
    formatRefunds,
    sessionError,
    authenticate,
    registerUser,
    setScarcity,
    minMaxValid,
    gameWallets,
    formatItems,
    formatItem,
    unsetNonce,
    getSession,
    nonce,
  }
}
