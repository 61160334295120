import { queryString } from '@helpers/utils.js'
import { PUBLIC_BASE_URL } from '@config'
import { createClient } from '@services'
import qs from 'qs'

const { get, post, del } = createClient()

export async function getReportsRequest(gameNameId, query) {
  const { data } = await get(
    `/games/@${gameNameId}/reports${queryString(query)}`
  )
  return data
}

export async function getReportsSummaryRequest(gameNameId, query) {
  const { data } = await get(
    `/games/@${gameNameId}/reports/summary${queryString(query)}`
  )
  return data
}

export async function submitReportRequest(report) {
  const { data } = await post(`${PUBLIC_BASE_URL}/report`, qs.stringify(report))
  return data
}

export async function resolveReportRequest(gameNameId, reportId, via) {
  const { data } = await post(
    `/games/@${gameNameId}/reports/${reportId}/resolve?via=${via}`,
    null,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  )
  return data
}

export async function deleteReportRequest(gameNameId, reportId) {
  const { data } = await del(
    `/games/@${gameNameId}/reports/${reportId}`,
    null,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  )
  return data
}

export async function getAdminReportsRequest(query) {
  const { data } = await get(`/admin/reports${queryString(query)}`)
  return data
}

export async function resolveAdminUserReportRequest(reportId) {
  const { data } = await post(`/admin/reports/${reportId}/resolve`, null, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
  return data
}
